import Footer from "../Components/footer";
import Header from "../Components/Header";
import Form from "../Components/Form";
import React, { useState} from 'react';
import { useNavigate } from "react-router-dom";
import Faqaccordian from '../Components/Faqaccordian';
import CompanyLogoSlider from "../Components/CompanyLogoSlider";
import CustomSlider from "../Components/CustomSlider";
import NewForm from "../Components/NewForm";

const ECommerceWebPage = () =>  {

const sec5dot1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot1.png";
const sec5dot2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot2.png";
const sec5dot3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot3.png";
const sec5dot4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot4.png";
const stagesbg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/stagesbg.png";



const projectimg1 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img1.svg';
const projectimg3 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img3.svg';
const projectimg5 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img5.svg';
const projectimg7 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img7.svg';
const projectimg8 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img8.svg';
const projectimg9 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img9.svg';
const projectimg2 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img2.svg';

const slides = [

{
  id: 1,
  title: "HealthOMax Web",
  description:
        "An extremely integrated platform of medical professionals and patients. Our website provides access to the same power as of our mobile application available anywhere, anytime, on any device.",                
  image: projectimg9,
  url1:"/our-completed-projects/healthomax-website"

},

{
  id: 2,
  title: "Lookplex",
  description:
    "Salon at Home Services Website For a salon at home services provider, we designed a sleek and modern website with an easy booking feature. The site allows customers to book appointments effortlessly and explore various beauty services offered.",
  image: projectimg1,
  url1:"/our-completed-projects/lookplex"

},
{
    id: 3,
    title: "Monovogue",
    description:
      "We developed a dynamic and visually appealing e-commerce platform that enhances the shopping experience. The site includes secure payment gateways, intuitive navigation, and a responsive design for seamless browsing on all devices.",
    image: projectimg8,
    url1:"/our-completed-projects/monovouge"

  },

  {
    id: 4,
    title: "3D Gardening",
    description:
      "We designed a vibrant and user-friendly website for a client providing gardening services. The site show cases their expertise, offers detailed service descriptions, and includes an easy-to-use contact form for customer inquiries.",
    image: projectimg3,
    url1:"/our-completed-projects/gardening"

  },

    {
      id: 5,
      title: "Narayana Home Services",
      description:
        "We developed a comprehensive website for Narayana Home, a home worker providers service company. The site allows users to easily hire cooks, maids, chefs, and watchmen, ensuring a seamless hiring process.",
      image: projectimg5,
      url1:"/our-completed-projects/narayana-homes"

    },
 
      {
          id: 6,
          title: "SmartCare",
          description:
            "We created a healthcare platform, SmartCare, that enables patients to easily book appointments, find doctors, store medical records, and save vital health information, streamlining their healthcare experience.",
          image: projectimg7,
          url1:"/our-completed-projects/smartcare"

        },
        {
            id: 7,
            title: "Cuddly koala",
            description:
              "We created an informative and professional website for a doctor and lactation consultant. The site features comprehensive service information, patient resources, and an intuitive appointment booking system.",
            image: projectimg2,
            url1:"/our-completed-projects/cuddly-cola"

          },
       
      
];


    // ================ Mailer code start from here =====================
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        phone: "",
      });
      const [responseMessage, setResponseMessage] = useState(""); 
      const [loading, setLoading] = useState(false); // State for loader
      const navigate = useNavigate(); // Initialize useNavigate
      const [errors, setErrors] = useState({}); 
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
     
      const validateForm = () => {
        let formErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10}$/; // Assumes phone number should be 10 digits
    
        if (!formData.email || !emailRegex.test(formData.email)) {
          formErrors.email = "Please enter a valid email address.";
        }
    
        if (!formData.phone || !phoneRegex.test(formData.phone)) {
          formErrors.phone = "Please enter a valid 10-digit phone number.";
        }
    
        if (!formData.message || formData.message.trim().length < 10) {
            formErrors.message = "Please enter a message with at least 10 characters.";
          }
    
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
          return;
        }
        setLoading(true); // Show loader
    
        try {
          const response = await fetch(
            "https://mailer.datametricks.com/send-email.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            }
          );
    
          const result = await response.json(); // Parse JSON from the response
    
          setResponseMessage(result.message);
    
          if (result.status === "success") {
            setTimeout(() => {
              setLoading(false); // Hide loader
              navigate("/Successemailpage"); // Redirect to Thank You page
            }, 1000); // Delay redirect by 2 seconds
          } else {
            setLoading(false); // Hide loader if not successful
          }
        } catch (error) {
          setResponseMessage("Error submitting form.");
          setLoading(false); // Hide loader on error
        }
      };
    
      // ================ Mailer code end here =====================


 const ratingstar = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/ratingstar.svg";


 
var faqDatamobile=[
    {que1:"1. What is mobile app development and why is it important?",ans1:"Mobile app development involves creating software applications that run on mobile devices. It's important because mobile apps can enhance customer engagement, provide better user experiences, and drive business growth by making your services accessible anytime, anywhere." },
    {que1:"2. How long does it take to develop a mobile app? ", ans1:"The timeline for developing a mobile app varies depending on the complexity and features of the app. A basic app might take a few months, while a more complex app with advanced features could take six months or longer."},
    {que1:"3. How much does it cost to develop a mobile app? ",ans1:"The cost of developing a mobile app depends on various factors, including the app's complexity, features, design, and the development team's rates. It's best to discuss your specific requirements with a development team to get an accurate estimate."},
    {que1:"4. What is the difference between native and cross-platform app development? ",ans1:"Native app development involves creating apps specifically for one platform (iOS or Android) using platform-specific languages and tools. Cross-platform app development allows you to create apps that run on multiple platforms (iOS and Android) using a single codebase, saving time and resources. "},
    {que1:"5. How do you ensure the security of my mobile app? ",ans1:"We prioritize app security by implementing best practices such as data encryption, secure APIs, regular security testing, and compliance with industry standards. Our team stays updated on the latest security trends to protect your app from vulnerabilities. "},
    {que1:"6. Can you integrate my mobile app with existing systems? ",ans1:"Yes, we offer app integration services that connect your mobile app with existing systems, databases, and third-party services to ensure seamless functionality and enhanced capabilities. "},
    {que1:"7. What kind of post-launch support do you provide?",ans1:"We provide comprehensive post-launch support, including regular updates, bug fixes, performance optimization, and ongoing maintenance to ensure your app remains functional and up to date. " },
    {que1:"8. How do you handle app updates and maintenance? ",ans1:"We offer ongoing maintenance services to keep your app updated with the latest features and security patches. Our team monitors the app's performance and user feedback to make necessary improvements and ensure optimal functionality. " },
    {que1:"9. Can you help with app store submissions? ",ans1:"Yes, we assist with the entire app store submission process, including preparing necessary documentation, ensuring compliance with app store guidelines, and managing the submission to both Apple App Store and Google Play Store. "},
    {que1:"10. Why should I choose DataMetricks for my mobile app development? ",ans1:"DataMetricks Consulting offers expert mobile app development services tailored to your business needs. Our team has extensive experience, a proven track record of successful projects, and a commitment to delivering high-quality, user-centric apps. We provide end-to-end services, from initial strategy and design to development, testing, and ongoing support. "},    

];

return(

    <div>
        <section>
                <Header/>
        </section>

        <section style={{marginTop:'50px'}}>
            <div  style={{backgroundColor:'rgba(0, 83, 154, 1)'}}>
                <div className="container  pt-20" style={{paddingTop:'80px'}}>
                    <p className="fw-800 fs-60 plus mb-0 hideinmo" style={{color:'rgba(255, 255, 255, 1)'}}>E-Commerce Website Development</p>

                    <div className="row justify-content-center ">
                        <div className="col-lg-7 col-12">
                        <p className="fw-800 fs-30 plus pt-lg-5 pt-20 mb-0 font-25" style={{color:'rgba(255, 255, 255, 1)'}}>Transform Your Online Store with Custom E-Commerce Website Development</p>

                            <p className="fw-500 fs-19 plus  pt-3 mt-10 " style={{color:'rgba(255, 255, 255, 1)'}}>We build scalable, robust applications designed to evolve with your business. Let’s craft a mobile app that your customers will love and that will drive your business forward.</p>

             <div className="mt-5  ">
                <a href="https://outlook.office365.com/owa/calendar/MihirProjectLeadatDataMetricks@datametricks.com/bookings/">
                    <button type="button" className="btn btn-sm font-14  pt-2  px-4" style={{fontSize:'20px',fontWeight:'600',backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'45px',border:'none',outline:'none',paddingBottom:'10px'}}>
                    Book a Free Consultation
                    </button>
                </a>
            </div>

         
                        </div>
                        <div className="col-lg-5 col-12 text-center mt-45 ">
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/custom-img-3.svg" alt="" className="img-fluid " />
                        </div>
                       
                    </div>
                </div>
              
            </div>
        </section>



        <section style={{backgroundColor:'rgba(232, 244, 255, 1)'}}>
            <div className="container pb-5 pt-lg-5 pt25" >
              
                <div className="row  justify-content-center align-items-center">

                <div className="col-lg-4 col-12 textcenter order-lg-1 order-2">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/e-img-1.svg" alt="" className="img-fluid pt-20"/>
                        
                        </div>

                        <div className="col-lg-7 col-12  order-lg-2 order-1">
                            <p className="fw-700 fs-30 plus font-25" style={{color:'rgba(0, 0, 0, 1)'}}>E-Commerce Website Development</p>
                            <p className="fs-17 fw-400 plus " style={{color:'rgba(34, 34, 34, 0.6)'}}>In today’s competitive digital marketplace, a powerful e-commerce website is more than just a storefront—it’s your gateway to reaching a global audience, building trust, and driving revenue. At Datametricks Consulting Pvt. Ltd., we design and develop e-commerce websites that deliver exceptional shopping experiences, tailored to your business goals.</p>
                     </div>

                </div>
            </div>

        </section>

        <section className=" mt-20" style={{backgroundColor:'#F6FBFF'}}>
    <div className="container pt-4 pb-5 ">
        <p className="fw-800 fs-35 plus text-center mb-0 font-23" style={{color:'#1B3567'}}>India's One Of The Highest Rated E-Commerce Development Company</p>
        <p className="fw-500 fs-20 plus text-center pt-2 font-18" style={{color:'#1B3567'}}> 100+ Glowing 5-Star Reviews Across Top B2B Review Platforms</p>
        <div className="row justify-content-center mt-4 g-2 p-10" >

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/clutch_img1.svg" alt="box-img" style={{height:'100px',width:'96px'}} className="img-fluid  pt-1"  />
                    <img src={ratingstar} alt="" className="pt-2  " />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                                  

                </div>
            </div>
            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img2.svg" alt="box-img" style={{height:'100px',width:'96px'}} className="img-fluid heightwidth pt-1"  />
                    <img src={ratingstar} alt="" className="pt-2  " />
                    <p className="fw-700 fs-32 mont font-23" >4.9</p>
                                  

                </div>
            </div>

            {/* <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img2.svg" alt="box-img" style={{height:'113px',width:'90px'}} className="img-fluid heightwidth1" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.9</p>
                </div>
            </div> */}

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img3.svg" alt="box-img" style={{height:'113px',width:'120px'}} className="img-fluid heightwidth2" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img4.svg" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid heightwidth2" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    {/* <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img5.svg" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid" /> */}
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/googleicon.png" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid heightwidth3 pt-1" />

                    <img src={ratingstar} alt="" className="pt-1"  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

          
        </div>
    </div>

</section>


<section>
                <div className="container mt-4">
                    <p className="fw-800 fs-30 plus text-center font-25" style={{color:'rgba(0, 0, 0, 1)'}}>Why E-Commerce Matters</p>
                    <div className="row g-2 pt-4">
                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 h-100">
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon2.svg" alt="" />
                            <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'#00539A'}}>Custom Solutions</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'#7C7C7C'}}>Tailored to your brand and business model.</p>

                        </div>
                        </div>

                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 h-100">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon2.svg" alt="" />
                        <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'#00539A'}}>Mobile-First Design</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'#7C7C7C'}}>Optimized for all devices to engage your audience.</p>

                        </div>
                        </div>

                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 h-100">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon2.svg" alt="" />
                        <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'#00539A'}}>SEO-Ready Architecture</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'#7C7C7C'}}>Optimized for all devices to engage your audience.</p>

                        </div>
                        </div>

                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 h-100">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon2.svg" alt="" />
                        <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'#00539A'}}>SEO-Ready Architecture</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'#7C7C7C'}}>Boost your visibility and rank higher on search engines.</p>

                        </div>
                        </div>
                    </div>
                </div>
</section>


<section >
            <div className="mt-5 pt-10" style={{backgroundColor:'rgba(208, 233, 255, 1)'}}>
                <div className="container pt-4 pb-5 ">
                <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                       <p className="fw-800 fs-35 plus  font-25 " style={{color:'rgba(0, 0, 0, 1)'}}>Our E-Commerce Services</p>  
                     </div>
              
                 </div>

                 <div className="row justify-content-center  mt-lg-3 g-0 g-2 pt-10">
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-5.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Custom E-Commerce Website Development</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Unlock the potential of your online business with a custom e-commerce website designed for seamless functionality and user engagement. Tailored to your needs, we deliver scalable, secure, and visually captivating solutions.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-6.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Platform Expertise</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Leverage our platform expertise to build innovative, scalable, and secure solutions tailored to your business needs. From web to mobile, we deliver seamless experiences across all major technologies.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-7.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Payment Integration</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Seamlessly integrate secure payment solutions into your platform for effortless transactions. From multiple gateways to global currencies, we ensure a smooth and reliable payment experience.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-8.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Multi-Vendor Marketplace</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Create a thriving multi-vendor marketplace with our custom solutions, enabling seamless collaboration between sellers and buyers. Empower your platform with advanced features, scalability, and secure transactions.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-9.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Performance Optimization</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Enhance your website's speed and efficiency with our performance optimization services. Deliver faster load times, seamless user experiences, and improved search engine rankings.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-10.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Maintenance & Support</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Ensure your website runs smoothly with our reliable maintenance and support services. From regular updates to issue resolution, we keep your platform secure and optimized.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>


              




              </div>
            </div>
</section>

<section>
<div className='container textcenter text-center  mt-lg-4 mt-20'>
            <p className='fw-600 fs-30 plus mb-0 font-25'>Key Features</p>
            <div className="row mt-4">
                <div className="col-lg-4 col-12">
                    <div className="d-flex gap-2">
                         <div><img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-5.svg" alt="" /></div>
                        <div><p className="fw-700 fs-16 plus font-16" style={{color:'rgba(0, 83, 154, 1)'}}>Create User-friendly admin dashboard</p></div>
                    </div>
                </div>

                <div className="col-lg-4 col-12">
                    <div className="d-flex gap-2">
                         <div><img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-5.svg" alt="" /></div>
                        <div><p className="fw-700 fs-16 plus font-16" style={{color:'rgba(0, 83, 154, 1)'}}>Product management and inventory Track</p></div>
                    </div>
                </div>

                <div className="col-lg-4 col-12">
                    <div className="d-flex gap-2">
                         <div><img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-5.svg" alt="" /></div>
                        <div><p className="fw-700 fs-16 plus font-16" style={{color:'rgba(0, 83, 154, 1)'}}>Advanced search and filter functionality</p></div>
                    </div>
                </div>
            </div>

            <div className="row mt-lg-4 ">
                <div className="col-lg-4 col-12 ">
                    <div className="d-flex gap-2">
                         <div><img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-5.svg" alt="" /></div>
                        <div><p className="fw-700 fs-16 plus font-16" style={{color:'rgba(0, 83, 154, 1)'}}>Multi-language and multi-currency support</p></div>
                    </div>
                </div>

                <div className="col-lg-4 col-12">
                    <div className="d-flex gap-2">
                         <div><img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-5.svg" alt="" /></div>
                        <div><p className="fw-700 fs-16 plus font-16" style={{color:'rgba(0, 83, 154, 1)'}}>Real-time analytics and reporting Tracking</p></div>
                    </div>
                </div>

                <div className="col-lg-4 col-12">
                    <div className="d-flex gap-2">
                         <div><img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-5.svg" alt="" /></div>
                        <div><p className="fw-700 fs-16 plus font-16" style={{color:'rgba(0, 83, 154, 1)'}}>Social media and third-party integrations</p></div>
                    </div>
                </div>
            </div>
       </div>
</section>

<section>
    <section>
       <div className='container textcenter text-center  mt-lg-5 mt-20'>
            <p className='fw-600 fs-35 plus mb-0 font-23'>Our Success Stories</p>
            <p className='fw-600 fs-35 plus font-23 '>Transformative Projects and <span style={{color:'rgba(0, 83, 154, 1)'}}>Case Studies </span></p>
       </div>
    </section>

    <section className="my-5">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-10">
              <CustomSlider slides={slides}/>

            </div>
        </div>
    </div>
</section>
</section>


<section className="mt-lg-5 mt-20 ">
<p className="fs-35 fw-700 plus pt-1 text-center font-25">Our Clients</p>
    <div className="p-4 mt-4" style={{backgroundColor:'rgba(255, 255, 255, 1)',boxShadow: '0px 0px 7.3px 0px rgba(175, 175, 175, 0.25)'}}>
        <div className="container">
            <div className="row ">           
                <div className="col-lg-12">
                    <CompanyLogoSlider/>
                </div>
            </div>
        </div>

    </div>

</section>



       
<section>

<section className='d-none d-sm-none d-lg-block d-md-block'>

<div className="mt-5">
        <p className="fs-35 fw-700 plus text-center mb-0 font-20">E-Commerce Website Development Process</p>

 </div>
 <div className="container mt-lg-5 ">
<div className="row ms-lg-5">
  <div className="col-lg-4 ">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt=""  />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">
          1. Discovery and Planning
        </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
         Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot2} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">2. Design</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Bringing your vision to life with captivating visuals and
          intuitive user interfaces.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt="" />
      </div>
    
      <div>
        <p className="sec5title mb-0 mt-1">3. Development</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Transforming designs into functional <br /> websites with
          clean code and seamless functionality.
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<div className="container">

<div className="col-lg-12 ms-lg-5">
<img className="img-fluid" src={stagesbg} alt="" />
</div>
</div>
<div className="container pt-4 ">
<div className="row ms-lg-5">
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">4. Testing </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Thoroughly checking every aspect of the website to ensure
          it meets quality standards and performs flawlessly.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot4} alt="" />
      </div>
     
      <div>
        <p className="sec5title mb-0 mt-1">5. Launch</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Deploying the website to the live <br /> environment and
          making it accessible to your audience.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">6. Beyond</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Providing ongoing support, maintenance, and updates to
          ensure your website remains optimized and up to date.
        </p>
      </div>
    </div>
  </div>
</div>



</div>
    
</section>

<section className='d-block d-sm-block d-lg-none d-md-none'>
  <div className='container mt-4 '>
  <p className="fs-35 fw-700 plus text-center mb-0 font-20">Our Streamlined Web Development</p>
  <p className="fs-35 fw-700 plus text-center font-20"> Process from Concept to Launch</p>
    <div className='row justify-content-center'>
     
      <div className='col-10 mt-2'>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot1} alt=""  />
          </div>
          <div>
            <p className="sec5title fs-15 mb-0 mt-1">
              1. Discovery and Planning
            </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12 "
            >
            Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot2} alt="" />
          </div>
        
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">2. Design</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Bringing your vision to life with captivating visuals and
              intuitive user interfaces.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot1} alt="" />
          </div>
        

          <div>
            <p className="sec5title mb-0 mt-1 fs-15">3. Development </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Transforming designs into functional <br /> websites with
              clean code and seamless functionality.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot3} alt="" />
          </div>
       
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">4. Testing </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Thoroughly checking every aspect of the website to ensure
              it meets quality standards and performs flawlessly.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot4} alt="" />
          </div>
       
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">5. Launch</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Deploying the website to the live <br /> environment and
              making it accessible to your audience.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot3} alt="" />
          </div>
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">6. Beyond</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Providing ongoing support, maintenance, and updates to
              ensure your website remains optimized and up to date.
            </p>
          </div>
        </div>
      </div>
    </div>
    

  </div>
</section>

</section>

 
<section>
    <div className="container mt-lg-5 mt-30">
        <div className="row justify-content-center align-items-center">
            <div className="col-lg-4 col-12">
                 <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-img4.svg" alt=""  className="img-fluid"/>

            </div>
            <div className="col-lg-7 col-12 ps-lg-5 mt-30">
                <div>
                    <p className="fw-800 plus fs-30 font-25" style={{color:'rgba(0, 0, 0, 1)'}}>Let’s Build Your Online Store Together</p>
                    <p className="fw-400 fs-16 plus " style={{color:'rgba(0, 0, 0, 1)'}}>Your e-commerce website is more than a platform—it’s a vital component of your business success. Let’s create a solution that drives sales, enhances customer loyalty, and scales with your ambitions.</p>
                    <p className="fw-700 fs-25 plus" style={{color:'rgba(0, 83, 154, 1)'}}>Get Started Today!</p>
                    <div>
                <a href="https://outlook.office365.com/owa/calendar/MihirProjectLeadatDataMetricks@datametricks.com/bookings/">
                    <button type="button" className="btn btn-sm font-14  pt-2  px-4" style={{fontSize:'15px',fontWeight:'400',backgroundColor:'rgba(246, 102, 0, 1)',borderRadius:'45px',border:'none',outline:'none',paddingBottom:'10px',color:'white'}}>
                    Book a Free Consultation
                    </button>
                </a>
            </div>
                </div>

            </div>
        </div>

    </div>
   </section>
       


   <section  className=" d-none d-sm-none d-md-block d-lg-block" style={{marginTop:'6%'}}>
        <NewForm/>

</section>

{/* Mobile View  */}
<section className="mt-lg-4 d-block d-sm-block d-md-none d-lg-none">
    <div className=" pt-3 pb-5 px-2 mt-30" style={{backgroundColor:'rgba(242, 242, 242, 1)'}}>
        <div className="container">
         
                      <p className="fw-600 text-center fs-23 plus mb-0 pt-1" >What can we help you with?</p>
                      <p className="fw-500 text-center fs-17 plus pt-3" style={{lineHeight:'22px'}}>Get Custom Solutions, Recommendations, or, Estimates.
                      Confidentiality & Same Day Response Guaranteed!</p>
    <div className="pt-1">
       <Form/>
    </div>

    <div className="d-flex justify-content-center gap-2 mt-4 ">

<div className="d-flex  gap-2">
    <div>
          <img src= "https://d3vqz9qyn2r1xe.cloudfront.net/imges/green-icon-form.svg" alt="" className="pt-1" />
    </div>
    <div>
           <p className="fw-600 plus fs-17  pt-1">100% Confidential</p>
    </div>
</div>

<div className="d-flex  gap-2">
    <div>
          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/green-icon-form.svg" alt="" className="pt-1" />
    </div>
    <div>
           <p className="fw-600 plus fs-17  pt-1">We sign NDA</p>
    </div>
</div>

</div>

<div className="d-flex gap-4 mt-4">
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon1.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon2.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon3.svg" alt="" className="img-fluid pt-1" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon4.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon5.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon6.svg" alt="" className="img-fluid pt-2" />
            </div>
    </div>

      </div>
      </div>
</section>



        <section style={{backgroundColor:'rgba(208, 233, 255, 1)'}}>
      <div className=" pt-5 pb-4">
          <Faqaccordian faqAccordianData={faqDatamobile}/>
          </div>
      </section>



        <section >
                <Footer/>
        </section>

    </div>
);
};
export default ECommerceWebPage;