import Footer from "../Components/footer";
import Header from "../Components/Header";
import Form from "../Components/Form";
import React, { useState} from 'react';
import { useNavigate } from "react-router-dom";
import Faqaccordian from '../Components/Faqaccordian';
import CompanyLogoSlider from "../Components/CompanyLogoSlider";
import CustomSlider from "../Components/CustomSlider";
import GoogleReviewsWidget from "../Components/GoogleReviewsWidget";
import NewForm from "../Components/NewForm";


const MobileDevlopmentPage = () =>  {

const sec5dot1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot1.png";
const sec5dot2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot2.png";
const sec5dot3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot3.png";
const sec5dot4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot4.png";
const stagesbg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/stagesbg.png";
const projectimg7 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img7.svg';
const projectimg11 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/healthomaxappimg-C.png';
const projectimg12 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/patientappimg-B.png';
const projectimg13 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/docappimg-A.png';

const slides = [

{
  id: 1,
  title: "HealthOMax App",
  description:
    "We developed the HealthOMax mobile application for both Android and iOS platforms. The app offers the same features as the website, allowing medical professionals to connect and collaborate, and patients to interact with doctors on the go.",
  image: projectimg11,
  image1: '/images/pro-line.svg',
  url1:"/our-completed-projects/healthomax-mobile-app",
  review:'40K+',
  review1:'Download',
  review2:'★ 4.8',
  review3:'App Rating',

},

 {
  id: 2,
  title: "SmartCare Patient App",
  description:
    "We developed the SmartCare patient mobile application for iOS and Android, providing patients with features such as appointment booking, doctor search, record storage, and vital tracking, all in one convenient app.",
  image: projectimg12,
  image1: '/images/pro-line.svg',
  url1:"/our-completed-projects/smartcare-mobile-app-for-ios-andriod",
  review:'30K+',
  review1:'Download',
  review2:'★ 4.8',
  review3:'App Rating',

},
{
  id: 3,
  title: "SmartCare Doctor App",
  description:
    "The SmartCare Doctors App, available on both Android and iOS, offers all the functionalities of the EMR system, allowing doctors to manage consultations, generate prescriptions, book appointments, and handle follow-ups efficiently  their mobile devices.",
  image: projectimg13,
  image1: '/images/pro-line.svg',
  url1:"/our-completed-projects/clinic-management-mobile-app",
  review:'30K+',
  review1:'Download',
  review2:'★ 4.8',
  review3:'App Rating',

},

{
  id: 4,
  title: "SmartCare",
  description:
    "We created a healthcare platform, SmartCare, that enables patients to easily book appointments, find doctors, store medical records, and save vital health information, streamlining their healthcare experience.",
  image: projectimg7,
  url1:"/our-completed-projects/smartcare"

},

];


    // ================ Mailer code start from here =====================
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        phone: "",
      });
      const [responseMessage, setResponseMessage] = useState(""); 
      const [loading, setLoading] = useState(false); // State for loader
      const navigate = useNavigate(); // Initialize useNavigate
      const [errors, setErrors] = useState({}); 
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
     
      const validateForm = () => {
        let formErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10}$/; // Assumes phone number should be 10 digits
    
        if (!formData.email || !emailRegex.test(formData.email)) {
          formErrors.email = "Please enter a valid email address.";
        }
    
        if (!formData.phone || !phoneRegex.test(formData.phone)) {
          formErrors.phone = "Please enter a valid 10-digit phone number.";
        }
    
        if (!formData.message || formData.message.trim().length < 10) {
            formErrors.message = "Please enter a message with at least 10 characters.";
          }
    
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
          return;
        }
        setLoading(true); // Show loader
    
        try {
          const response = await fetch(
            "https://mailer.datametricks.com/send-email.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            }
          );
    
          const result = await response.json(); // Parse JSON from the response
    
          setResponseMessage(result.message);
    
          if (result.status === "success") {
            setTimeout(() => {
              setLoading(false); // Hide loader
              navigate("/Successemailpage"); // Redirect to Thank You page
            }, 1000); // Delay redirect by 2 seconds
          } else {
            setLoading(false); // Hide loader if not successful
          }
        } catch (error) {
          setResponseMessage("Error submitting form.");
          setLoading(false); // Hide loader on error
        }
      };
    
      // ================ Mailer code end here =====================


 const ratingstar = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/ratingstar.svg";


 
var faqDatamobile=[
    {que1:"1. What is mobile app development and why is it important?",ans1:"Mobile app development involves creating software applications that run on mobile devices. It's important because mobile apps can enhance customer engagement, provide better user experiences, and drive business growth by making your services accessible anytime, anywhere." },
    {que1:"2. How long does it take to develop a mobile app? ", ans1:"The timeline for developing a mobile app varies depending on the complexity and features of the app. A basic app might take a few months, while a more complex app with advanced features could take six months or longer."},
    {que1:"3. How much does it cost to develop a mobile app? ",ans1:"The cost of developing a mobile app depends on various factors, including the app's complexity, features, design, and the development team's rates. It's best to discuss your specific requirements with a development team to get an accurate estimate."},
    {que1:"4. What is the difference between native and cross-platform app development? ",ans1:"Native app development involves creating apps specifically for one platform (iOS or Android) using platform-specific languages and tools. Cross-platform app development allows you to create apps that run on multiple platforms (iOS and Android) using a single codebase, saving time and resources. "},
    {que1:"5. How do you ensure the security of my mobile app? ",ans1:"We prioritize app security by implementing best practices such as data encryption, secure APIs, regular security testing, and compliance with industry standards. Our team stays updated on the latest security trends to protect your app from vulnerabilities. "},
    {que1:"6. Can you integrate my mobile app with existing systems? ",ans1:"Yes, we offer app integration services that connect your mobile app with existing systems, databases, and third-party services to ensure seamless functionality and enhanced capabilities. "},
    {que1:"7. What kind of post-launch support do you provide?",ans1:"We provide comprehensive post-launch support, including regular updates, bug fixes, performance optimization, and ongoing maintenance to ensure your app remains functional and up to date. " },
    {que1:"8. How do you handle app updates and maintenance? ",ans1:"We offer ongoing maintenance services to keep your app updated with the latest features and security patches. Our team monitors the app's performance and user feedback to make necessary improvements and ensure optimal functionality. " },
    {que1:"9. Can you help with app store submissions? ",ans1:"Yes, we assist with the entire app store submission process, including preparing necessary documentation, ensuring compliance with app store guidelines, and managing the submission to both Apple App Store and Google Play Store. "},
    {que1:"10. Why should I choose DataMetricks for my mobile app development? ",ans1:"DataMetricks Consulting offers expert mobile app development services tailored to your business needs. Our team has extensive experience, a proven track record of successful projects, and a commitment to delivering high-quality, user-centric apps. We provide end-to-end services, from initial strategy and design to development, testing, and ongoing support. "},    

];

return(

    <div>
        <section>
                <Header/>
        </section>

        <section style={{marginTop:'50px'}}>
            <div  style={{backgroundColor:'rgba(0, 83, 154, 1)'}}>
                <div className="container pb-4 pt-20" style={{paddingTop:'80px'}}>
                    <p className="fw-800 fs-60 plus mb-0 hideinmo" style={{color:'rgba(255, 255, 255, 1)'}}>Trusted Mobile App Development<br/> Company in India</p>
                    <p className="fw-800 fs-25 plus mb-0 hideindesktop" style={{color:'rgba(255, 255, 255, 1)'}}>Trusted Mobile App Development Company in India</p>

                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-12">
                            <p className="fw-500 fs-17 plus pt-lg-5 pt-20" style={{color:'rgba(255, 255, 255, 0.7)'}}>As a leading Mobile App Development Company in India, we develop scalable and robust  applications tailored to grow with your business. Let’s create a mobile app your customers will love,  driving your business to new heights.</p>

                <div className="d-flex gap-3 pt-20" style={{paddingTop:'50px'}}>
                     <div >
                <a href="https://outlook.office365.com/owa/calendar/MihirProjectLeadatDataMetricks@datametricks.com/bookings/">
                    <button type="button" className="btn btn-sm font-14  pt-2  px-4" style={{fontSize:'20px',fontWeight:'600',backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'45px',border:'none',outline:'none',paddingBottom:'10px'}}>
                    Book a Free Consultation
                    </button>
                </a>
            </div>

            <div >
                <a href="#formsection">
                    <button type="button" className="btn btn-sm font-14  pt-2 text-white px-4" style={{fontSize:'20px',fontWeight:'600',borderRadius:'45px',border:'1px solid rgba(255, 255, 255, 1)',outline:'none',paddingBottom:'10px'}}>
                   Send Us Your Requirement
                    </button>
                </a>
            </div>
            </div>
                        </div>
                        <div className="col-lg-6 col-12 text-end mt-45">
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mobile-img-1.svg" alt="" className="img-fluid" />
                        </div>
                       
                    </div>
                </div>
              
            </div>
        </section>


        <section>
            <div className="container mt-lg-4">
              
                <div className="row pt25 justify-content-center align-items-center">

                <div className="col-lg-4 col-12 textcenter">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mobile-img-2.svg" alt="" className="img-fluid "/>
                        
                        </div>

                        <div className="col-lg-7 col-12 mt-30">
                            <p className="fw-800 fs-35 plus font-25" style={{color:'rgba(0, 0, 0, 1)'}}>What makes DataMetricks the ideal choice for your mobile app development project?</p>
                            <p className="fs-16 fw-500 plus " style={{color:'rgba(0, 0, 0, 1)'}}>As a premier Mobile App Development Company in India, we provide a comprehensive range of  application design, integration, and management services. From consumer-focused apps to  transformative enterprise-grade solutions, we handle the entire mobile app development process—from  ideation and concept to delivery and continuous support.</p>
                     </div>

                </div>
            </div>

        </section>

        <section className="mt-lg-5 mt-20" style={{backgroundColor:'#F6FBFF'}}>
    <div className="container pt-4 pb-5 ">
        <p className="fw-800 fs-35 plus text-center mb-0 font-23" style={{color:'#1B3567'}}>India's One Of The Highest Rated Mobile Development Company</p>
        <p className="fw-500 fs-20 plus text-center pt-2 font-18" style={{color:'#1B3567'}}> 100+ Glowing 5-Star Reviews Across Top B2B Review Platforms</p>
        <div className="row justify-content-center mt-4 g-2 p-10" >

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/clutch_img1.svg" alt="box-img" style={{height:'100px',width:'96px'}} className="img-fluid  pt-1"  />
                    <img src={ratingstar} alt="" className="pt-2  " />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                                  

                </div>
            </div>
            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img2.svg" alt="box-img" style={{height:'100px',width:'96px'}} className="img-fluid heightwidth pt-1"  />
                    <img src={ratingstar} alt="" className="pt-2  " />
                    <p className="fw-700 fs-32 mont font-23" >4.9</p>
                                  

                </div>
            </div>

            {/* <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img2.svg" alt="box-img" style={{height:'113px',width:'90px'}} className="img-fluid heightwidth1" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.9</p>
                </div>
            </div> */}

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img3.svg" alt="box-img" style={{height:'113px',width:'120px'}} className="img-fluid heightwidth2" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img4.svg" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid heightwidth2" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    {/* <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img5.svg" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid" /> */}
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/googleicon.png" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid heightwidth3 pt-1" />

                    <img src={ratingstar} alt="" className="pt-1"  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

          
        </div>
    </div>

</section>


<section>
    <section>
       <div className='container textcenter text-center  mt-lg-4 mt-20'>
            <p className='fw-600 fs-35 plus mb-0 font-23'>Our Success Stories Transformative</p>
            <p className='fw-600 fs-35 plus font-23 '>Projects and <span style={{color:'rgba(0, 83, 154, 1)'}}>Case Studies </span></p>
       </div>
    </section>

    <section className="my-5">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-10">
              <CustomSlider slides={slides}/>

            </div>
        </div>
    </div>
</section>
</section>



<section className="mt-lg-5 mt-20 ">
<p className="fs-35 fw-700 plus pt-1 text-center font-25">Our Clients</p>
    <div className="p-4 mt-4" style={{backgroundColor:'rgba(255, 255, 255, 1)',boxShadow: '0px 0px 7.3px 0px rgba(175, 175, 175, 0.25)'}}>
        <div className="container">
            <div className="row ">           
                <div className="col-lg-12">
                    <CompanyLogoSlider/>
                </div>
            </div>
        </div>

    </div>

</section>


<section >
            <div className="py-5 mt-5" style={{backgroundColor:'rgba(232, 244, 255, 1)'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                      
                       <p className="fw-800 fs-35 plus  font-25 " style={{color:'rgba(0, 0, 0, 1)'}}>Mobile Application Development Services for Various Platforms</p>  
                       <p className="fw-500 fs-16 plus" style={{color:'rgba(0, 0, 0, 0.6)'}}>Our mobile app developers have over a decade-long expertise in building solutions that meet market needs, empower companies’ brand identity, and encourage business growth and expansion.</p>                   
                     </div>
              
                 </div>

            <div className="row justify-content-center  mt-lg-3 g-0 g-2 pt-10">
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-1.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>iOS App Development</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>As a leading iOS App Development Company, our team ensures seamless integration with the  Apple ecosystem, delivering flawless user experience. Leveraging the latest technologies and  customized features, we develop iOS apps that align with your business goals and surpass user  expectations.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-1.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Android App Development</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our team specializes in building Android software that places your business at the forefront of  any modern Android device. Our Android app development services ensure your app stands out  in a competitive market and delivers a delightful user experience.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-2.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Wearables and Embedded Software</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our Wearables and Embedded Software solutions are designed to seamlessly integrate with  modern devices, enhancing functionality and user experience. We create innovative, reliable  software that powers wearable technology and embedded systems, helping your business stay  ahead in a rapidly evolving market.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-3.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Native Mobile Apps</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>We develop high-performance native mobile apps tailored for iOS and Android, ensuring a  seamless user experience with full access to device features and optimal performance. we create  secure, scalable and intuitive mobile experiences that grow your business and captivate your  end-users.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-4.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Cross-Platform Apps</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our cross-platform app development ensures your app works smoothly on both iOS and  Android, Cross-platform apps are cost-effective and easy to maintain, making them a fantastic  addition to your business.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-4.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Progressive Web Apps</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>We build Progressive Web Apps that combine the best of web and mobile apps, offering fast,  reliable, and engaging user experiences across all platforms with offline capabilities.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

              </div>
            </div>
        </section>


<section >
            <div className=" pt-10" style={{backgroundColor:'rgba(208, 233, 255, 1)'}}>
                <div className="container pt-4 pb-5 ">
                <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                       <p className="fw-800 fs-35 plus  font-25 " style={{color:'rgba(0, 0, 0, 1)'}}>Industries We Specialize In for Mobile App Development  Solutions</p>  
                       <p className="fw-500 fs-16 plus" style={{color:'rgba(0, 0, 0, 0.6)'}}>From startups to enterprises, we’ve gained vast experience in delivering innovative digital solutions for a wide range of clients across multiple industries.</p>                   
                     </div>
              
                 </div>

                 <div className="row justify-content-center  mt-lg-3 g-0 g-2 pt-10">
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-5.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Healthcare Sector</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>We offer tailored mobile app development solutions for the healthcare sector, enhancing patient care,  improving operational efficiency, and enabling seamless communication between providers and patients.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-6.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>eCommerce & Retail</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>We provide mobile app development solutions for the eCommerce and retail industry, enabling seamless  shopping experiences, secure payment options, and personalized customer interactions that drive sales  and boost customer loyalty.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-7.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Travel & Hospitality</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our mobile app development solutions for the travel and hospitality industry streamline booking  processes, enhance guest experiences, and offer real-time travel information, making trips more  convenient and enjoyable.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-8.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Fintech</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>We deliver secure and efficient mobile app development solutions for the fintech sector, enabling digital  banking, payment solutions, and financial management apps that cater to today’s tech-savvy users.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-9.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Education</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>We provide mobile app development solutions for the education sector, delivering innovative learning  platforms, e-learning apps, and tools that foster collaboration, knowledge sharing, and student  engagement.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-10.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>For Entertainment</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our mobile app development solutions for the entertainment industry create engaging, interactive apps  that enhance user experiences, from streaming services to gaming apps and event management.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>


                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-11.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Social Networks</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>We create innovative mobile app development solutions for social networks, fostering connections and  enhancing communication with features like messaging, media sharing, and community engagement.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-4.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Transport and Logistics</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>We provide mobile app development solutions for transport and logistics, optimizing route planning,  real-time tracking, and fleet management to improve efficiency and reduce operational costs.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>


                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-8.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Insurance</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our mobile app development solutions for the insurance industry provide seamless claim management,  policy tracking, and customer service, offering an improved and more efficient user experience.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/mo-icon-4.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Construction</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our mobile app development solutions for the construction industry help manage projects, track  progress, and streamline communication, improving productivity and safety across job sites.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>



              </div>
            </div>
</section>

<section>
    <div className="p-3 " style={{backgroundColor:'rgba(0, 83, 154, 1)'}}>
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-lg-7 col-12">
                    <p className="fs-50 plus fw-800 font-25" style={{color:'rgba(255, 255, 255, 1)'}}>Looking to Accelerate Your Mobile App Development?</p>
                </div>
                <div className="col-lg-4 col-12">
                <div className="textcenter">
                <a href="#formsection">
                    <button type="button" className="btn btn-sm font-14  pt-2  px-4" style={{fontSize:'20px',fontWeight:'600',backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'45px',border:'none',outline:'none',paddingBottom:'10px'}}>
                    Discover how we can help
                    </button>
                </a>
            </div>
                    </div>
            </div>
        </div>
    </div>
</section>
   

<section className="hideinmo">
            <div >
                <div className="container pt-5 pb-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                       <p className="fw-800 fs-35 plus text-center" style={{color:'rgba(0, 0, 0, 1)'}}>Mobile Apps We Develop</p>
                     
                     </div>
              
                 </div>

            <div className="row justify-content-center  mt-1 g-0 g-3">

                <div className="col-lg-4 col-12 col-md-3">
                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                         
                          <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Clinic & Patient Management App</p>
                           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 1)'}}>We’ve developed solutions to manage patient appointments,  medical records, and treatment history, enabling healthcare providers to offer efficient and  personalized care.</p>
                    </div>
                </div>
                
                <div className="col-lg-4 col-12 col-md-3">
                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                           
                             <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Telemedicine & Health Monitoring Apps</p>
                           
                           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>These apps enable remote consultations, real-time  health monitoring, and medication reminders, improving patient engagement and care delivery.</p>
                    </div>
                </div>

                <div className="col-lg-4 col-12 col-md-3">
                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                          
                            <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Streaming & Media Apps</p>
                           
                           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We’ve created apps for music and video streaming, offering users a  seamless and personalized entertainment experience on-the-go.</p>
                    </div>
                </div>
                 
              
                 </div>


            <div className="row justify-content-center pt-3 g-0 g-3">

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
         
          <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>E-learning & Virtual Classroom Apps</p>
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We build platforms that support online courses, virtual  classrooms, live sessions, and digital assessments, enhancing the learning experience for  students and educators.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
           
             <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Event & Ticketing Apps</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>These apps help users discover events, purchase tickets, and stay  informed about upcoming entertainment happenings.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
          
            <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Learning Management Systems (LMS)</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>These apps provide features for course management,  grading, and student-teacher interaction, creating a streamlined learning environment.</p>
    </div>
</div>
 

            </div>

            <div className="row justify-content-center pt-3 g-0 g-3">

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
         
          <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Booking & Itinerary Apps</p>
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We create apps for travel bookings, hotel reservations, and itinerary  management, providing users with smooth planning and travel experience.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
           
             <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Hotel & Guest Management Apps</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>These solutions help hotel managers streamline check-ins,  guest communication, and service management, ensuring a seamless guest experience.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
          
            <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Mobile Banking & Payment Apps</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We develop apps for secure mobile banking, fund transfers,  and payments, offering users smooth, reliable financial management experience.</p>
    </div>
</div>
 

            </div>

            <div className="row justify-content-center pt-3 g-0 g-3">

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
         
          <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Investment & Wealth Management Apps</p>
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}> These apps provide tools for investment tracking,  portfolio management, and financial planning, helping users manage their wealth effectively.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
           
             <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Project Management & Tracking Apps</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We build apps to manage construction projects, track  progress, and allocate resources, improving team collaboration and productivity</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
          
            <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Safety & Compliance Monitoring Apps</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>These apps ensure worker safety, track safety  compliance, and streamline reporting for construction projects.</p>
    </div>
</div>
 

            </div>

            <div className="row justify-content-center pt-3 g-0 g-3">

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
         
          <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Fleet Management & Tracking Apps</p>
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We develop solutions for managing fleets, optimizing  routes, and tracking vehicles in real time, improving efficiency and reducing operational costs.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
           
             <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Delivery & Logistics Management App</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}> These apps streamline package tracking, inventory  management, and delivery coordination, enhancing logistics operations.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
          
            <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Policy & Claims Management Apps</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We create apps for managing insurance policies, submitting  claims, and tracking coverage details, making the process transparent and easy for users.</p>
    </div>
</div>
 

            </div>

              
            <div className="row justify-content-center pt-3 g-0 g-3">

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
         
          <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Insurance Quotation & Purchase Apps</p>
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>These apps allow users to compare, purchase, and  manage insurance plans directly from their mobile devices</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
           
             <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Social media & Messaging Apps</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We develop apps that enable users to connect, share content,  and communicate with friends, family, and communities.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
          
            <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Community Building & Event Apps</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>These apps allow users to create, manage, and join social  events, fostering stronger community engagement and interactions.</p>
    </div>
</div>
 

            </div> 

              


              </div>
            </div>
</section>
       
<section>

<section className='d-none d-sm-none d-lg-block d-md-block'>

<div >
        <p className="fs-35 fw-700 plus text-center mb-0 font-20">Our Streamlined Mobile Development</p>
        <p className="fs-35 fw-700 plus text-center font-20"> Process from Concept to Launch</p>

 </div>
 <div className="container mt-lg-5 ">
<div className="row ms-lg-5">
  <div className="col-lg-4 ">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt=""  />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">
          1. Discovery and Planning
        </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
         Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot2} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">2. Design</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Bringing your vision to life with captivating visuals and
          intuitive user interfaces.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt="" />
      </div>
    
      <div>
        <p className="sec5title mb-0 mt-1">3. Development</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Transforming designs into functional <br /> websites with
          clean code and seamless functionality.
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<div className="container">

<div className="col-lg-12 ms-lg-5">
<img className="img-fluid" src={stagesbg} alt="" />
</div>
</div>
<div className="container pt-4 ">
<div className="row ms-lg-5">
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">4. Testing </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Thoroughly checking every aspect of the website to ensure
          it meets quality standards and performs flawlessly.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot4} alt="" />
      </div>
     
      <div>
        <p className="sec5title mb-0 mt-1">5. Launch</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Deploying the website to the live <br /> environment and
          making it accessible to your audience.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">6. Beyond</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Providing ongoing support, maintenance, and updates to
          ensure your website remains optimized and up to date.
        </p>
      </div>
    </div>
  </div>
</div>



</div>
    
</section>

{/* mobile view */}
<section className='d-block d-sm-block d-lg-none d-md-none'>
  <div className='container mt-4 '>
  <p className="fs-35 fw-700 plus text-center mb-0 font-20">Our Streamlined Web Development</p>
  <p className="fs-35 fw-700 plus text-center font-20"> Process from Concept to Launch</p>
    <div className='row justify-content-center'>
      {/* <div className='col-3'>
         <img style={{height:'532px'}} src="/assets/" alt="" className="img-fluid" />
      </div> */}
      <div className='col-10 mt-2'>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot1} alt=""  />
          </div>
          <div>
            <p className="sec5title fs-15 mb-0 mt-1">
              1. Discovery and Planning
            </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12 "
            >
            Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot2} alt="" />
          </div>
        
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">2. Design</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Bringing your vision to life with captivating visuals and
              intuitive user interfaces.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot1} alt="" />
          </div>
        

          <div>
            <p className="sec5title mb-0 mt-1 fs-15">3. Development </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Transforming designs into functional <br /> websites with
              clean code and seamless functionality.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot3} alt="" />
          </div>
       
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">4. Testing </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Thoroughly checking every aspect of the website to ensure
              it meets quality standards and performs flawlessly.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot4} alt="" />
          </div>
       
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">5. Launch</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Deploying the website to the live <br /> environment and
              making it accessible to your audience.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot3} alt="" />
          </div>
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">6. Beyond</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Providing ongoing support, maintenance, and updates to
              ensure your website remains optimized and up to date.
            </p>
          </div>
        </div>
      </div>
    </div>
    

  </div>
</section>

</section>

    <section>
                <div className="container mt-lg-5 mt-20">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-12 col-md-6">
                        <div className="text-center">
                                  <p className="fw-800 fs-33 plus font-23 " style={{color:'#1B3567'}}>What  We Believe in</p>
                                <p className="fw-500 fs-18 plus px-lg-5 font-17" style={{color:'#000000',lineHeight:'30px'}}>We Committed To Delivering High-Quality Projects With a Strong Focus On On-Time Delivery. Our Team Prioritizes Efficient Workflows, Clear Communication, And Tailored Solutions To Ensure Every Project Is Completed Within The Agreed Timeline, Meeting And Exceeding Client Expectations</p>
                        </div>
                        </div>
                    </div>
                </div>
    </section>

            <section>
                <div className="container mt-4">
                    <div className="row g-2">
                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 h-100">
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon2.svg" alt="" />
                            <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'#00539A'}}>Security</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'#7C7C7C'}}>Our aim is to bring a new approach to secure your software based on integrity, trust and transparency</p>

                        </div>
                        </div>

                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 h-100">
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon3.svg" alt="" />
                            <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'#00539A'}}>Support</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'#7C7C7C'}}>The best gift is time, and our 24/7 dedicated support team is always available to address your queries and concerns.</p>

                        </div>
                        </div>

                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 h-100">
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon4.svg" alt="" />
                            <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'#00539A'}}>Integrity</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'#7C7C7C'}}>Our expert team offers the best advice tailored to your product. We ensure guidance that drives success.</p>

                        </div>
                        </div>

                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 h-100">
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon5.svg" alt="" />
                            <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'#00539A'}}>Transparency</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'#7C7C7C'}}>Transparency means embracing new ideas, technology, and approaches. We firmly believe in fostering learning and openness.</p>

                        </div>
                        </div>
                    </div>
                </div>
            </section>



  <div id="formsection">

</div>

      
<section  className=" d-none d-sm-none d-md-block d-lg-block" style={{marginTop:'7%'}}>
   <NewForm/>

</section>

{/* Mobile View  */}
<section className="mt-lg-4 d-block d-sm-block d-md-none d-lg-none">
    <div className=" pt-3 pb-5 px-2 mt-30" style={{backgroundColor:'rgba(242, 242, 242, 1)'}}>
        <div className="container">
         
                      <p className="fw-600 text-center fs-23 plus mb-0 pt-1" >What can we help you with?</p>
                      <p className="fw-500 text-center fs-17 plus pt-3" style={{lineHeight:'22px'}}>Get Custom Solutions, Recommendations, or, Estimates.
                      Confidentiality & Same Day Response Guaranteed!</p>
    <div className="pt-1">
       <Form/>
    </div>

    <div className="d-flex justify-content-center gap-2 mt-4 ">

<div className="d-flex  gap-2">
    <div>
          <img src= "https://d3vqz9qyn2r1xe.cloudfront.net/imges/green-icon-form.svg" alt="" className="pt-1" />
    </div>
    <div>
           <p className="fw-600 plus fs-17  pt-1">100% Confidential</p>
    </div>
</div>

<div className="d-flex  gap-2">
    <div>
          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/green-icon-form.svg" alt="" className="pt-1" />
    </div>
    <div>
           <p className="fw-600 plus fs-17  pt-1">We sign NDA</p>
    </div>
</div>

</div>

<div className="d-flex gap-4 mt-4">
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon1.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon2.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon3.svg" alt="" className="img-fluid pt-1" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon4.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon5.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon6.svg" alt="" className="img-fluid pt-2" />
            </div>
    </div>

      </div>
      </div>
</section>



<section className="mt-5">
    <GoogleReviewsWidget/>
</section>






        <section style={{backgroundColor:'rgba(208, 233, 255, 1)'}}>
      <div className="mt-5 pt-5 pb-4">
          <Faqaccordian faqAccordianData={faqDatamobile}/>
          </div>
      </section>



        <section >
                <Footer/>
        </section>

    </div>
);
};
export default MobileDevlopmentPage;