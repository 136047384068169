import Footer from "../Components/footer";
import Header from "../Components/Header";
import Form from "../Components/Form";
import React, { useState} from 'react';
import { useNavigate } from "react-router-dom";
import Faqaccordian from '../Components/Faqaccordian';
import CompanyLogoSlider from "../Components/CompanyLogoSlider";
import CustomSlider from "../Components/CustomSlider";
import Bigcontact from '../Components/Bigcontact';
import Modal from 'react-bootstrap/Modal';
import GoogleReviewsWidget from "../Components/GoogleReviewsWidget";
import NewForm from "../Components/NewForm";



const WebsitePage = () =>  {

    const projectimg1 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img1.svg';
    const projectimg3 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img3.svg';
    const projectimg5 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img5.svg';
    const projectimg7 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img7.svg';
    const projectimg8 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img8.svg';
    const projectimg9 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img9.svg';
    const projectimg2 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img2.svg';

  const slides = [
   
    {
      id: 1,
      title: "HealthOMax Web",
      description:
            "An extremely integrated platform of medical professionals and patients. Our website provides access to the same power as of our mobile application available anywhere, anytime, on any device.",                
      image: projectimg9,
      url1:"/our-completed-projects/healthomax-website"

    },
   
    {
      id: 2,
      title: "Lookplex",
      description:
        "Salon at Home Services Website For a salon at home services provider, we designed a sleek and modern website with an easy booking feature. The site allows customers to book appointments effortlessly and explore various beauty services offered.",
      image: projectimg1,
      url1:"/our-completed-projects/lookplex"

    },
    {
        id: 3,
        title: "Monovogue",
        description:
          "We developed a dynamic and visually appealing e-commerce platform that enhances the shopping experience. The site includes secure payment gateways, intuitive navigation, and a responsive design for seamless browsing on all devices.",
        image: projectimg8,
        url1:"/our-completed-projects/monovouge"

      },
   
      {
        id: 4,
        title: "3D Gardening",
        description:
          "We designed a vibrant and user-friendly website for a client providing gardening services. The site show cases their expertise, offers detailed service descriptions, and includes an easy-to-use contact form for customer inquiries.",
        image: projectimg3,
        url1:"/our-completed-projects/gardening"

      },
   
        {
          id: 5,
          title: "Narayana Home Services",
          description:
            "We developed a comprehensive website for Narayana Home, a home worker providers service company. The site allows users to easily hire cooks, maids, chefs, and watchmen, ensuring a seamless hiring process.",
          image: projectimg5,
          url1:"/our-completed-projects/narayana-homes"

        },
     
          {
              id: 6,
              title: "SmartCare",
              description:
                "We created a healthcare platform, SmartCare, that enables patients to easily book appointments, find doctors, store medical records, and save vital health information, streamlining their healthcare experience.",
              image: projectimg7,
              url1:"/our-completed-projects/smartcare"

            },
            {
                id: 7,
                title: "Cuddly koala",
                description:
                  "We created an informative and professional website for a doctor and lactation consultant. The site features comprehensive service information, patient resources, and an intuitive appointment booking system.",
                image: projectimg2,
                url1:"/our-completed-projects/cuddly-cola"
  
              },
           
          
  ];

 const ratingstar = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/ratingstar.svg";
//  const newhomeimg12 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newhome-img12.svg";
//  const newhomeimg13 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/proimg1.svg";
//  const newhomeimg14 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newhome-img14.svg";
//  const newhomeimg15 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/proimg2.svg";

 
 var faqDataPage=[
    {que1:"1. What is web development and why is it important?",ans1:"Web development involves creating and maintaining websites. It includes web design, web publishing, web programming, and database management. A well-developed website enhances a business's online presence, boosts credibility, attracts customers, and drives sales." },
    {que1:"2. How much does it cost to develop a website? ", ans1:"The cost of developing a website can vary greatly depending on factors such as complexity, features, design, and the development company."},
    {que1:"3. How long does it take to build a website? ",ans1:"The time required to build a website depends on its complexity and the specific requirements of the project. A simple website might take a few weeks, while a more complex site with custom features can take several months."},
    {que1:"4. What are the key features of a good website? ",ans1:"A good website should be user-friendly, responsive (works on all devices), fast-loading, secure, SEO-optimized, and visually appealing. It should also have clear navigation, engaging content, and effective call-to-actions. "},
    {que1:"5. Do I need a mobile-friendly website? ",ans1:"Yes, having a mobile-friendly (responsive) website is essential as more people access the internet through mobile devices. A mobile-friendly site improves user experience, boosts SEO rankings, and increases the chances of retaining visitors. "},
    {que1:"6. What is SEO and why is it important for my website? ",ans1:"SEO (Search Engine Optimization) involves optimizing your website to rank higher in search engine results. It is important because higher rankings lead to increased visibility, more traffic, and potentially more customers. "},
    {que1:"7. Can I update my website content by myself? ",ans1:"Yes, if your website is built with a Content Management System (CMS) like WordPress, you can easily update your content without needing technical knowledge. A CMS allows you to add, edit, and delete content as needed. " },
    {que1:"8. What is the difference between web design and web development? ",ans1:"Web design focuses on the visual aspects of a website, including layout, color schemes, and graphics. Web development, on the other hand, involves coding and programming to create the website’s functionality and features. " },
    {que1:"9. Why should I choose DataMetricks for my web development needs? ",ans1:"Datametricks Consulting offers expert web development services tailored to your business needs. We prioritize user experience, use the latest technologies, and provide ongoing support to ensure your website is always optimized and effective. "},
    {que1:"10. What kind of support do you provide after the website is launched? ",ans1:"We offer comprehensive support and maintenance services, including regular updates, security checks, performance optimization, and troubleshooting to ensure your website remains secure and performs at its best. "},    
];

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);


return(

    <div>
        <section>
                <Header/>
        </section>

        {/* <section style={{marginTop:'50px'}}>
            <div className="p-lg-5 p-10 text-lg-center text-sm-start pb-30" style={{backgroundColor:'rgba(0, 83, 154, 1)'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                        <p className="fw-600 fs-23 plus text-white pt-4 mb-0 hideinmo ">Best Website Development Services in India</p>
                       <p className="fw-800 fs-45 plus text-white mb-0 pt-lg-2 font-30 pt25">Website Development Company In India</p>
                       <p className="fw-600 fs-23 plus text-white pt-2 hideinmo">Website Development Services</p>
                       <div className="d-flex gap-3 text-lg-center mt-4" >
                    <div>
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon-1.svg " alt="" />
                    </div>
                    <div>
                        <p className="fw-500 fs-19 plus text-white ">At DataMetricks Consulting Pvt. Ltd, we pride ourselves on being a top-notch website  development company in India. Our expert team of web developers and designers specializes in  offering high-quality web development services, SEO, and mobile app development solutions to help  businesses thrive online.</p>
                    </div>
                </div>

                <div className="d-flex gap-3 text-lg-center mt-3" >
                    <div>
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon-2.svg " alt="" />
                    </div>
                    <div>
                        <p className="fw-500 fs-19 plus text-white">Looking for a website that ranks high on Google? Hire an Indian website developer who can create a  fast, customized, and SEO-friendly website—all at an affordable price. Our skilled developers are  dedicated to delivering user-friendly and visually appealing websites tailored to your business needs.</p>
                    </div>
                </div>

                <p className="fw-500 fs-16 plus text-white mt-3 hideinmo">Take a quick call now and let us help you with custom website design and development!</p>
                <p className="fw-700 fs-15 plus text-white mt-3 hideindesktop">Take a quick call now and let us help you with custom website design and development!</p>

            <div className="textcenter">
                <a href="https://outlook.office365.com/owa/calendar/MihirProjectLeadatDataMetricks@datametricks.com/bookings/">
                    <button type="button" className="btn btn-sm   mt-lg-3 font-14 mt-05" style={{fontSize:'17px',fontWeight:'600',backgroundColor:'#E77F00',color:'white',borderRadius:'45px',border:'none',outline:'none',paddingLeft:'70px',paddingRight:'70px',paddingTop:'10px',paddingBottom:'13px'}}>
                    Get top-notch web developers for<br/> your project today! 
                    </button>
                </a>
            </div>
                        </div>
                    </div>
                </div>
              
            </div>
        </section> */}

<section className="Websecbackground1" style={{marginTop:'5%'}}>
  <div className="text-center container">
    <div className="row justify-content-center">
      <div className="col-lg-12">
        <p className="fs-60 fw-800 plus text-white font-30">Website Development Company in India</p>
      </div>
      <div className="col-lg-8">
      <p className="fs-18 fw-500 plus pt-2 font-15" style={{ color: 'rgba(255, 255, 255, 0.8)' }}>
        Affordable, Professional, and Tailored Web Development Services, Save costs without compromising quality. Web, software, and app development. <span className="fw-700 fs-20 plus font-15" style={{color:'rgba(255, 255, 255, 1)'}}>Contact us now!</span> 
        </p>
        <div className="d-lg-flex gap-3 pt-5 justify-content-center mt-20">
           
             <div>
                <a href="https://outlook.office365.com/owa/calendar/MihirProjectLeadatDataMetricks@datametricks.com/bookings/">
                    <button type="button" className="btn btn-sm font-14  pt-2  px-4" style={{fontSize:'20px',fontWeight:'600',backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'45px',border:'none',outline:'none',paddingBottom:'10px'}}>
                    Book a Free Consultation
                    </button>
                </a>
            </div>
            

             <div className="pt-20">
                 <a href="#formsection" >   <button type="button" className="btn btn-sm font-14  pt-2  px-4" style={{fontSize:'20px',fontWeight:'600',backgroundColor:'rgba(0, 83, 154, 1)',borderRadius:'45px',border:'none',outline:'none',paddingBottom:'10px',color:'white'}}>
                    Send us your Requirement
                    </button></a>
              
            </div>
            <Modal show={show} onHide={handleClose} className='modal1'   >
      <Modal.Header closeButton className='modelheader1' ></Modal.Header>
        <Modal.Body className='modelbody1'>  
           <Bigcontact/>
        </Modal.Body>
       
      </Modal>
          
        </div>
        </div>
    </div>
  </div>
</section>


    {/* Mobile-view  */}
        <section>
         <div className="hideindesktop textcenter pt30">
             <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-img1.svg" alt="" className="img-fluid "/>
         </div>
        </section>

        <section>
            <div className="container mt-lg-5 mt-25 plr20">
                <p className="fw-600 fs-18 plus font-16 " style={{color:'rgba(19, 19, 19, 1)'}}>BEST WEBSITE DEVELOPMENT COMPANY IN INDIA </p>
                <p className="fw-800 plus fs-35 font-23 hideinmo" style={{color:'rgba(0, 0, 0, 1)'}}>Website Development Services in India </p>
                <p className="fw-800 fs-24 plus mb-0 font-20" style={{color:'rgba(0, 83, 154, 1)'}}>Searching for a reliable website development company in India? Look no further!</p>

                <div className="row pt25">

                    <div className="col-lg-8 col-12 ">
                        <ul className="ps-3 mt-lg-3">
                            <li className="fw-500 plus fs-16" style={{color:'rgba(0, 0, 0, 1)'}}>
                            At <span className="fw-700 fs-16 plus" style={{color:'rgba(0, 83, 154, 1)'}}>DataMetricks ,</span> we have a team of skilled web developers, designers, SEO experts, and UI/UX  specialists who are passionate about crafting exceptional websites.
                            </li>

                            <li className="fw-500 plus fs-16 pt-4 " style={{color:'rgba(0, 0, 0, 1)'}}>
                            As a trusted web development company, we deliver the best web design and development services at  affordable prices without compromising on quality. With our team of expert developers and designers,  we’ve completed over <span className="fw-700 fs-16 plus" style={{color:'rgba(0, 83, 154, 1)'}}>100 projects,</span> delivering custom websites, mobile apps, and software solutions  for clients across India and other countries.
                            </li>

                            <li className="fw-500 plus fs-16 pt-4" style={{color:'rgba(0, 0, 0, 1)'}}>
                            We craft bespoke websites tailored to startups and enterprises, combining innovation with a deep  understanding of what drives online success.
                            </li>
                        </ul>

                    </div>
                    <div className="col-lg-4 col-6 text-end hideinmo">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-img1.svg" alt="" className="img-fluid "/>
                        
                        </div>
                </div>
            </div>

        </section>

        <section className="mt-lg-5 mt-20" style={{backgroundColor:'#F6FBFF'}}>
    <div className="container pt-4 pb-5 ">
        <p className="fw-800 fs-35 plus text-center mb-0 font-23" style={{color:'#1B3567'}}>India's One Of The Highest Rated Web Development Company</p>
        <p className="fw-500 fs-20 plus text-center pt-2 font-18" style={{color:'#1B3567'}}> 100+ Glowing 5-Star Reviews Across Top B2B Review Platforms</p>
        <div className="row justify-content-center mt-4 g-2 p-10" >

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/clutch_img1.svg" alt="box-img" style={{height:'100px',width:'96px'}} className="img-fluid  pt-1"  />
                    <img src={ratingstar} alt="" className="pt-2  " />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                                  

                </div>
            </div>
            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img2.svg" alt="box-img" style={{height:'100px',width:'96px'}} className="img-fluid heightwidth pt-1"  />
                    <img src={ratingstar} alt="" className="pt-2  " />
                    <p className="fw-700 fs-32 mont font-23" >4.9</p>
                                  

                </div>
            </div>

            {/* <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img2.svg" alt="box-img" style={{height:'113px',width:'90px'}} className="img-fluid heightwidth1" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.9</p>
                </div>
            </div> */}

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img3.svg" alt="box-img" style={{height:'113px',width:'120px'}} className="img-fluid heightwidth2" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img4.svg" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid heightwidth2" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    {/* <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img5.svg" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid" /> */}
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/googleicon.png" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid heightwidth3 pt-1" />

                    <img src={ratingstar} alt="" className="pt-1"  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

          
        </div>
    </div>

</section>



<section>
    <section>
       <div className='container textcenter text-center  mt-lg-4 mt-20'>
            <p className='fw-600 fs-35 plus mb-0 font-23'>Our Success Stories Transformative</p>
            <p className='fw-600 fs-35 plus font-23 '>Projects and Case Studies </p>
       </div>
    </section>

    <section className="my-5">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-10 ">
              <CustomSlider slides={slides}/>

            </div>
        </div>
    </div>
</section>
</section>

<section className="mt-lg-5 mt-20 ">
<p className="fs-35 fw-700 plus pt-1 text-center font-25">Our Clients</p>
    <div className="p-4 mt-4" style={{backgroundColor:'rgba(255, 255, 255, 1)',boxShadow: '0px 0px 7.3px 0px rgba(175, 175, 175, 0.25)'}}>
        <div className="container">
            <div className="row ">           
                <div className="col-lg-12">
                    <CompanyLogoSlider/>
                </div>
            </div>
        </div>

    </div>

</section>


<section className="mt-4 pt-4 pb-5">
            <div className="container">

                <p className="fw-800 plus fs-35 text-center hideinmo">Affordable Website Design Services with <br/> Transparent Pricing</p>
                <p className="fw-700 plus fs-25 text-center hideindesktop">Affordable Website Design Services with Transparent Pricing</p>


                <div className="row justify-content-center mt-lg-4 g-0 g-3 p-20">
                    <div className="col-lg-5 col-12 col-md-3">
                        <div className=" pt-4 pb-5  h-100 textcenter" style={{  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',borderRadius:'12px',paddingLeft:'35px',paddingRight:'30px'}}>
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon9.svg" alt="" />
                            <p className="fw-700 fs-20 plus pt-2" style={{color:'rgba(0, 0, 0, 1)'}}>Get a Fixed Price Quote </p>
                            <p className="fw-500 fs-15  plus " style={{color:'rgba(0, 0, 0, 1)'}}>At our web development company, we offer a fixed-price quote for website development, ensuring  transparency and no hidden costs. Once your requirements are clearly documented, we proceed with  minimal changes during the project to deliver as promised.</p>
                            <a href="#formsection">
                    <button type="button" className="btn btn-sm py-2 px-4 mt-lg-3 font-14" style={{fontSize:'14px',fontWeight:'600',backgroundColor:'rgba(0, 83, 154, 1)',color:'white',borderRadius:'6px',border:'none',outline:'none'}}>
                    Share your Requirements
                    </button>
                </a>

                        </div>

                    </div>

                    <div className="col-lg-5 col-12 col-md-3">
                        <div className=" pt-4 pb-5  h-100 textcenter" style={{  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',borderRadius:'12px',paddingLeft:'35px',paddingRight:'30px'}}>
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon9.svg" alt="" />
                            <p className="fw-700 fs-20 plus pt-2" style={{color:'rgba(0, 0, 0, 1)'}}>Hire a Website Developer for Just $20/hr</p>
                            <p className="fw-500 fs-15 plus " style={{color:'rgba(0, 0, 0, 1)'}}>Choose to hire our experienced website developers at an affordable rate of $20 USD per hour. With  over 5 years of expertise in full-stack web development, we ensure clear communication and regular  updates via Teams, Zoom, email, or Skype.</p>
                            <a href="https://outlook.office365.com/owa/calendar/MihirProjectLeadatDataMetricks@datametricks.com/bookings/">
                            <button type="button"  className="btn btn-sm py-2 px-4 mt-lg-3 font-14" style={{fontSize:'14px',fontWeight:'600',backgroundColor:'rgba(0, 83, 154, 1)',color:'white',borderRadius:'6px',border:'none',outline:'none'}}>
                            Book a Free Consultaiton
                    </button></a>
                        </div>

                    </div>

                </div>
            </div>
        </section>





<section >
            <div className=" mt-lg-2" style={{backgroundColor:'rgba(232, 244, 255, 1)'}}>
                <div className="container pt-4 pb-5 ">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                       <p className="fw-800 fs-35 plus text-center newfont-24" style={{color:'rgba(0, 0, 0, 1)'}}>Grow Your Business with Our Expert Web Development Services</p>
                       <p className="fw-500 fs-17 plus text-center font-15 fw700" style={{color:'rgba(0, 0, 0, 0.6)'}}>Partner with India’s top website development company to create websites that stand out. Here’s why  you should choose us:</p>
                     
                     </div>
              
                 </div>

            <div className="row justify-content-center  mt-3 ">
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon1.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Expertise You Can Trust</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-16 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our team of skilled professionals ensures your website is built using the latest technologies and  industry best practices.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3 pt-10">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon2.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Tailored Solutions for Your Business</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-16 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>We craft custom websites that reflect your brand identity and meet your unique goals.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center  mt-lg-4 pt-10 ">
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon3.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Affordable Excellence</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-16 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Enjoy top-quality web design and development services at competitive prices</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3 pt-10">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon4.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >SEO-Friendly & Responsive Designs</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-16 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>All our websites are mobile-friendly, fast, and optimized for search engines to help you reach a wider  audience.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>



              </div>
            </div>
</section>


<section>
  <div className="container mt-lg-5 mt-30">
  <p className="fw-800 fs-30 plus text-center font-25" style={{color:'rgba(0, 0, 0, 1)'}}>Industry - Leading Technologies We Utilized</p>
  <div className="text-center pt-4">
  <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/technology-img.svg" alt="" className="img-fluid" />
  </div>
  </div>
</section>


<section className="mt-5" >
            <div style={{backgroundColor:'rgba(246, 251, 255, 1)'}}>
                <div className="container pt-lg-5 pt-20  pb-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                       <p className="fw-800 fs-35 plus text-center newfont-24" style={{color:'rgba(0, 0, 0, 1)'}}>Trusted Website Development Company in India for Diverse Industries</p>
                       <p className="fw-500 fs-16 plus text-center " style={{color:'rgba(0, 0, 0, 0.6)'}}>At DataMetricks, we are committed to providing professional website development services across a  wide range of industries, including retail, real estate, finance, education, healthcare, and more. As a  leading web development company in India, we collaborate with offshore partners to deliver  exceptional web design, application development, and eCommerce solutions.</p>
                     
                     </div>
              
                 </div>

            <div className="row justify-content-center  mt-3 ">
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon20.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Healthcare Solutions </p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13" style={{color:'rgba(0, 0, 0, 0.7)'}}>We specialize in creating HIPAA-compliant and secure web applications for the healthcare industry.  Our team leverages its deep expertise to offer customized healthcare development solutions that meet  the industry's highest standards using the latest technologies.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3 pt-10">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon21.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >E-commerce & Retail </p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13" style={{color:'rgba(0, 0, 0, 0.7)'}}>We build custom software solutions tailored for the retail and e-commerce sector. Our team focuses on  creating affordable, secure, and fully customized e-commerce websites that provide a seamless  shopping experience for your customers.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center  mt-lg-4 pt-10 ">
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon22.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Tourism and Travel </p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13" style={{color:'rgba(0, 0, 0, 0.7)'}}>Using the most recent technologies and innovation, our skilled experts offer top-notch travel web  applications, including booking systems for online travel agencies and consistently exceeds  expectations, delivering exceptional solutions that elevate the tourism and travel industry.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3 pt-10">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon23.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Banking and Finance </p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our web development services for banking and finance leverage the latest technologies to enhance  user experience and increase revenue. We specialize in creating secure, custom financial websites that  meet the specific needs of this ever-evolving sector.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center  mt-lg-4 pt-10 ">
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon24.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Education and E Learning </p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13" style={{color:'rgba(0, 0, 0, 0.7)'}}>We design and develop custom e-learning solutions, such as online training websites, learning  management systems (LMS), and corporate learning platforms. Our expert team builds robust,  scalable, and cost-effective web applications that empower learning and growth.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3 pt-10">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon25.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Entertainment and Media </p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our development team creates dynamic media and entertainment websites, including multi-screen  streaming platforms, online promotion sites, and media content distribution systems. We help clients  achieve their digital transformation goals with cutting-edge solutions tailored to the entertainment  industry.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>


              </div>
            </div>
</section>



<section className=" " style={{backgroundColor:'rgba(208, 233, 255, 1)'}}>
            <div className="container pt-4 pb-5">
                <p className="fw-800 plus fs-35 text-center font-25" style={{color:'rgba(0, 0, 0, 1)'}}>Why Choose Our Website Development Company in India?</p>
                {/* <p className="fw-600 fs-19 plus text-center newfont-20 fw500 pt-10" style={{color:'rgba(0, 0, 0, 1)'}}>Grow Your Business with Our Top-Notch Web Development Solutions</p> */}

                {/* <div className="row  justify-content-center mt-5">
                    <div className="col-lg-4 text-center">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-img2.svg" alt="" className="img-fluid" />
                    </div>
                    <div className="col-lg-6 hideinmo">
                        <div style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'16px',boxShadow:'0px 0px 4px 0px rgba(0, 0, 0, 0.25)',paddingLeft:'30px',paddingRight:'30px',paddingTop:'25px',paddingBottom:'20px'}}>
                            <p className="fw-500 fs-18 plus">Whether you’re a startup or an established enterprise,<span className="fw-700 plus fs-20" style={{color:'rgba(0, 83, 154, 1)'}}> DataMetricks Consulting Pvt Ltd </span>is your one stop solution for professional and result-driven website development services.</p>
                        </div>
                        </div>
                </div> */}

                {/* <p className="fw-800 fs-35 plus text-center mt-5  font-25" style={{color:'rgba(0, 0, 0, 1)'}}>Get the best Website Development Services in India</p> */}

                <div className="row justify-content-center mt-4 g-0 g-2 hideinmo">
                    <div className="col-lg-3 col-12 col-md-3">
                        <div className="text-center p-4 h-100" style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'10px'}}>
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon9.svg" alt="" />
                            <p className="fw-700 fs-20 plus pt-2" style={{color:'rgba(0, 0, 0, 1)'}}>Affordable Pricing</p>
                            <p className="fw-500 fs-15 plus " style={{color:'rgba(23, 23, 23, 1)'}}>Hire dedicated website developers starting at just $20/hour.</p>

                        </div>

                    </div>

                    <div className="col-lg-3 col-12 col-md-3">
                        <div className="text-center p-4 h-100" style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'10px'}}>
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon10.svg" alt="" />
                            <p className="fw-700 fs-20 plus pt-2" style={{color:'rgba(0, 0, 0, 1)'}}>Global Clientele</p>
                            <p className="fw-500 fs-15 plus " style={{color:'rgba(23, 23, 23, 1)'}}>Trusted by clients across different regions.</p>

                        </div>

                    </div>

                    <div className="col-lg-3 col-12 col-md-3">
                        <div className="text-center p-4 h-100" style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'10px'}}>
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon11.svg" alt="" />
                            <p className="fw-700 fs-20 plus pt-2" style={{color:'rgba(0, 0, 0, 1)'}}>Proven Track Record</p>
                            <p className="fw-500 fs-15 plus " style={{color:'rgba(23, 23, 23, 1)'}}>Over 100+ projects completed for startups and enterprises.</p>

                        </div>

                    </div>
                </div>

                <div className="row justify-content-center pt-2 g-0 g-2">
                    <div className="col-lg-3 col-12 col-md-3">
                        <div className="text-center p-4 h-100" style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'10px'}}>
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon9.svg" alt="" />
                            <p className="fw-700 fs-20 plus pt-2" style={{color:'rgba(0, 0, 0, 1)'}}>Cutting-Edge Technology</p>
                            <p className="fw-500 fs-15 plus " style={{color:'rgba(23, 23, 23, 1)'}}>We stay updated with the latest trends to deliver innovative solutions.</p>

                        </div>

                    </div>

                    <div className="col-lg-3 col-12 col-md-3">
                        <div className="text-center p-4 h-100" style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'10px'}}>
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon10.svg" alt="" />
                            <p className="fw-700 fs-20 plus pt-2" style={{color:'rgba(0, 0, 0, 1)'}}>24 / 7 Support</p>
                            <p className="fw-500 fs-15 plus " style={{color:'rgba(23, 23, 23, 1)'}}>Our team is always available to assist you via Teams, Skype, email, or Zoom.</p>

                        </div>

                    </div>

                
                </div>
            </div>
        </section>




        <section >
            <div className="mt-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                       <p className="fw-800 fs-35 plus text-center font-25" style={{color:'rgba(0, 0, 0, 1)'}}>Hire India’s Web Design & Development Experts</p>                     
                     </div>
              
                 </div>

            <div className="row justify-content-center  mt-lg-3 g-0 g-2 pt-10">
                <div className="col-lg-6 col-12 col-md-3">

                <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon19.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Ecommerce Website Development</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-16 plus font-13" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our developers specialize in creating custom online stores and eCommerce web applications on PHP,  WordPress and Laravel at competitive prices.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-6 col-12 col-md-3 ">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon15.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >WordPress Website Development</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-16 plus font-13" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our expert team of dedicated WordPress developers specializes in creating custom websites on  WordPress at an affordable cost.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-6 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon16.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Magento Website Development</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-16 plus font-13" style={{color:'rgba(0, 0, 0, 0.7)'}}>With certified Magento developers, our web development agency excels in designing and building  Magento websites with robust eCommerce capabilities.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-6 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon17.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >PHP Web Development</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-16 plus font-13" style={{color:'rgba(0, 0, 0, 0.7)'}}>As a leading PHP web development company in India, we are committed to delivering secure, custom  PHP web applications with exceptional quality.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-6 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon18.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Laravel Web Development</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-16 plus font-13" style={{color:'rgba(0, 0, 0, 0.7)'}}>We provide professional Laravel web development services, backed by a skilled team of 20+  developers.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-6 col-12 col-md-3">

                     
                    <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon14.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Fast, Secure, and SEO-Friendly Services</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-16 plus font-13 " style={{color:'rgba(0, 0, 0, 0.7)'}}>Leading a website company specializing in robust, scalable, and secure solutions.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>



              </div>
            </div>
        </section >


<div id="formsection">

</div>


<section  className=" d-none d-sm-none d-md-block d-lg-block" style={{marginTop:'7%'}}>
   <NewForm/>
</section>

{/* Mobile View  */}
<section className="mt-lg-4 d-block d-sm-block d-md-none d-lg-none">
    <div className=" pt-3 pb-5 px-2 mt-30" style={{backgroundColor:'rgba(242, 242, 242, 1)'}}>
        <div className="container">
         
                      <p className="fw-600 text-center fs-23 plus mb-0 pt-1" >What can we help you with?</p>
                      <p className="fw-500 text-center fs-17 plus pt-3" style={{lineHeight:'22px'}}>Get Custom Solutions, Recommendations, or, Estimates.
                      Confidentiality & Same Day Response Guaranteed!</p>
    <div className="pt-1">
       <Form/>
    </div>

    <div className="d-flex justify-content-center gap-2 mt-4 ">

<div className="d-flex  gap-2">
    <div>
          <img src= "https://d3vqz9qyn2r1xe.cloudfront.net/imges/green-icon-form.svg" alt="" className="pt-1" />
    </div>
    <div>
           <p className="fw-600 plus fs-17  pt-1">100% Confidential</p>
    </div>
</div>

<div className="d-flex  gap-2">
    <div>
          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/green-icon-form.svg" alt="" className="pt-1" />
    </div>
    <div>
           <p className="fw-600 plus fs-17  pt-1">We sign NDA</p>
    </div>
</div>

</div>

<div className="d-flex gap-4 mt-4">
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon1.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon2.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon3.svg" alt="" className="img-fluid pt-1" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon4.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon5.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon6.svg" alt="" className="img-fluid pt-2" />
            </div>
    </div>

      </div>
      </div>
</section>



        <section >
            <div className="mt-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                        <p className="fw-600 fs-20 plus pt-2 text-center hideinmo " style={{color:'rgba(0, 83, 154, 1)'}}>Want a Custom website that meets your needs? Hire skilled web developers in India today!</p>
                        <p className="fw-500 fs-17 plus  text-center hideindesktop" style={{color:'rgba(0, 83, 154, 1)'}}>Want a Custom website that meets your needs? Hire skilled web developers in India today!</p>

                       <p className="fw-800 fs-35 plus text-center font-25 " style={{color:'rgba(0, 0, 0, 1)'}}>Custom Services for Every Requirements</p>                     
                     </div>
              
                 </div>

            <div className="row justify-content-center  mt-lg-3 g-0 g-2 pt-10">
                <div className="col-lg-6 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon5.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Custom Web Applications Development</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-16 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our website development company specializes in prototyping and MVP creation for your custom web  application. Our primary focus is on designing user-friendly UI/UX that enhances engagement and  provides an enjoyable experience for users.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-6 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon6.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Progressive Web App Development</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-16 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our team of expert website developers brings extensive experience in creating innovative, progressive  web applications that are fast, reliable, and secure. We leverage the latest technologies to design and  develop cutting-edge web applications.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-6 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon7.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >E-Commerce Solutions</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-16 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our team specializes in creating eCommerce websites that are both cost-effective and reliable,  ensuring a seamless shopping experience for your customers. We focus on building secure, scalable,  and user-friendly online stores tailored to your business needs.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-6 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon8.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-20 plus font-15" >Full-Stack Development</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-16 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>DataMetricks is a leading web development company in India, offering custom web development  services using a wide range of front-end and back-end technologies. Our expertise spans MEAN,  MERN, PHP + Vue.js, Node.js, Angular, and more to deliver tailored solutions for your business.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>



              </div>
            </div>
        </section>

{/* 
        <section>
            <div className="container mt-5 hideinmo">
                <div className="row  justify-content-center">
                    <div className="col-lg-6">
                        <p className="plus fw-800 fs-32" style={{color:'rgba(0, 0, 0, 1)'}}>Why Should You Hire a Professional Web Development Company in India?</p>
                        <p className="plus fw-500 fs-16" style={{color:'rgba(0, 0, 0, 1)'}}>A professional web development company is dedicated to crafting, designing, and maintaining user friendly websites tailored to meet the needs of businesses and individuals. By partnering with a  reputable development team, you can significantly boost the quality, performance, and overall  effectiveness of your online presence, helping your business thrive and succeed.</p>
                        <p className="plus fw-500 fs-16" style={{color:'rgba(0, 0, 0, 1)'}}>Web development agencies offer a wide range of services, from website design and development to  ongoing maintenance and support. This means you can access comprehensive website solutions all in  one place.</p>
                        <p className="plus fw-500 fs-16" style={{color:'rgba(0, 0, 0, 1)'}}>A skilled website developer can build an efficient, user-friendly site for any type of business. Hiring  the right professional ensures that your website not only looks great but also performs optimally. Choosing the best web development company with expert developers guarantees faster delivery  without compromising on quality.</p>

                    </div>
                    <div className="col-lg-4 text-end">
                     
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-img3.svg" alt="" className="img-fluid" />
                   
                    </div>
                </div>
            </div>
        </section>

    


        <section>
            <div className="container mt-5 hideinmo">
                <div className="row  justify-content-center">
                    <div className="col-lg-7 ">
                        <p className="plus fw-800 fs-32" style={{color:'rgba(0, 0, 0, 1)'}}>Top Website Development Services in India with a Team of Skilled Developers</p>
                        <p className="fw-500 plus fs-16">Looking for a web development agency, software solutions, or an IT company with deep technical  expertise? DataMetricks is the ideal partner for your projects.</p>
                        <p className="fw-700 fs-17 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>60% More Cost-Effective and Twice as Fast</p>
                        <p className="plus fw-500 fs-16 pt-1" style={{color:'rgba(0, 0, 0, 1)'}}>DataMetricks offers services at 60% lower cost compared to other agencies, providing high-quality  results at affordable hourly rates without sacrificing performance.</p>
                 
                        <p className="fw-700 fs-17 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Unmatched Quality Assurance</p>
                        <p className="plus fw-500 fs-16 pt-1" style={{color:'rgba(0, 0, 0, 1)'}}>With our experienced web developers in India, you can trust us to deliver top-tier IT solutions,  ensuring excellence in every project we take on.</p>
                 
                        <p className="fw-700 fs-17 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>Harness the Power of Cutting-Edge Technologies</p>
                        <p className="plus fw-500 fs-16 pt-1" style={{color:'rgba(0, 0, 0, 1)'}}>Our team stays ahead of the curve, using the latest technologies to build websites that stand out and  perform exceptionally well.</p>
                 
                        <p className="fw-700 fs-17 plus mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>A Team You Can Rely On</p>
                        <p className="plus fw-500 fs-16 pt-1" style={{color:'rgba(0, 0, 0, 1)'}}>With over years of experience, our dedicated offshore web development team can work full-time,  part-time, or as per your specific project requirements—ensuring timely delivery and high-quality  results.</p>
                 
                    </div>
                    <div className="col-lg-5 text-end">
                     
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-img4.svg" alt="" className="img-fluid" />
                   
                    </div>
                </div>
            </div>
        </section> */}





{/* <section className="hideinmo">
            <div style={{backgroundColor:'rgba(208, 233, 255, 1)'}}>
                <div className="container pt-4 pb-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                       <p className="fw-800 fs-35 plus text-center" style={{color:'rgba(0, 0, 0, 1)'}}>Choose Us for 9 WE’s of Excellence</p>
                     
                     </div>
              
                 </div>

            <div className="row justify-content-center  mt-1 g-0 g-3">

                <div className="col-lg-4 col-12 col-md-3">
                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                         
                          <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>We Are Expert </p>
                           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our team consists of over 50 skilled website developers, experienced in creating custom, SEO friendly, and dynamic websites tailored to meet your specific needs.</p>
                    </div>
                </div>
                
                <div className="col-lg-4 col-12 col-md-3">
                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                           
                             <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>We Are Always Available</p>
                           
                           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We offer 24/7 availability via Teams, Meet, Skype, email, or Zoom to provide quick project updates or  assistance during emergencies.</p>
                    </div>
                </div>

                <div className="col-lg-4 col-12 col-md-3">
                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                          
                            <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>We Provide Unlimited Revisions</p>
                           
                           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We offer unlimited changes until you're fully satisfied with the final result. Our in-house designers are  dedicated to bringing your vision to life.</p>
                    </div>
                </div>
                 
              
                 </div>


            <div className="row justify-content-center pt-3 g-0 g-3">

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
         
          <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>We Communicate Well</p>
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our professional web developers ensure clear and effective communication throughout the project.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
           
             <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>We Are Affordable </p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We offer competitive rates without compromising on quality. With our low hourly rates, we guarantee  100% client satisfaction.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
          
            <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>5 Years of Collective Expertise</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our skilled Indian web developers bring over 5+ years of experience in delivering full-stack website  development services across platforms like PHP, WordPress, Laravel, and Web Design.</p>
    </div>
</div>
 

            </div>

            <div className="row justify-content-center pt-3 g-0 g-3">

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
         
          <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>We Deliver Fast</p>
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our team meets deadlines and ensures timely delivery of every project.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
           
             <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>We Offer Ongoing Support</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We provide comprehensive support and maintenance services post-launch, ensuring your website  remains live and fully functional at all times.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
          
            <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>Commitment to Integrity & Transparency</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We prioritize your data security, ensuring your sensitive information is protected. We promise never to  share your details with third parties without your consent.</p>
    </div>
</div>
 

            </div>

            <div className="row justify-content-center pt-3 g-0 g-3">

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
         
          <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>We Specialize in SEO</p>
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our expertise in SEO covers all aspects, from on-page optimization to backlink generation and off page SEO, ensuring your website ranks higher and attracts more visitors.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
           
             <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>We Create Responsive Websites</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our designers specialize in creating fully responsive websites optimized for all mobile devices,  offering a seamless experience across platforms.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
          
            <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>Free, No-Obligation Estimates</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>Simply share your project requirements with us, and we’ll provide a detailed quote within 24 hours,  free of charge—no hidden fees or obligations.</p>
    </div>
</div>
 

            </div>

            <div className="row justify-content-center pt-3 g-0 g-3">

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
         
          <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>Seamless Project Management</p>
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our web development agency uses top-tier project management tools like Trello, Zoho Projects,  Asana, and Kiss flow to name few, ensuring smooth and efficient project execution.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
           
             <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>NDA Protection Guaranteed</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We offer complete confidentiality through NDA agreements, ensuring that your information and  projects remain private and secure.</p>
    </div>
</div>

<div className="col-lg-4 col-12 col-md-3">
      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
          
            <p className="fw-700 fs-16 plus mb-0" style={{color:'rgba(27, 53, 103, 1)'}}>Flexible Engagement Options</p>
           
           <p className="fw-500 fs-14 plus pt-2" style={{color:'rgba(0, 0, 0, 0.7)'}}>We provide various engagement models, whether fixed cost, hourly, monthly, or dedicated team, to  match your project needs and budget.</p>
    </div>
</div>
 

            </div>

               

              


              </div>
            </div>
</section>

<section className="hideinmo">
    <div className="container mt-5">
        <div className="row justify-content-center  ">
        <div className="col-lg-5 ">
            <p className="fw-800 fs-35 plus">Why Hire Website Development Company in India?</p>
            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-img3.svg" alt=""  />
        </div>

        <div className="col-lg-6 ">
            <div className="py-3 px-4"  style={{boxShadow:'0px 0px 4px 0px rgba(0, 0, 0, 0.25)',backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'16px'}}>
            <div className="d-flex gap-lg-3 gap15 pt-2">
                    <div>
                         <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-Doneimg.svg" alt="done-icon"  />
                    </div>
                    <div>
                        <p className="plus fw-500 fs-16 mb-0 font-18" style={{color:'rgba(0, 0, 0, 1)'}}>Develop SEO-optimized and user-friendly websites tailored to business needs.</p>
                    </div>
                   
                </div>

                <div className="d-flex gap-lg-3 gap15 pt-4">
                    <div>
                         <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-Doneimg.svg" alt="done-icon"  />
                    </div>
                    <div>
                        <p className="plus fw-500 fs-16 mb-0 font-18" style={{color:'rgba(0, 0, 0, 1)'}}>Enhance business visibility, organic search rankings, and overall revenue.</p>
                    </div>
                   
                </div>

                <div className="d-flex gap-lg-3 gap15 pt-4">
                    <div>
                         <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-Doneimg.svg" alt="done-icon"  />
                    </div>
                    <div>
                        <p className="plus fw-500 fs-16 mb-0 font-18" style={{color:'rgba(0, 0, 0, 1)'}}>Deliver custom web solutions to help your brand stand out in the digital space.</p>
                    </div>
                   
                </div>
                <div className="d-flex gap-lg-3 gap15 pt-4">
                    <div>
                         <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-Doneimg.svg" alt="done-icon"  />
                    </div>
                    <div>
                        <p className="plus fw-500 fs-16 mb-0 font-18" style={{color:'rgba(0, 0, 0, 1)'}}>Design responsive websites that work seamlessly across all devices and screen sizes.
                        </p>
                    </div>
                   
                </div>

                <div className="d-flex gap-lg-3 gap15 pt-4">
                    <div>
                         <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-Doneimg.svg" alt="done-icon"  />
                    </div>
                    <div>
                        <p className="plus fw-500 fs-16 mb-0 font-18" style={{color:'rgba(0, 0, 0, 1)'}}>Provide bespoke software solutions to achieve specific business objectives.</p>
                    </div>
                   
                </div>

                <div className="d-flex gap-lg-3 gap15 pt-4">
                    <div>
                         <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-Doneimg.svg" alt="done-icon"  />
                    </div>
                    <div>
                        <p className="plus fw-500 fs-16 mb-0 font-18" style={{color:'rgba(0, 0, 0, 1)'}}>Focus on creating websites that leave a lasting impression and drive conversions.</p>
                    </div>
                   
                </div>

                <div className="d-flex gap-lg-3 gap15 pt-4">
                    <div>
                         <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-Doneimg.svg" alt="done-icon"  />
                    </div>
                    <div>
                        <p className="plus fw-500 fs-16 mb-0 font-18" style={{color:'rgba(0, 0, 0, 1)'}}>Utilize a skilled team of developers and designers to meet customer demands.</p>
                    </div>
                   
                </div>

                <div className="d-flex gap-lg-3 gap15 pt-4">
                    <div>
                         <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-Doneimg.svg" alt="done-icon"  />
                    </div>
                    <div>
                        <p className="plus fw-500 fs-16 mb-0 font-18" style={{color:'rgba(0, 0, 0, 1)'}}>Prioritize user-friendly UI/UX with intuitive designs and interfaces.</p>
                    </div>
                   
                </div>
                <p className="fw-700 fs-18 plus pt-4" style={{color:'rgba(0, 83, 154, 1)'}}>Get a free quote today and see how we can transform your online presence!</p>
            </div>
        </div>
        </div>
    </div>
</section> */}

<section className="mt-5">
    <GoogleReviewsWidget/>
</section>


        <section className="mt-30" style={{backgroundColor:'rgba(208, 233, 255, 1)'}}>
      <div className="mt-lg-5 pt-4 pb-4">
          <Faqaccordian faqAccordianData={faqDataPage}/>
          </div>
      </section>



        <section >
                <Footer/>
        </section>

    </div>
);
};
export default WebsitePage;