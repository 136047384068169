import React, { useState, useEffect } from 'react';
import Header from "../Components/Header";
import Footer from "../Components/footer";
import Chatbox from '../Components/Chatbox';
import Blog1 from '../Components/Blog1';
import SEOPage from '../Components/SEOPage';
import { Helmet } from "react-helmet";
import Video from '../Components/Video';
import Form from "../Components/Form";
import { useNavigate } from "react-router-dom";
import GoogleReviewsWidget from '../Components/GoogleReviewsWidget';
import Faqaccordian from '../Components/Faqaccordian';

const ratingstar = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/ratingstar.svg";


const NewHomePage = () => {



    // ================ Mailer code start from here =====================
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        phone: "",
      });
      const [responseMessage, setResponseMessage] = useState(""); 
      const [loading, setLoading] = useState(false); // State for loader
      const navigate = useNavigate(); // Initialize useNavigate
      const [errors, setErrors] = useState({}); 
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
     
      const validateForm = () => {
        let formErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10}$/; // Assumes phone number should be 10 digits
    
        if (!formData.email || !emailRegex.test(formData.email)) {
          formErrors.email = "Please enter a valid email address.";
        }
    
        if (!formData.phone || !phoneRegex.test(formData.phone)) {
          formErrors.phone = "Please enter a valid 10-digit phone number.";
        }
    
        if (!formData.message || formData.message.trim().length < 10) {
            formErrors.message = "Please enter a message with at least 10 characters.";
          }
    
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
          return;
        }
        setLoading(true); // Show loader
    
        try {
          const response = await fetch(
            "https://mailer.datametricks.com/send-email.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            }
          );
    
          const result = await response.json(); // Parse JSON from the response
    
          setResponseMessage(result.message);
    
          if (result.status === "success") {
            setTimeout(() => {
              setLoading(false); // Hide loader
              navigate("/Successemailpage"); // Redirect to Thank You page
            }, 1000); // Delay redirect by 2 seconds
          } else {
            setLoading(false); // Hide loader if not successful
          }
        } catch (error) {
          setResponseMessage("Error submitting form.");
          setLoading(false); // Hide loader on error
        }
      };
    
      // ================ Mailer code end here =====================

  const schemaData1 = 
  [
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "DataMetricks Consulting Pvt Ltd",
      "url": "https://www.datametricks.com/",
      "logo": "https://www.datametricks.com/logo.png",
      "contactPoint": [
        {
          "@type": "ContactPoint",
          "telephone": "+91 7303946657",
          "contactType": "Customer Service",
          "email": "contact@datametricks.com",
          "areaServed": "IN",
          "availableLanguage": "English"
        }
      ],
      "address": [
        {
          "@type": "PostalAddress",
          "streetAddress": "A40, ITHUM TOWER, Industrial Area, Sector 62",
          "addressLocality": "Noida",
          "addressRegion": "Uttar Pradesh",
          "postalCode": "201309",
          "addressCountry": "IN"
        }
      ],
      "sameAs": [
        "https://www.facebook.com/people/DataMetricks-Consulting-PvtLtd/61554350043470/",
        "https://www.instagram.com/datametricks_pvt_ltd/",
        "https://x.com/datametricks",
        "https://www.linkedin.com/company/datametricks-consulting-pvt-ltd/"
      ]
    }
  ];
  

  const faqSchemaData1 = 
  [
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What services does DataMetricks offer?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "DataMetricks offers a range of services including website design and development, mobile app development, SEO, social media marketing, performance marketing, and CMS development."
        }
      },
      {
        "@type": "Question",
        "name": "How can I get a quote for a website development project?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can easily request a quote by visiting our 'Contact Us' page or reaching out to us via email at contact@datametricks.com or phone at +91 7303946657."
        }
      },
      {
        "@type": "Question",
        "name": "Does DataMetricks provide mobile app development services?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, DataMetricks offers mobile app development services for both Android and iOS platforms, ensuring high-quality apps tailored to your business needs."
        }
      },
      {
        "@type": "Question",
        "name": "Can DataMetricks help with SEO for my website?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, we provide comprehensive SEO services to help improve your website's ranking on search engines and drive organic traffic."
        }
      },
      {
        "@type": "Question",
        "name": "How can DataMetricks help with digital marketing?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "DataMetricks offers a full suite of digital marketing services, including social media marketing, performance marketing, SEO, and content marketing, designed to boost your brand's online presence and engagement."
        }
      }
    ]
  }
];


  var faqData=[

    {que1:"1. How can DataMetricks help my business grow?",ans1:"We help businesses grow by enhancing their online presence through effective digital marketing strategies and high-quality website development. Our services are tailored to meet your specific business needs, driving more traffic, engagement, and conversions." },
    {que1:"2. What industries do you specialize in? ", ans1:"We work with clients from various industries, including information technology, healthcare, e-commerce, real estate, education, and professional services. Our diverse experience allows us to create customized solutions for each industry."},
    {que1:"3. How does your SEO service improve my website’s ranking? ",ans1:"Our SEO service includes comprehensive strategies such as keyword research, on-page optimization, technical SEO, and off-page SEO. By implementing these techniques, we improve your website's visibility and ranking on search engines, driving more organic traffic."},
    {que1:"4. What is the process for getting started with DataMetricks? ",ans1:"Getting started with DataMetricks is easy. Simply contact us through our website or fill out the form on our home page. We’ll schedule a consultation to discuss your needs and develop a customized plan for your business. "},
    {que1:"5. Can you help with social media marketing? ",ans1:"Yes, we offer comprehensive social media marketing services that include content creation, campaign management, and performance analysis to help you engage with your audience and grow your brand on social media platforms. "},
    {que1:"6. Do you offer support and maintenance for websites? ",ans1:"Absolutely. We provide ongoing support and maintenance services to ensure your website remains up-to-date, secure, and performs optimally. This includes regular updates, security checks, and performance enhancements. "},
    {que1:"7. What makes DataMetricks different from other digital marketing agencies? ",ans1:"DataMetricks stands out due to our personalized approach, industry expertise, and commitment to delivering measurable results. We tailor our strategies to meet your specific goals and use the latest technologies to ensure your success. "},
    {que1:"8. How do you ensure the security of my website and data? ",ans1:"We prioritize security by implementing best practices in website development and data protection. Our measures include secure coding practices, regular security audits, data encryption, and compliance with relevant regulations. "},
    {que1:"9. Can you provide examples of your previous work? ",ans1:"Yes, we have a portfolio of successful projects across various industries. Visit our portfolio section on the website to see case studies and examples of how we’ve helped other businesses achieve their goals. "},

  ];
  

  


  const [categories, setCategories] = useState([]);
  useEffect(() => {
    fetch('https://blog.datametricks.com/wp-json/wp/v2/categories')
      .then((response) => response.json())
      .then((data) => setCategories(data.slice(0, 3)))
      .catch((error) => console.error('Error fetching categories:', error));
  }, []);


    return (
        
      <div>
        <SEOPage 
                title="Website Design & Development Agency in Noida | Mobile App Development, SEO, DigitalMarketing"
                description="DataMetricks is a leading website design and development company in Noida, offering
                              comprehensive digital solutions including mobile app development, SEO services, social media
                              marketing, performance marketing, and CMS development. Grow your business with our expertdriven web and digital marketing services."
                canonicalUrl="https://www.datametricks.com/"
            />
  
        <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaData1)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(faqSchemaData1)}
        </script>
        
      </Helmet>
     
   
          <section>
               <div className="">
                      <Header />
               </div>
            
           </section>
      
           <section className='newhome-firstcon' style={{marginTop:'5%'}}>
            <Video />
           </section>


<section style={{backgroundColor:'#F6FBFF'}}>
    <div className="container  py-lg-5 pt-20">
        <div className="row align-items-center justify-content-center ">
            <div className="col-lg-6 col-12 ">
                <p className="fw-800 fs-38 plus  font-23  " style={{color:'#000000'}}>Working Together towards Digital Excellence</p>
                <p className="fs-17 plus fw-400 font-18  " style={{color:'#000000',lineHeight:'28px'}}>We believe in the power of collaboration to unlock the full potential of your  business. Our comprehensive IT solutions, from bespoke web development to cutting-edge digital  marketing, are crafted to elevate your brand's online presence. With a dedicated team, we ensure  every strategy is optimized for maximum impact and tailored to your unique needs.</p>
            </div>
            <div className="col-lg-6 col-12">
                <div className="row ">
                    <div className="col-md-4 col-6 text-center">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon1.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>Dedicated Resource Allocation</p>
                    </div>

                    <div className="col-md-4 col-6 text-center">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon2.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>Different Business Models</p>
                    </div>

                    <div className="col-md-4 col-6 text-center">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon3.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>Technology Experts on Board</p>
                    </div>

                    <div className="col-md-4 col-6 text-center d-sm-block d-block d-lg-none d-md-none">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon5.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>Value of your Investment</p>
                    </div>
                  
                </div>

                <div className="row mt-3">
                    <div className="col-md-4 col-6 text-center d-sm-none d-none d-lg-block d-md-block">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon5.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>Value of your Investment</p>
                    </div>

                    <div className="col-md-4 col-6 text-center">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon6.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>Competitive Rates for Excellent Services</p>
                    </div>

                    <div className="col-md-4 col-6 text-center">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon7.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>NDA Ownership Transfer</p>
                    </div>

                 
                </div>

            </div>
        </div>
    </div>
</section>


<section className="" style={{backgroundColor:'#E8F4FF'}}>
    <div className="container pt-4 pb-5 ">
        <p className="fw-800 fs-33 plus text-center mb-0 font-23" style={{color:'#1B3567'}}>India's Highest Rated Web Development Company</p>
        <p className="fw-500 fs-20 plus text-center pt-2 font-18" style={{color:'#1B3567'}}> 100+ Glowing 5-Star Reviews Across Top B2B Review Platforms</p>
        <div className="row justify-content-center mt-4 g-2 p-10" >

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img1.svg" alt="box-img" style={{height:'40px',width:'181px',marginTop:'25px'}} className="img-fluid heightwidth"  />
                    <img src={ratingstar} alt="" className="mt-lg-5 mt-10 " />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                                  

                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img2.svg" alt="box-img" style={{height:'113px',width:'90px'}} className="img-fluid heightwidth1" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.9</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img3.svg" alt="box-img" style={{height:'113px',width:'120px'}} className="img-fluid heightwidth2" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img4.svg" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid heightwidth2" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    {/* <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img5.svg" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid" /> */}
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/googleicon.png" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid heightwidth3" />

                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

          
        </div>
    </div>

</section>

<section>
    <div className='container mt-4'>
        <div className='row align-items-center'>
        <div className='col-lg-7 col-12'>
            <p className='fw-800 fs-70 mb-0 plus font-25' style={{color:'#00539A'}}>Web Development</p>
            <p className='fw-500 fs-30 plus mb-0 font-20' style={{color:'#000000'}}>Let's Build Something Amazing Together</p>
            <p className='fw-400 plus fs-17 pt-2 ' style={{color:'#000000'}}>We specialize in creating dynamic, responsive, and user-friendly websites that bring your vision to life. Our expert team of developers works tirelessly to ensure that your site not only looks stunning but also functions seamlessly across all devices. Whether you need a simple informational site or a complex e-commerce platform, we have the skills and experience to deliver top-notch solutions tailored to your specific needs. We prioritize performance and security, ensuring fast load times and robust protection against online threats. We’re committed to providing ongoing support to keep your website running smoothly.</p>

        </div>
        <div className='col-lg-5 col-12 text-center'>
            <img src='https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-img5.svg' alt='' className='img-fluid' />
        </div>
    </div>
    </div>
</section>

<section >
            <div className="" >
                <div className="container pt-4 pb-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                       <p className="fw-800 fs-30 plus text-center" style={{color:'rgba(0, 0, 0, 1)'}}>All Inclusive Web Development Services</p>
                     
                     </div>
              
                 </div>

            <div className="row justify-content-center  mt-3 g-0 g-2">
                <div className="col-lg-5 col-12 col-md-3">

                  <a href='/service/custom-website-design-development-solutions' style={{textDecoration:'none'}}><div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon26.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus" style={{color:'rgba(0, 83, 154, 1)'}}>Custom Website Design </p>
                                </div>
                           </div>
                           <p className="fw-500 fs-17 plus" style={{color:'rgba(0, 0, 0, 0.7)'}}>Create a unique, professional, and visually appealing website tailored to your brand. Our custom designs ensure your website stands out, drives engagement, and effectively captures your audience's attention.</p>
                       
                    </div></a>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                   <a href='/service/ecommerce-website-design-development-solutions' style={{textDecoration:'none'}}><div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon7.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus" style={{color:'rgba(0, 83, 154, 1)'}}>E-Commerce Solutions</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-17 plus" style={{color:'rgba(0, 0, 0, 0.7)'}}>Boost your online sales with our robust e-commerce solutions. We develop secure, scalable, and user-friendly online stores that enhance the shopping experience for your customers.</p>
                       
                    </div></a>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center  pt-2 g-0 g-2">
                <div className="col-lg-5 col-12 col-md-3">

                 <a href='/service/cms-website-design-development-solutions' style={{textDecoration:'none'}}><div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon27.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus" style={{color:'rgba(0, 83, 154, 1)'}}>Content Management Systems (CMS)</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-17 plus" style={{color:'rgba(0, 0, 0, 0.7)'}}>Manage your website content effortlessly with our CMS solutions. We provide easy-to-use platforms that allow you to update and maintain your site with minimal technical knowledge.</p>
                       
                    </div></a>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <a href='/service/mobile-app-design-development-solutions' style={{textDecoration:'none'}}><div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon28.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus" style={{color:'rgba(0, 83, 154, 1)'}}>Mobile Responsive Design </p>
                                </div>
                           </div>
                           <p className="fw-500 fs-17 plus" style={{color:'rgba(0, 0, 0, 0.7)'}}>Ensure your website looks great on all devices with our mobile-responsive design services. We create sites that provide a seamless user experience across smartphones, tablets, and desktops.</p>
                       
                    </div></a>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center pt-2 g-0 g-2 ">
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon4.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus" style={{color:'rgba(0, 83, 154, 1)'}}>SEO Optimization</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-17 plus" style={{color:'rgba(0, 0, 0, 0.7)'}}>Increase your website's visibility on search engines with our SEO optimization services. We implement strategies that drive organic traffic and improve your search engine optimization rankings.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <a href='/service/website-design-development-solutions' style={{textDecoration:'none'}}><div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon29.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus" style={{color:'rgba(0, 83, 154, 1)'}}>Website Maintenance and Support</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-17 plus" style={{color:'rgba(0, 0, 0, 0.7)'}}>Keep your website running smoothly with our maintenance and support services. We offer regular updates, security checks, and technical assistance to ensure optimal performance.</p>
                       
                    </div></a>
                       
                        </div>
              
                 </div>

              </div>
            </div>
</section>

<section>
    <div className='container'>
        <div className='row '>
        <div className='col-lg-5 col-12 text-center'>
            <img src='https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-img6.svg' alt='' className='img-fluid' />
        </div>
        <div className='col-lg-7 col-12 '>
            <p className='fw-800 fs-70 mb-0 plus font-25 pt-20' style={{color:'#00539A'}}>Digital Marketing </p>
            <p className='fw-500 fs-30 plus mb-0 font-20 pt-10' style={{color:'#000000'}}>Get Found, Generate Leads, Achieve Results</p>
            <p className='fw-400 plus fs-17 pt-2 ' style={{color:'#000000'}}>We offer tailored strategies encompassing SEO, social media marketing,  content marketing, and PPC campaigns designed to boost your online visibility, attract  qualified leads, and deliver measurable results. Our expert team is dedicated to helping  your brand stand out in a crowded digital landscape, ensuring sustained growth and  success. Partner with us to elevate your digital presence and achieve your business goals.</p>

        </div>
      
    </div>
    </div>
</section>
  
<section >
            <div className="mt-lg-4" >
                <div className="container pt-4 pb-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                       <p className="fw-800 fs-30 plus text-center" style={{color:'rgba(0, 0, 0, 1)'}}> Complete Digital Marketing Solutions</p>
                     
                     </div>
              
                 </div>

            <div className="row justify-content-center  mt-3 g-0 g-2">
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon30.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus" style={{color:'rgba(0, 83, 154, 1)'}}>SEO (Search Engine Optimization)</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-17 plus" style={{color:'rgba(0, 0, 0, 0.7)'}}>Boost your website's visibility and organic traffic with our expert SEO services. We optimize your site to rank higher on search engines, driving more potential customers to your business. Our tailored strategies ensure long-term results.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon31.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus" style={{color:'rgba(0, 83, 154, 1)'}}>Performance Marketing</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-17 plus" style={{color:'rgba(0, 0, 0, 0.7)'}}>Maximize your ROI with our data-driven performance marketing solutions. We focus on measurable outcomes, using analytics to refine campaigns and deliver the best possible results for your business.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center  pt-2 g-0 g-2">
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon32.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus" style={{color:'rgba(0, 83, 154, 1)'}}>Social Media Marketing</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-17 plus" style={{color:'rgba(0, 0, 0, 0.7)'}}>Engage with your audience and grow your brand with our dynamic social media marketing strategies. We create compelling content and targeted campaigns to increase your reach and drive conversions.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-icon33.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus" style={{color:'rgba(0, 83, 154, 1)'}}>Email Marketing</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-17 plus" style={{color:'rgba(0, 0, 0, 0.7)'}}>Reach your customers directly with our targeted email marketing campaigns. We design and execute personalized email strategies that enhance engagement and drive sales for your business.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

              

              

              </div>
            </div>
</section>

<div id="formsection">

</div>

<section  className=" d-none d-sm-none d-md-block d-lg-block" style={{marginTop:'6%'}}>
    <div className=" py-4" style={{backgroundColor:'rgba(242, 242, 242, 1)',position:'relative'}}>
        <div style={{position:'absolute', right:'7%',bottom:'87%'}}>
            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/response-img.svg" alt="" className="img-fluid" />
        </div>
        <div className="container">
            <form onSubmit={handleSubmit}>
           

            <div className="row justify-content-center align-items-center">
                <div className="col-lg-5 ">
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/men-formimg.svg" alt="" className="img-fluid "  />
                </div>
                <div className="col-lg-7 ">
                     <p className="fw-800  fs-30 plus " >What can we help you with?</p>

                    <div className="row  pt-1">
                        <div className="col-md-6 ">
                              <input type="text"  id="fname"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                               placeholder="Full Name *" required className="fs-13 plus px-3 w-100" style={{color:'#000000',height:'51px',borderRadius:'7px',border:'none',outline:'none'}} />

                        </div>
                        <div className="col-md-6">
                              <input type="email"
                                id="email" name="email"  value={formData.email}
                                onChange={handleChange}
                              placeholder="Email *" required className="fs-13 plus px-3 w-100" style={{color:'#000000',height:'51px',borderRadius:'7px',border:'none',outline:'none'}} />
                {errors.email && <p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "5px" }}>{errors.email}</p>}

                        </div>
                    </div>
                    <div className="row  mt-4 ">
                        <div className="col-md-6 ">
                              <input type="tel" 
                               id="phone" 
                               name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                               placeholder="Phone Number" required className="fs-13 plus px-3 w-100" style={{color:'#000000',height:'51px',borderRadius:'7px',border:'none',outline:'none'}} />
                    {errors.phone && <p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "10px" }}>{errors.phone}</p>}


                        </div>
                        <div className="col-md-6">
                              <input type="text" 
                                name="message"  value={formData.message}
                                onChange={handleChange}
                                form="usrform"
                              placeholder="Company" required className="fs-13 plus px-3 w-100" style={{color:'#000000',height:'51px',borderRadius:'7px',border:'none',outline:'none'}} />
                    {errors.message && (<p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "5px" }} > {errors.message} </p>)}
                        </div>
                    </div>

                    <div className="row justify-content-center mt-4">
                        <div className="col-lg-12 ">
                              <textarea
                             
                              placeholder="Your Message / Requirements "  className="fs-13 plus px-3 py-3 w-100" style={{color:'#000000',height:'115px',borderRadius:'7px',border:'none',outline:'none', maxHeight: '115px',}}></textarea>
                              {/* <p className=" text-white pt-3 fw-700 fs-13 plus">
                                 <span type="file" className=" text-white fw-700 fs-13 plus">📎 Select files from your </span>
                                  <span className="  fw-700 fs-13 plus" style={{color:'#ABD2FF'}}>Computer</span>, <span className=" fw-700 fs-13 plus" style={{color:'#ABD2FF'}}>Google Docs</span> or <span className=" fw-700 fs-13 plus" style={{color:'#ABD2FF'}}>Dropbox URLs</span>
                            </p> */}
                        </div>
              
                    </div>
                  

                  <div className="d-flex gap-4 mt-4">

                
                    <div >

                        <button type="submit" className="btn btn-sm  mont" disabled={loading} style={{fontSize:'16px',fontWeight:'700',backgroundColor:'rgba(0, 83, 154, 1)',color:'white',borderRadius:'5px',border:'none',outline:'none',padding:'12px 60px 12px 60px'}}>
                        {loading ? (
                                    <>
            
                         <div className="spinner"></div>
                
                              </>
                          ) : (
                                "Send Your Query"
                              )}

                         </button>
                    </div>


            <div className="d-flex justify-content-center gap-4 ">

<div className="d-flex  gap-2">
    <div>
          <img src= "https://d3vqz9qyn2r1xe.cloudfront.net/imges/green-icon-form.svg" alt="" className="pt-1" />
    </div>
    <div>
           <p className="fw-600 plus fs-19  pt-1">100% Confidential</p>
    </div>
</div>

<div className="d-flex  gap-2">
    <div>
          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/green-icon-form.svg" alt="" className="pt-1" />
    </div>
    <div>
           <p className="fw-600 plus fs-19  pt-1">We sign NDA</p>
    </div>
</div>

</div>
                    </div>


    <div className="d-flex gap-4 mt-4">
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon1.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon2.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon3.svg" alt="" className="img-fluid pt-3" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon4.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon5.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon6.svg" alt="" className="img-fluid pt-4" />
            </div>
    </div>

                </div>
            </div>

         

        
    </form>
        </div>

    </div>

</section>

{/* Mobile View  */}
<section className="mt-lg-4 d-block d-sm-block d-md-none d-lg-none">
    <div className=" pt-3 pb-5 px-2 mt-30" style={{backgroundColor:'rgba(242, 242, 242, 1)'}}>
        <div className="container">
         
                      <p className="fw-600 text-center fs-23 plus mb-0 pt-1" >What can we help you with?</p>
                      <p className="fw-500 text-center fs-17 plus pt-3" style={{lineHeight:'22px'}}>Get Custom Solutions, Recommendations, or, Estimates.
                      Confidentiality & Same Day Response Guaranteed!</p>
    <div className="pt-1">
       <Form/>
    </div>

    <div className="d-flex justify-content-center gap-2 mt-4 ">

<div className="d-flex  gap-2">
    <div>
          <img src= "https://d3vqz9qyn2r1xe.cloudfront.net/imges/green-icon-form.svg" alt="" className="pt-1" />
    </div>
    <div>
           <p className="fw-600 plus fs-17  pt-1">100% Confidential</p>
    </div>
</div>

<div className="d-flex  gap-2">
    <div>
          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/green-icon-form.svg" alt="" className="pt-1" />
    </div>
    <div>
           <p className="fw-600 plus fs-17  pt-1">We sign NDA</p>
    </div>
</div>

</div>

<div className="d-flex gap-4 mt-4">
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon1.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon2.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon3.svg" alt="" className="img-fluid pt-1" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon4.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon5.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon6.svg" alt="" className="img-fluid pt-2" />
            </div>
    </div>

      </div>
      </div>
</section>


 

<section className="mt-lg-5 mt-30">
<div>
            {/* <Testimonial1/> */}
            <GoogleReviewsWidget/>
          </div>
</section>


<section className='mt-lg-5 mt-30'>
  <div>
      <p className='text-center fw-600 fs-35 plus font-23'>Latest From The <span style={{ color: 'rgba(0, 83, 154, 1)' }}>Blog</span></p>
  </div>

      {categories.map((category) => (
        <div key={category.id}>
              <Blog1 categoryId={category.id} />
          
        </div>  
      ))} 
 
</section>




<section className="mt-30" style={{backgroundColor:'rgba(208, 233, 255, 1)'}}>
      <div className="mt-lg-5 pt-4 pb-4">
          <Faqaccordian faqAccordianData={faqData}/>
          </div>
      </section>

<section>
  <div>
    <Chatbox/>
  </div>
</section>

             <section >
              <div  >
                     <Footer />
                     </div>
           </section>

     </div>
  );
};
export default NewHomePage;