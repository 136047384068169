// import { useEffect } from 'react';
 
// const GoogleReviewsWidget = () => {
//     useEffect(() => {
//         // Add Elfsight script dynamically
//         const script = document.createElement('script');
//         script.src = 'https://apps.elfsight.com/p/platform.js';
//         script.defer = true;
//         document.body.appendChild(script);
 
//         return () => {
//             // Clean up the script if component unmounts
//             document.body.removeChild(script);
 
 
//         };
//     }, []);
 
//     useEffect(() => {
//         if (typeof window !== "undefined") {
//             const observer = new MutationObserver(() => {
//                 const links = document.querySelectorAll("a");
//                 links.forEach((link) => {
//                     if (link.href.includes("&utm_campaign=free-widget")) {
//                         link.style.setProperty("display", "none", "important");
//                     }
//                 });
//             });
 
//             observer.observe(document.body, { childList: true, subtree: true });
 
//             return () => observer.disconnect(); // Clean up observer on unmount
//         }
//     }, []);
 
//     return (
//         <div>
//             {/* Elfsight Widget container */}
//             <div className="elfsight-app-3bd78af3-e8e8-46c1-9bf6-17a2c9b73e4e" data-elfsight-app-lazy></div>
//         </div>
//     );
// };
 
// export default GoogleReviewsWidget;



// ========================================================================================================================================

import { useEffect } from "react";

const GoogleReviewsWidget = () => {
    useEffect(() => {
        // Add Elfsight script dynamically
        const script = document.createElement("script");
        script.src = "https://apps.elfsight.com/p/platform.js";
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            // Clean up the script if the component unmounts
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (typeof window !== "undefined") {
            // Utility function to debounce DOM updates
            const debounce = (callback, delay) => {
                let timer;
                return () => {
                    clearTimeout(timer);
                    timer = setTimeout(callback, delay);
                };
            };

            // MutationObserver to monitor DOM changes
            const observer = new MutationObserver(
                debounce(() => {
                    const links = document.querySelectorAll("a");
                    links.forEach((link) => {
                        if (link.href.includes("&utm_campaign=free-widget")) {
                            link.style.setProperty("display", "none", "important");
                        }
                    });
                }, 100) // Debounce delay of 100ms
            );

            // Observe changes in the Elfsight widget container only
            const widgetContainer = document.querySelector(".elfsight-app-container");
            if (widgetContainer) {
                observer.observe(widgetContainer, { childList: true, subtree: true });
            }

            return () => observer.disconnect(); // Clean up observer on unmount
        }
    }, []);

    return (
        <div>
            {/* Elfsight Widget container */}
            <div className="elfsight-app-container p-2" style={{zIndex:'-999',position:'relative'}}>
                <div
                    className="elfsight-app-3bd78af3-e8e8-46c1-9bf6-17a2c9b73e4e"
                    data-elfsight-app-lazy
                ></div>
            </div>
        </div>
    );
};

export default GoogleReviewsWidget;
