import Footer from "../Components/footer";
import Header from "../Components/Header";
import Form from "../Components/Form";
import React, { useState} from 'react';
import { useNavigate } from "react-router-dom";
import Faqaccordian from '../Components/Faqaccordian';
import CompanyLogoSlider from "../Components/CompanyLogoSlider";
import CustomSlider from "../Components/CustomSlider";
import NewForm from "../Components/NewForm";

const SoftwareDevPage = () =>  {

const sec5dot1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot1.png";
const sec5dot2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot2.png";
const sec5dot3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot3.png";
const sec5dot4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot4.png";
const stagesbg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/stagesbg.png";



const projectimg1 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img1.svg';
const projectimg3 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img3.svg';
const projectimg5 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img5.svg';
const projectimg7 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img7.svg';
const projectimg8 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img8.svg';
const projectimg9 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img9.svg';
const projectimg2 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img2.svg';

const slides = [

{
  id: 1,
  title: "HealthOMax Web",
  description:
        "An extremely integrated platform of medical professionals and patients. Our website provides access to the same power as of our mobile application available anywhere, anytime, on any device.",                
  image: projectimg9,
  url1:"/our-completed-projects/healthomax-website"

},

{
  id: 2,
  title: "Lookplex",
  description:
    "Salon at Home Services Website For a salon at home services provider, we designed a sleek and modern website with an easy booking feature. The site allows customers to book appointments effortlessly and explore various beauty services offered.",
  image: projectimg1,
  url1:"/our-completed-projects/lookplex"

},
{
    id: 3,
    title: "Monovogue",
    description:
      "We developed a dynamic and visually appealing e-commerce platform that enhances the shopping experience. The site includes secure payment gateways, intuitive navigation, and a responsive design for seamless browsing on all devices.",
    image: projectimg8,
    url1:"/our-completed-projects/monovouge"

  },

  {
    id: 4,
    title: "3D Gardening",
    description:
      "We designed a vibrant and user-friendly website for a client providing gardening services. The site show cases their expertise, offers detailed service descriptions, and includes an easy-to-use contact form for customer inquiries.",
    image: projectimg3,
    url1:"/our-completed-projects/gardening"

  },

    {
      id: 5,
      title: "Narayana Home Services",
      description:
        "We developed a comprehensive website for Narayana Home, a home worker providers service company. The site allows users to easily hire cooks, maids, chefs, and watchmen, ensuring a seamless hiring process.",
      image: projectimg5,
      url1:"/our-completed-projects/narayana-homes"

    },
 
      {
          id: 6,
          title: "SmartCare",
          description:
            "We created a healthcare platform, SmartCare, that enables patients to easily book appointments, find doctors, store medical records, and save vital health information, streamlining their healthcare experience.",
          image: projectimg7,
          url1:"/our-completed-projects/smartcare"

        },
        {
            id: 7,
            title: "Cuddly koala",
            description:
              "We created an informative and professional website for a doctor and lactation consultant. The site features comprehensive service information, patient resources, and an intuitive appointment booking system.",
            image: projectimg2,
            url1:"/our-completed-projects/cuddly-cola"

          },
       
      
];

    // ================ Mailer code start from here =====================
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        phone: "",
      });
      const [responseMessage, setResponseMessage] = useState(""); 
      const [loading, setLoading] = useState(false); // State for loader
      const navigate = useNavigate(); // Initialize useNavigate
      const [errors, setErrors] = useState({}); 
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
     
      const validateForm = () => {
        let formErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10}$/; // Assumes phone number should be 10 digits
    
        if (!formData.email || !emailRegex.test(formData.email)) {
          formErrors.email = "Please enter a valid email address.";
        }
    
        if (!formData.phone || !phoneRegex.test(formData.phone)) {
          formErrors.phone = "Please enter a valid 10-digit phone number.";
        }
    
        if (!formData.message || formData.message.trim().length < 10) {
            formErrors.message = "Please enter a message with at least 10 characters.";
          }
    
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
          return;
        }
        setLoading(true); // Show loader
    
        try {
          const response = await fetch(
            "https://mailer.datametricks.com/send-email.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            }
          );
    
          const result = await response.json(); // Parse JSON from the response
    
          setResponseMessage(result.message);
    
          if (result.status === "success") {
            setTimeout(() => {
              setLoading(false); // Hide loader
              navigate("/Successemailpage"); // Redirect to Thank You page
            }, 1000); // Delay redirect by 2 seconds
          } else {
            setLoading(false); // Hide loader if not successful
          }
        } catch (error) {
          setResponseMessage("Error submitting form.");
          setLoading(false); // Hide loader on error
        }
      };
    
      // ================ Mailer code end here =====================


 const ratingstar = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/ratingstar.svg";


 
var faqDatamobile=[
    {que1:"1. What is mobile app development and why is it important?",ans1:"Mobile app development involves creating software applications that run on mobile devices. It's important because mobile apps can enhance customer engagement, provide better user experiences, and drive business growth by making your services accessible anytime, anywhere." },
    {que1:"2. How long does it take to develop a mobile app? ", ans1:"The timeline for developing a mobile app varies depending on the complexity and features of the app. A basic app might take a few months, while a more complex app with advanced features could take six months or longer."},
    {que1:"3. How much does it cost to develop a mobile app? ",ans1:"The cost of developing a mobile app depends on various factors, including the app's complexity, features, design, and the development team's rates. It's best to discuss your specific requirements with a development team to get an accurate estimate."},
    {que1:"4. What is the difference between native and cross-platform app development? ",ans1:"Native app development involves creating apps specifically for one platform (iOS or Android) using platform-specific languages and tools. Cross-platform app development allows you to create apps that run on multiple platforms (iOS and Android) using a single codebase, saving time and resources. "},
    {que1:"5. How do you ensure the security of my mobile app? ",ans1:"We prioritize app security by implementing best practices such as data encryption, secure APIs, regular security testing, and compliance with industry standards. Our team stays updated on the latest security trends to protect your app from vulnerabilities. "},
    {que1:"6. Can you integrate my mobile app with existing systems? ",ans1:"Yes, we offer app integration services that connect your mobile app with existing systems, databases, and third-party services to ensure seamless functionality and enhanced capabilities. "},
    {que1:"7. What kind of post-launch support do you provide?",ans1:"We provide comprehensive post-launch support, including regular updates, bug fixes, performance optimization, and ongoing maintenance to ensure your app remains functional and up to date. " },
    {que1:"8. How do you handle app updates and maintenance? ",ans1:"We offer ongoing maintenance services to keep your app updated with the latest features and security patches. Our team monitors the app's performance and user feedback to make necessary improvements and ensure optimal functionality. " },
    {que1:"9. Can you help with app store submissions? ",ans1:"Yes, we assist with the entire app store submission process, including preparing necessary documentation, ensuring compliance with app store guidelines, and managing the submission to both Apple App Store and Google Play Store. "},
    {que1:"10. Why should I choose DataMetricks for my mobile app development? ",ans1:"DataMetricks Consulting offers expert mobile app development services tailored to your business needs. Our team has extensive experience, a proven track record of successful projects, and a commitment to delivering high-quality, user-centric apps. We provide end-to-end services, from initial strategy and design to development, testing, and ongoing support. "},    

];

return(

    <div>
        <section>
                <Header/>
        </section>

        <section style={{marginTop:'50px'}}>
            <div  style={{backgroundColor:'rgba(0, 83, 154, 1)'}}>
                <div className="container  pt0  pb-40" style={{paddingTop:'80px',paddingBottom:'80px'}}>
                    <p className="fw-800 fs-60 plus  hideinmo" style={{color:'rgba(255, 255, 255, 1)'}}>Software Development Company in India</p>

                    <div className="row justify-content-center mt-5">

                        <div className="col-lg-6 col-12">
                        <p className="fw-800 fs-30 plus pt30 mb-0 font-25" style={{color:'rgba(255, 255, 255, 1)'}}>Building Innovative Software One Solution at a Time</p>

                        <p className="fw-500 fs-19 plus  pt-lg-3 mt-10 " style={{color:'rgba(255, 255, 255, 1)'}}>Software development solutions that transform your ideas into powerful digital experiences</p>

           <div className="hideinmo">
            <div className="d-flex gap-3 pt-20" style={{paddingTop:'30px'}}>
                     <div >
                <a href="https://outlook.office365.com/owa/calendar/MihirProjectLeadatDataMetricks@datametricks.com/bookings/">
                    <button type="button" className="btn btn-sm font-14  pt-2  px-4" style={{fontSize:'20px',fontWeight:'600',backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'45px',border:'none',outline:'none',paddingBottom:'10px'}}>
                    Book a Free Consultation
                    </button>
                </a>
            </div>

            <div >
                <a href="#formsection">
                    <button type="button" className="btn btn-sm font-14  pt-2 text-white px-4" style={{fontSize:'20px',fontWeight:'600',borderRadius:'45px',border:'1px solid rgba(255, 255, 255, 1)',outline:'none',paddingBottom:'10px'}}>
                  Send Us Your Requirement
                    </button>
                </a>
            </div>
            </div></div>



                        </div>

                        <div className="col-lg-6  col-12 col-sm-6 mt-45 hideinmo">
                            <div className="row justify-content-center g-0 gap-2 ">
                                <div className="col-md-3  text-center">
                                    <div  style={{borderRadius:'9px',backgroundColor:'rgba(255, 255, 255, 1)',border:'1px solid rgba(0, 112, 209, 1)',  backdropFilter: 'blur(9px)',WebkitBackdropFilter: 'blur(9.12px)',padding: '20px',}}>
                                          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-1.svg" alt="" />
                                          <p className="fw-700 fs-15 outfit pt-2" style={{color:'rgba(0, 83, 154, 1)'}}>Fintech</p>
                                    </div>
                                </div>

                                <div className="col-md-3  text-center">
                                    <div  style={{borderRadius:'9px',backgroundColor:'rgba(255, 255, 255, 1)',border:'1px solid rgba(0, 112, 209, 1)',  backdropFilter: 'blur(9px)',WebkitBackdropFilter: 'blur(9.12px)',padding: '20px',}}>
                                          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-2.svg" alt="" />
                                          <p className="fw-700 fs-15 outfit pt-2" style={{color:'rgba(0, 83, 154, 1)'}}>Construction</p>
                                    </div>
                                </div>

                                <div className="col-md-3  text-center">
                                    <div  style={{borderRadius:'9px',backgroundColor:'rgba(255, 255, 255, 1)',border:'1px solid rgba(0, 112, 209, 1)',  backdropFilter: 'blur(9px)',WebkitBackdropFilter: 'blur(9.12px)',padding: '20px',}}>
                                          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-3.svg" alt="" />
                                          <p className="fw-700 fs-15 outfit pt-2" style={{color:'rgba(0, 83, 154, 1)'}}>Hospitality</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center g-0 pt-2 gap-2 hideinmo">
                                <div className="col-md-3 text-center">
                                    <div  style={{borderRadius:'9px',backgroundColor:'rgba(255, 255, 255, 1)',border:'1px solid rgba(0, 112, 209, 1)',  backdropFilter: 'blur(9px)',WebkitBackdropFilter: 'blur(9.12px)',padding: '20px',}}>
                                          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-4.svg" alt="" />
                                          <p className="fw-700 fs-15 outfit pt-2" style={{color:'rgba(0, 83, 154, 1)'}}>Edtech</p>
                                    </div>
                                </div>

                                <div className="col-md-3 text-center">
                                    <div  style={{borderRadius:'9px',backgroundColor:'rgba(255, 255, 255, 1)',border:'1px solid rgba(0, 112, 209, 1)',  backdropFilter: 'blur(9px)',WebkitBackdropFilter: 'blur(9.12px)',padding: '20px',}}>
                                          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-5.svg" alt="" />
                                          <p className="fw-700 fs-15 outfit pt-2" style={{color:'rgba(0, 83, 154, 1)'}}>Travel</p>
                                    </div>
                                </div>

                                <div className="col-md-3 text-center">
                                    <div  style={{borderRadius:'9px',backgroundColor:'rgba(255, 255, 255, 1)',border:'1px solid rgba(0, 112, 209, 1)',  backdropFilter: 'blur(9px)',WebkitBackdropFilter: 'blur(9.12px)',padding: '20px',}}>
                                          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-6.svg" alt="" />
                                          <p className="fw-700 fs-15 outfit pt-2" style={{color:'rgba(0, 83, 154, 1)'}}>Logistics</p>
                                    </div>
                                </div>
                            </div>
                        </div>
 
                    </div>
            {/* Mobile View  */}
                  <div className="hideindesktop">
               
                    <div className="row pt-10 g-2">
                    <div className="col-4 text-center">
                                    <div  style={{borderRadius:'9px',backgroundColor:'rgba(255, 255, 255, 1)',border:'1px solid rgba(0, 112, 209, 1)',  backdropFilter: 'blur(9px)',WebkitBackdropFilter: 'blur(9.12px)',padding: '20px',}}>
                                          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-1.svg" alt="" />
                                          <p className="fw-700 fs-15 outfit pt-2" style={{color:'rgba(0, 83, 154, 1)'}}>Fintech</p>
                                    </div>
                                </div>

                                <div className="col-4  text-center">
                                    <div  style={{borderRadius:'9px',backgroundColor:'rgba(255, 255, 255, 1)',border:'1px solid rgba(0, 112, 209, 1)',  backdropFilter: 'blur(9px)',WebkitBackdropFilter: 'blur(9.12px)',padding: '20px',}}>
                                          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-2.svg" alt="" />
                                          <p className="fw-700 fs-15 outfit pt-2" style={{color:'rgba(0, 83, 154, 1)'}}>Construction</p>
                                    </div>
                                </div>

                                <div className="col-4  text-center">
                                    <div  style={{borderRadius:'9px',backgroundColor:'rgba(255, 255, 255, 1)',border:'1px solid rgba(0, 112, 209, 1)',  backdropFilter: 'blur(9px)',WebkitBackdropFilter: 'blur(9.12px)',padding: '20px',}}>
                                          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-3.svg" alt="" />
                                          <p className="fw-700 fs-15 outfit pt-2" style={{color:'rgba(0, 83, 154, 1)'}}>Hospitality</p>
                                    </div>
                                </div>

                    </div>

                    <div className="hideindesktop">
                    <div className="row pt-2 g-2">
                    <div className="col-4 text-center">
                                    <div  style={{borderRadius:'9px',backgroundColor:'rgba(255, 255, 255, 1)',border:'1px solid rgba(0, 112, 209, 1)',  backdropFilter: 'blur(9px)',WebkitBackdropFilter: 'blur(9.12px)',padding: '20px',}}>
                                          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-4.svg" alt="" />
                                          <p className="fw-700 fs-15 outfit pt-2" style={{color:'rgba(0, 83, 154, 1)'}}>Edtech</p>
                                    </div>
                                </div>

                                <div className="col-4 text-center">
                                    <div  style={{borderRadius:'9px',backgroundColor:'rgba(255, 255, 255, 1)',border:'1px solid rgba(0, 112, 209, 1)',  backdropFilter: 'blur(9px)',WebkitBackdropFilter: 'blur(9.12px)',padding: '20px',}}>
                                          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-5.svg" alt="" />
                                          <p className="fw-700 fs-15 outfit pt-2" style={{color:'rgba(0, 83, 154, 1)'}}>Travel</p>
                                    </div>
                                </div>

                                <div className="col-4 text-center">
                                    <div  style={{borderRadius:'9px',backgroundColor:'rgba(255, 255, 255, 1)',border:'1px solid rgba(0, 112, 209, 1)',  backdropFilter: 'blur(9px)',WebkitBackdropFilter: 'blur(9.12px)',padding: '20px',}}>
                                          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-6.svg" alt="" />
                                          <p className="fw-700 fs-15 outfit pt-2" style={{color:'rgba(0, 83, 154, 1)'}}>Logistics</p>
                                    </div>
                                </div>
                                </div>
                                </div>

                <div className="pt-4 text-center" >
                <a href="https://outlook.office365.com/owa/calendar/MihirProjectLeadatDataMetricks@datametricks.com/bookings/">
                    <button type="button" className="btn btn-sm font-14  pt-2  px-4" style={{fontSize:'20px',fontWeight:'600',backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'45px',border:'none',outline:'none',paddingBottom:'10px'}}>
                    Book a Free Consultation
                    </button>
                </a>
            </div>
            <div className="pt-3 text-center" >
                <a href="https://outlook.office365.com/owa/calendar/MihirProjectLeadatDataMetricks@datametricks.com/bookings/">
                    <button type="button" className="btn btn-sm font-14  pt-2 text-white px-4" style={{fontSize:'20px',fontWeight:'600',borderRadius:'45px',border:'1px solid rgba(255, 255, 255, 1)',outline:'none',paddingBottom:'10px'}}>
                    View Portfolio
                    </button>
                </a>
            </div>
                    </div>
                </div>
              
            </div>
        </section>



        <section style={{backgroundColor:'rgba(232, 244, 255, 1)'}}>
            <div className="container pb-lg-5 pt-lg-5 pt25 pb-20" >
              
                <div className="row  justify-content-center align-items-center">

                <div className="col-lg-4 col-12 textcenter  ">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-img1.svg" alt="" className="img-fluid "/>
                        
                        </div>

                        <div className="col-lg-7 col-12  plr-20 ">
                            <p className="fw-700 fs-30 plus font-22 pt30" style={{color:'rgba(0, 0, 0, 1)'}}>Building Custom Software Development Solutions to Meet Business Needs</p>
                            <p className="fs-17 fw-400 plus font-15" style={{color:'rgba(34, 34, 34, 0.6)'}}>We specialize in delivering tailored software solutions designed to meet the unique needs of your business. From concept to deployment, our expert team develops scalable, efficient, and user-friendly applications that enhance operations and drive growth. Whether you need enterprise software, CRM systems, or custom tools, we ensure high performance, security, and seamless integration with your existing workflows. Let' transform your ideas into innovative software solutions.</p>
                     </div>

                </div>
            </div>

        </section>

        <section className=" mt-20" style={{backgroundColor:'#F6FBFF'}}>
    <div className="container pt-4 pb-5 ">
        <p className="fw-800 fs-35 plus text-center mb-0 font-23" style={{color:'#1B3567'}}>India's One Of The Highest Rated Software Development Company</p>
        <p className="fw-500 fs-20 plus text-center pt-2 font-18" style={{color:'#1B3567'}}> 100+ Glowing 5-Star Reviews Across Top B2B Review Platforms</p>
        <div className="row justify-content-center mt-4 g-2 p-10" >

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/clutch_img1.svg" alt="box-img" style={{height:'100px',width:'96px'}} className="img-fluid  pt-1"  />
                    <img src={ratingstar} alt="" className="pt-2  " />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                                  

                </div>
            </div>
            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img2.svg" alt="box-img" style={{height:'100px',width:'96px'}} className="img-fluid heightwidth pt-1"  />
                    <img src={ratingstar} alt="" className="pt-2  " />
                    <p className="fw-700 fs-32 mont font-23" >4.9</p>
                                  

                </div>
            </div>

            {/* <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img2.svg" alt="box-img" style={{height:'113px',width:'90px'}} className="img-fluid heightwidth1" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.9</p>
                </div>
            </div> */}

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img3.svg" alt="box-img" style={{height:'113px',width:'120px'}} className="img-fluid heightwidth2" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img4.svg" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid heightwidth2" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2 h-100" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    {/* <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img5.svg" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid" /> */}
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/googleicon.png" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid heightwidth3 pt-1" />

                    <img src={ratingstar} alt="" className="pt-1"  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

          
        </div>
    </div>

</section>



<section className="mt-lg-5 mt-20 ">
<p className="fs-35 fw-700 plus pt-1 text-center font-25">Our Clients</p>
    <div className="p-4 mt-4" style={{backgroundColor:'rgba(255, 255, 255, 1)',boxShadow: '0px 0px 7.3px 0px rgba(175, 175, 175, 0.25)'}}>
        <div className="container">
            <div className="row ">           
                <div className="col-lg-12">
                    <CompanyLogoSlider/>
                </div>
            </div>
        </div>

    </div>

</section>

<section>
    <section>
       <div className='container textcenter text-center  mt-lg-5 mt-20'>
            <p className='fw-600 fs-35 plus mb-0 font-23'>Our Success Stories Transformative</p>
            <p className='fw-600 fs-35 plus font-23 '>Projects and <span style={{color:'rgba(0, 83, 154, 1)'}}>Case Studies </span></p>
       </div>
    </section>

    <section className="my-5">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-10">
              <CustomSlider slides={slides}/>

            </div>
        </div>
    </div>
</section>
</section>





<section >
            <div className="mt-lg-5 " style={{backgroundColor:'rgba(208, 233, 255, 1)'}}>
                <div className="container pt-4 pb-5 ">
                <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                       <p className="fw-800 fs-35 plus  font-25 " style={{color:'rgba(0, 0, 0, 1)'}}>Our Software Development Services</p>  
                     </div>
              
                 </div>

                 <div className="row justify-content-center  mt-lg-3 g-0 g-2 pt-10">
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-7.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Custom Software Development</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Tailored solutions to meet unique business needs, ensuring scalability, security, and performance.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-8.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Web Application Development</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Dynamic, responsive, and user-friendly web applications designed to elevate user experience.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-9.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Mobile App Development</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Native, hybrid, and cross-platform apps for iOS and Android to bring your ideas to mobile users.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-10.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Enterprise Software Solutions</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Robust and scalable software for managing complex business processes efficiently.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-11.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>UI/UX Design</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Intuitive designs focused on enhancing user satisfaction and ease of use.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-12.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Software Maintenance and Support</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Ongoing updates, performance optimization, and technical support to ensure software reliability.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>


              




              </div>
            </div>
</section>


<section>
                <div className="container mt-4">
                    <p className="fw-800 fs-30 plus text-center font-25" style={{color:'rgba(0, 0, 0, 1)'}}>Why Choose us For your  Software Development Needs</p>
                    <div className="row g-2 pt-4 justify-content-center">
                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 h-100">
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-13.svg" alt="" />
                            <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'rgba(0, 83, 154, 1)'}}>Experienced Team</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'rgba(124, 124, 124, 1)'}}>A dedicated team of developers, designers, and project managers.</p>

                        </div>
                        </div>

                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 h-100">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-14.svg" alt="" />
                        <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'rgba(0, 83, 154, 1)'}}>Cutting-Edge Technology</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'rgba(124, 124, 124, 1)'}}>Expertise in the latest frameworks and tools.</p>

                        </div>
                        </div>

                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 h-100">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-15.svg" alt="" />
                        <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'rgba(0, 83, 154, 1)'}}>Client-Centric Approach</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'rgba(124, 124, 124, 1)'}}>Tailored solutions to meet your business goals.</p>

                        </div>
                        </div>

                    </div>







                    <div className="row g-2 pt-2 justify-content-center">
                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 h-100">
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-16.svg" alt="" />
                            <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'rgba(0, 83, 154, 1)'}}>Agile Methodology</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'rgba(124, 124, 124, 1)'}}>Flexibility and adaptability to ensure timely delivery.</p>

                        </div>
                        </div>

                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 h-100">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-13.svg" alt="" />
                        <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'rgba(0, 83, 154, 1)'}}>Transparent Communication</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'rgba(124, 124, 124, 1)'}}>Regular updates and open channels for feedback.</p>

                        </div>
                        </div>

                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 h-100">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-17.svg" alt="" />
                        <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'rgba(0, 83, 154, 1)'}}>Proven Track Record</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'rgba(124, 124, 124, 1)'}}>A portfolio of successful projects across various industries.</p>

                        </div>
                        </div>

                    </div>
                </div>
</section>


<section>
  <div className="container mt-lg-5 mt-30">
  <p className="fw-800 fs-30 plus text-center font-25" style={{color:'rgba(0, 0, 0, 1)'}}>Tools and Technologies We Utilized</p>
  <div className="text-center pt-4">
  <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/technology-img.svg" alt="" className="img-fluid" />
  </div>
  </div>
</section>




<section >
            <div className="mt-lg-5 mt-30 pt-10" style={{backgroundColor:'rgba(208, 233, 255, 1)'}}>
                <div className="container pt-4 pb-5 ">
                <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                       <p className="fw-800 fs-35 plus  font-25 " style={{color:'rgba(0, 0, 0, 1)'}}>Intelligent Software Solutions</p>  
                     </div>
              
                 </div>

                 <div className="row justify-content-center  mt-lg-3 g-0 g-2 pt-10">
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-18.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}> AI-Powered Innovation</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our AI-powered solutions are designed to drive innovation, efficiency, and precision. From natural language processing to predictive analytics, we craft intelligent systems that automate tasks, enhance decision-making, and provide unmatched scalability for your growing business needs.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-19.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Smart Automation</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Eliminate manual tasks and reduce operational costs with our smart automation tools. Whether it’s robotic process automation (RPA) or end-to-end workflow automation, we enable your business to operate faster, smarter, and with fewer errors.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-20.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Predictive Insights</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Leverage the power of predictive analytics to make informed decisions. Our intelligent solutions analyze complex data, identify trends, and forecast future outcomes, helping you stay ahead in a competitive market.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-21.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Scalable Cloud Solutions</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Built on cutting-edge cloud platforms, our solutions ensure reliability, scalability, and seamless integration with your existing systems. Whether you’re a startup or an enterprise, our solutions grow with your business.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>

                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-22.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Personalized User Experiences</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>We create intelligent software solutions with the end-user in mind. Our user-friendly designs ensure effortless adoption, making your systems easy to use and highly effective for all stakeholders.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-23.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Cognitive Applications</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Our cognitive applications use AI to mimic human reasoning and learning, delivering smarter interactions, faster resolutions, and enhanced operational efficiency for your business.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>


              
                 <div className="row justify-content-center g-0 g-2 " style={{paddingTop:'8px'}}>
                <div className="col-lg-5 col-12 col-md-3">

                      <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-24.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Intelligent Security</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>Stay protected in an ever-evolving threat landscape with intelligent security solutions. Our AI-based tools identify and mitigate risks in real time, safeguarding your data and operations.</p>
                       
                    </div>

                </div>

                        <div className="col-lg-5 col-12 col-md-3">

                        <div className="px-4 py-3 h-100" style={{borderTopRightRadius:'6px',backgroundColor:'rgba(255, 255, 255, 1)',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                            <div className="d-flex gap-2">
                                <div>
                                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/softdev-icon-25.svg" alt="" />
                                </div>
                                <div>
                                    <p className="fw-700 fs-18 plus font-15" style={{color:'rgba(0, 83, 154, 1)'}}>Industry-Specific Solutions</p>
                                </div>
                           </div>
                           <p className="fw-500 fs-15 plus font-13 fw700" style={{color:'rgba(0, 0, 0, 0.7)'}}>From healthcare to retail, our intelligent software solutions are customized for your industry. We combine domain expertise with advanced technology to deliver targeted results.</p>
                       
                    </div>
                       
                        </div>
              
                 </div>



              </div>
            </div>
</section>

       
<section>

<section className='d-none d-sm-none d-lg-block d-md-block'>

<div className="mt-5">
        <p className="fs-35 fw-700 plus text-center mb-0 font-20">Software Development Process</p>

 </div>
 <div className="container mt-lg-5 ">
<div className="row ms-lg-5">
  <div className="col-lg-4 ">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt=""  />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">
          1. Discovery and Planning
        </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
         Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot2} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">2. Design</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Bringing your vision to life with captivating visuals and
          intuitive user interfaces.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt="" />
      </div>
    
      <div>
        <p className="sec5title mb-0 mt-1">3. Development</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Transforming designs into functional <br /> websites with
          clean code and seamless functionality.
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<div className="container">

<div className="col-lg-12 ms-lg-5">
<img className="img-fluid" src={stagesbg} alt="" />
</div>
</div>
<div className="container pt-4 ">
<div className="row ms-lg-5">
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">4. Testing </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Thoroughly checking every aspect of the website to ensure
          it meets quality standards and performs flawlessly.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot4} alt="" />
      </div>
     
      <div>
        <p className="sec5title mb-0 mt-1">5. Launch</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Deploying the website to the live <br /> environment and
          making it accessible to your audience.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">6. Beyond</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Providing ongoing support, maintenance, and updates to
          ensure your website remains optimized and up to date.
        </p>
      </div>
    </div>
  </div>
</div>



</div>
    
</section>

<section className='d-block d-sm-block d-lg-none d-md-none'>
  <div className='container mt-4 '>
  <p className="fs-35 fw-700 plus text-center mb-0 font-20">Our Streamlined Web Development</p>
  <p className="fs-35 fw-700 plus text-center font-20"> Process from Concept to Launch</p>
    <div className='row justify-content-center'>
     
      <div className='col-10 mt-2'>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot1} alt=""  />
          </div>
          <div>
            <p className="sec5title fs-15 mb-0 mt-1">
              1. Discovery and Planning
            </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12 "
            >
            Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot2} alt="" />
          </div>
        
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">2. Design</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Bringing your vision to life with captivating visuals and
              intuitive user interfaces.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot1} alt="" />
          </div>
        

          <div>
            <p className="sec5title mb-0 mt-1 fs-15">3. Development </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Transforming designs into functional <br /> websites with
              clean code and seamless functionality.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot3} alt="" />
          </div>
       
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">4. Testing </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Thoroughly checking every aspect of the website to ensure
              it meets quality standards and performs flawlessly.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot4} alt="" />
          </div>
       
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">5. Launch</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Deploying the website to the live <br /> environment and
              making it accessible to your audience.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot3} alt="" />
          </div>
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">6. Beyond</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Providing ongoing support, maintenance, and updates to
              ensure your website remains optimized and up to date.
            </p>
          </div>
        </div>
      </div>
    </div>
    

  </div>
</section>

</section>

 
<section>
    <div className="container mt-lg-5 mt-30">
        <div className="row justify-content-center align-items-center">
            <div className="col-lg-4 col-12">
                 <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Us-img4.svg" alt=""  className="img-fluid"/>

            </div>
            <div className="col-lg-7 col-12 ps-lg-5 mt-30 plr-20">
                <div>
                    <p className="fw-800 plus fs-30 font-25" style={{color:'rgba(0, 0, 0, 1)'}}>Let’s Build Your Dream Website</p>
                    <p className="fw-400 fs-16 plus " style={{color:'rgba(0, 0, 0, 1)'}}>Take control of your online presence today. Whether you're a small business, a large corporation, or a creative professional, our CMS development services provide the tools you need to succeed.</p>
                    <p className="fw-400 fs-16 plus " style={{color:'rgba(0, 0, 0, 1)'}}>Contact us today to discuss your project and see how we can help you achieve your digital goals.</p>
                    <p className="fw-700 fs-25 plus" style={{color:'rgba(0, 83, 154, 1)'}}>Get Started Today!</p>
                    <div>
                <a href="https://outlook.office365.com/owa/calendar/MihirProjectLeadatDataMetricks@datametricks.com/bookings/">
                    <button type="button" className="btn btn-sm font-14  pt-2  px-4" style={{fontSize:'15px',fontWeight:'400',backgroundColor:'rgba(246, 102, 0, 1)',borderRadius:'45px',border:'none',outline:'none',paddingBottom:'10px',color:'white'}}>
                    Book a Free Consultation
                    </button>
                </a>
            </div>
                </div>

            </div>
        </div>

    </div>
   </section>
       

   <div id="formsection">

</div>


<section  className=" d-none d-sm-none d-md-block d-lg-block" style={{marginTop:'6%'}}>
   <NewForm/>

</section>

{/* Mobile View  */}
<section className="mt-lg-4 d-block d-sm-block d-md-none d-lg-none">
    <div className=" pt-3 pb-5 px-2 mt-30" style={{backgroundColor:'rgba(242, 242, 242, 1)'}}>
        <div className="container">
         
                      <p className="fw-600 text-center fs-23 plus mb-0 pt-1" >What can we help you with?</p>
                      <p className="fw-500 text-center fs-17 plus pt-3" style={{lineHeight:'22px'}}>Get Custom Solutions, Recommendations, or, Estimates.
                      Confidentiality & Same Day Response Guaranteed!</p>
    <div className="pt-1">
       <Form/>
    </div>

    <div className="d-flex justify-content-center gap-2 mt-4 ">

<div className="d-flex  gap-2">
    <div>
          <img src= "https://d3vqz9qyn2r1xe.cloudfront.net/imges/green-icon-form.svg" alt="" className="pt-1" />
    </div>
    <div>
           <p className="fw-600 plus fs-17  pt-1">100% Confidential</p>
    </div>
</div>

<div className="d-flex  gap-2">
    <div>
          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/green-icon-form.svg" alt="" className="pt-1" />
    </div>
    <div>
           <p className="fw-600 plus fs-17  pt-1">We sign NDA</p>
    </div>
</div>

</div>

<div className="d-flex gap-4 mt-4">
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon1.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon2.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon3.svg" alt="" className="img-fluid pt-1" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon4.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon5.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon6.svg" alt="" className="img-fluid pt-2" />
            </div>
    </div>

      </div>
      </div>
</section>



        <section style={{backgroundColor:'rgba(208, 233, 255, 1)'}}>
      <div className=" pt-5 pb-4">
          <Faqaccordian faqAccordianData={faqDatamobile}/>
          </div>
      </section>



        <section >
                <Footer/>
        </section>

    </div>
);
};
export default SoftwareDevPage;