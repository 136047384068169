import React, { useState } from 'react';


const Chatbox = () => {
//   const chatboxicon = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/chatbotimg 1.svg";
// const chatbotlogo = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/chatbotlogo.svg";
// const chatbotcross = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/chatboxcross.svg";

//   const [isChatOpen, setIsChatOpen] = useState(false);
//   const [isFormVisible, setIsFormVisible] = useState(false);
//   const [messages, setMessages] = useState([
//     { text: "Welcome to DataMetricks! This is your virtual assistant.",
//        type: "bot-message"
//        }
//   ]);

//   const handleChatboxIconClick = () => {
//     setIsChatOpen(true); 
//   };

//   const handleChatbotCloseClick = () => {
//     setIsChatOpen(false); 
//   };



//   const selectService = (serviceName) => {
//     setMessages(prevMessages => [
//       ...prevMessages,
//       { text: `You have selected ${serviceName}. Please fill out the form for more details.`, type: "user-message" }
//     ]);
//     setIsFormVisible(true);
//   };

//   const submitForm = (event) => {
//     event.preventDefault();
//     const name = event.target.name.value;
//     const email = event.target.email.value;

//     setMessages(prevMessages => [
//       ...prevMessages,
//       { text: `Thank you ${name}! We have received your message. We will contact you at ${email} .`,
//        type: "bot-message",
//        icon: "https://d3vqz9qyn2r1xe.cloudfront.net/imges/greenicon1.svg" 
      
//       }
//     ]);

//     setIsFormVisible(false);
//   };

  return (
  
  <div>


  </div>
  //   <div className="unique-chatbox-wrapper">

  //   {!isChatOpen && (
  //     <div className="unique-chatbox-icon" onClick={handleChatboxIconClick}>
  //       <img src={chatboxicon} alt="Chatbox Icon" className='chatboxupdown-icon'/>
  //     </div>
  //   )}

  //   {isChatOpen && (
  //     <div className="unique-chat-container">
  //       <div className="unique-chat-header">
  //         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
  //           <div style={{ display: 'flex', gap: '10px' }}>
  //             <div style={{ marginTop: '5px' }}>
  //               <img src={chatbotlogo} alt="Chatbot Logo" />
  //             </div>
  //             <div>
  //               <p className="plus fw-500 fs-18" style={{ color: 'hsla(0, 0%, 100%, 1)',marginTop:'12px' }}>DataMetricks</p>
  //             </div>
  //           </div>
  //           <div className="unique-chatbot-close" style={{ marginTop: '15px' }} onClick={handleChatbotCloseClick}>
  //             <img src={chatbotcross} alt="Close Chat" />
  //           </div>
  //         </div>
  //       </div>

   
  //       <div className="unique-chat-box">
  //         {messages.map((message, index) => (
  //           <div key={index} className={`message ${message.type}`}>
  //             {message.text}
  //             {message.icon && (
  //       <img 
  //         src={message.icon} 
  //         alt="bot icon" 
  //         className="message-icon12345"
         
  //       />
  //     )}
  //           </div>
  //         ))}

  //         {!isFormVisible && (
  //           <div className="unique-service-container message bot-message-service">
  //             <h3>Our Services</h3>
  //             <div className="unique-service-buttons">
  //               <button onClick={() => selectService('Website Development')}>Website Development</button>
  //               <button onClick={() => selectService('Mobile Development')}>Mobile Development</button>
  //               <button onClick={() => selectService('Social Media')}>Social Media</button>
  //               <button onClick={() => selectService('Graphic Design')}>Graphic Design</button>
  //               <button onClick={() => selectService('Digital Marketing')}>Digital Marketing</button>
  //               <button onClick={() => selectService('SEO')}>SEO</button>
  //             </div>
  //           </div>
  //         )}

  //         {isFormVisible && (
  //           <div className="unique-custom-service-form message bot-message">
  //             <form onSubmit={submitForm}>
  //               <input
  //                 style={{ outline: 'none', width: '100%' }}
  //                 type="text"
  //                 id="name"
  //                 name="name"
  //                 placeholder="Your Name"
  //                 required
  //               />
  //                <input
  //                 style={{ outline: 'none', width: '100%' }}
  //                 type="email"
  //                 id=""
  //                 name="email"
  //                 placeholder="Your Email"
  //                 required
  //               />
               
  //               <textarea
  //                 style={{ outline: 'none', width: '100%' }}
  //                 id="message"
  //                 name="message"
  //                 placeholder="Your Message"
  //                 required
  //               />
  //               <button type="submit">Submit</button>
  //             </form>
  //           </div>
  //         )}
  //       </div>

      
  //     </div>
  //   )}
  // </div>
  );
};

export default Chatbox;
