import CompanyLogoSlider from "../Components/CompanyLogoSlider";
import Footer from "../Components/footer";
import Header from "../Components/Header";
import Testimonial1 from "../Components/Testimonial1";


const Aboutus1 = () =>  {

const newheaderimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newHeaderimg1.svg";
const newheaderimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg2.svg";
const newheaderimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg3.svg";
const newheaderimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg4.svg";
const newheaderimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg5.svg";
const newheaderimg6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg6.svg";
const newheaderimg7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg7.svg";
const newheaderimg8 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg8.svg";
const newheaderimg9 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg9.svg";
const newheaderimg10 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg10.svg";

return(

    <div>
        <section>
                <Header/>
        </section>


       <section className="aboutsecbackground " style={{marginTop:'70px'}}>
  <div className="text-center container">
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <p className="fs-60 fw-800 plus text-white">About us</p>
        <p className="fs-18 fw-400 plus" style={{ color: 'rgba(255, 255, 255, 0.8)' }}>
          We are a team of innovative thinkers, creative designers, and tech-savvy developers committed to delivering exceptional digital solutions. Our diverse expertise allows us to tackle challenges head-on and provide customized strategies that drive results.
        </p>
      </div>
    </div>
  </div>
</section>

<section>
    <div className="container my-lg-5 mt-10">
        <div className="row justify-content-center">
            <div className="col-lg-10">
                <div style={{position:'relative'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/a-img1.svg" className="img-fluid " alt="" />
                    <div className="p-4 d-sm-none d-none d-md-block d-lg-block" style={{position:'absolute',top:'23%',left:'34%',right:'0%',bottom:'0%', height:'200px',width:'747px',borderRadius:'10px',boxShadow:'0px 0px 4px 0px rgba(110, 110, 110, 0.25)',backgroundColor:'white'}}>
                        <p className="fs-25 fw-600 plus  mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>OUR MISSION</p>
                        <p className="fs-17 fw-400 plus pt-2" style={{color:'rgba(121, 134, 159, 1)'}}>Our mission is to empower businesses by providing cutting-edge digital marketing and website development services that foster growth and innovation. We strive to create digital experiences that are not only impactful but also sustainable in the ever-evolving digital world. </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>


<section>
    <div className="container my-lg-5 mt-10 d-sm-none d-none d-lg-block d-md-block">
        <div className="row justify-content-center">
            <div className="col-lg-10 ">
                <div style={{position:'relative'}} className="text-end">
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/a-img2.svg" className="img-fluid " alt="" />
                    <div className="p-4 " style={{position:'absolute',top:'23%',left:'0%',right:'0%',bottom:'0%', height:'200px',width:'747px',borderRadius:'10px',boxShadow:'0px 0px 4px 0px rgba(110, 110, 110, 0.25)',backgroundColor:'white'}}>
                        <p className="fs-25 fw-600 plus text-start mb-0" style={{color:'rgba(0, 83, 154, 1)'}}>OUR VISION</p>
                        <p className="fs-17 fw-400 plus text-start pt-2" style={{color:'rgba(121, 134, 159, 1)'}}>We envision a future where businesses of all sizes can harness the power of digital technology to achieve their goals. By staying ahead of industry trends and continuously improving our skills, we aim to be at the forefront of digital innovation. </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<div className="container">
    <div className="row justify-content-center">
        <div className="col-lg-8 text-center">
    <p className="fw-700 fs-30 plus mb-0 font-23 mt-10">Let's <span className="fw-700 fs-30 plus font-23" style={{color:'rgba(0, 83, 154, 1)'}}>Build the Future</span> Together </p>
    <p className="fs-17 plus pt-1 font-18" style={{color:'rgba(121, 134, 159, 1)'}}> We believe in the power of collaboration and innovation. Our team is dedicated to transforming your digital presence and driving your business towards success. Whether you're looking to revamp your website, enhance your digital marketing strategies, or leverage the latest technologies, we have the expertise and passion to make it happen. </p>
     </div>
    </div>
</div>

<section>
    <div className="pt-lg-4 pb-5  mt-3 pt-10" style={{backgroundColor:'rgba(198, 229, 255, 1)'}}>
        <div className="container">
            <p className="fs-30 plus fw-700 font-23" style={{color:'rgba(0, 86, 159, 1)'}}>Our Digital Marketing & Web Development Services</p>
            <p className="fw-500 fs-18 plus" style={{color:'rgba(0, 86, 159, 1)'}}>Digital Marketing</p>
            <div className="row">
            <div className='col-lg-4 '>
                    <a href='/services/digital-marketing' style={{textDecoration:'none'}} > <div className=' header-shadow p-2 ' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px',}}>

        <div className='d-flex gap-3 align-items-center' >

             <div>
               <img src={newheaderimg1} alt=''  />
             </div>
          
             <div>
                <p className='fw-600 plus fs-17 mb-0 ' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>Digital Marketing</p>
                <p className='fs-14 plus' style={{color:'rgba(53, 53, 53, 0.72)'}}>Accelerate Your Online Growth with Expert Digital Marketing Solutions</p>
              </div>
        </div>
        </div></a>
        </div>

        <div className='col-lg-4 pt-10'>

<a href='/service/search-engine-optimization-seo' style={{textDecoration:'none'}} ><div className=' header-shadow p-2 ' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>

<div className='d-flex gap-3 align-items-center'>
<div>
   <img src={newheaderimg2} alt='' />
 </div>
 <div >
    <h1 className='fw-600 plus fs-17 mb-0 ' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>SEO  </h1>
    <p className='fs-14 plus ' style={{color:'rgba(53, 53, 53, 0.72)'}}>Boost Your Search Rankings and Drive Organic Traffic</p>
  </div>
</div>
</div></a>
</div>

<div className='col-lg-4 pt-10'>

<a href='/service/social-media-marketing' style={{textDecoration:'none'}} ><div className=' header-shadow p-2' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>

<div className='d-flex gap-3 align-items-center'>
    <div>
       <img src={newheaderimg3} alt=''  />
     </div>
     <div >
        <h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>Social Media Marketing</h1>
        <p className='fs-14 plus ' style={{color:'rgba(53, 53, 53, 0.72)'}}>Amplify Your Brand's Voice with Targeted Social Media Campaigns </p>
      </div>
</div>
</div></a>
</div>
            </div>



            <div className='row  mt-lg-3 '>
                <div className='col-lg-4 pt-10'>

                <a href='/services/performance-marketing' style={{textDecoration:'none'}} ><div className=' header-shadow p-2 ' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>

                 <div className='d-flex gap-3 align-items-center'>
                     <div>
                        <img src={newheaderimg4} alt=''  />
                      </div>
                      <div >
                         <h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>Performance Marketing</h1>
                         <p className='fs-14 plus ' style={{color:'rgba(53, 53, 53, 0.72)'}}>Data-Driven Strategies to Maximize ROI and Lead Generation </p>
                       </div>
                 </div>
                 </div></a>
                 </div>
                 <div className='col-lg-4 pt-10'>

                 <a href='/services/graphic-desinging' style={{textDecoration:'none'}} ><div className=' header-shadow p-2' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>

<div className='d-flex gap-3 align-items-center'>
    <div>
       <img src={newheaderimg5} alt='' />
     </div>
     <div >
        <h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>Graphic Designing</h1>
        <p className='fs-14 plus ' style={{color:'rgba(53, 53, 53, 0.72)'}}>Crafting Visual Masterpieces to Elevate Your Brand Identity</p>
      </div>
</div>
</div></a>
</div>
            </div>
        <div style={{marginTop:'30px'}}>
            <hr  style={{width: '100%',color: 'rgba(106, 187, 255, 1)',height: '2px',backgroundColor: 'rgba(106, 187, 255, 1)',}} /> 
            </div>
            <p className="fw-500 fs-18 plus" style={{color:'rgba(0, 86, 159, 1)'}}>Web Development</p>
            <div className="row">

            <div className='col-lg-4'>
     
     <a href='/service/custom-website-design-development-solutions' style={{textDecoration:'none'}} >  <div className='mt-2 header-shadow p-2' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>

      <div className='d-flex gap-3 align-items-center'>
          <div>
             <img src={newheaderimg6} alt='' />
           </div>
           <div >
              <h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>Custom Website</h1>
              <p className='fs-14 plus pt-1' style={{color:'rgba(53, 53, 53, 0.72)'}}>Tailored Digital Solutions to Meet Your Unique Business Needs</p>
            </div>
      </div>
      </div></a>
      </div>

      <div className='col-lg-4 '>

<a href='/service/website-design-development-solutions' style={{textDecoration:'none'}} >    <div className='mt-2 header-shadow p-2' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>

<div className='d-flex gap-3 align-items-center'>
<div>
<img src={newheaderimg7} alt=''   />
</div>
<div >
<h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>Web Development</h1>
<p className='fs-14 plus pt-1' style={{color:'rgba(53, 53, 53, 0.72)'}}>Designing Cutting-Edge Websites that Drive Engagement and Growth</p>
</div>
</div>
</div></a>
</div>

<div className='col-lg-4'>

<a href='/service/mobile-app-design-development-solutions' style={{textDecoration:'none'}} >  <div className='mt-2 header-shadow p-2' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>

<div className='d-flex gap-3 align-items-center'>
<div>
<img src={newheaderimg8} alt=''  />
</div>
<div >
<h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>Mobile App Development</h1>
<p className='fs-14 plus pt-1' style={{color:'rgba(53, 53, 53, 0.72)'}}>Building Intuitive and High-Performance Mobile Apps for All Platforms</p>
</div>
</div>
</div></a>
</div>
            </div>



            <div className='row  mt-lg-3'>
            <div className='col-lg-4'>
     
     <a href='/service/ecommerce-website-design-development-solutions' style={{textDecoration:'none'}} >  <div className='mt-2 header-shadow p-2' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>
       
                        <div className='d-flex gap-3 align-items-center'>
                            <div>
                               <img src={newheaderimg9} alt=''   />
                             </div>
                             <div >
                                <h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>E-Commerce  Website</h1>
                                <p className='fs-14 plus pt-1' style={{color:'rgba(53, 53, 53, 0.72)'}}>Empowering Your Online Store with Seamless Ecommerce Development</p>
                              </div>
                        </div>
                        </div></a>
                        </div>
                        <div className='col-lg-4'>
       
         <a href='/service/cms-website-design-development-solutions' style={{textDecoration:'none'}} >   <div className='mt-2 header-shadow p-2' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>
       
       <div className='d-flex gap-3 align-items-center'>
           <div>
              <img src={newheaderimg10} alt='' />
  
            </div>
            <div >
               <h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>CMS Website</h1>
               <p className='fs-14 plus pt-1' style={{color:'rgba(53, 53, 53, 0.72)'}}>Efficient Content Management Solutions for Dynamic and Scalable Websites</p>
             </div>
       </div>
       </div></a>
       </div>
            </div>
            </div>

            </div>
</section>

     <section>
        <div>

        </div>
        
    </section> 

    <section className="mt-lg-5 mt-20 ">
    <div className="p-3" style={{backgroundColor:'rgba(255, 255, 255, 1)',boxShadow: '0px 0px 7.3px 0px rgba(175, 175, 175, 0.25)'}}>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-3 d-flex align-items-center d-sm-none d-none d-md-block d-lg-block">
                    <p className="fs-35 fw-700 plus pt-1">Our Clients</p>
                </div>
                <div className="col-lg-8">
                    <CompanyLogoSlider/>
                </div>
            </div>
        </div>

    </div>

</section>


<section className="mt-5 mb-5">
    <Testimonial1/>
</section>


    <section>
                <Footer/>
        </section>

    </div>  
);
};
export default Aboutus1;