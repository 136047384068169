const Footer = ()=>{
    const footertwitter = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/footertwitter.svg";

const footerlogo = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/footerlogo.svg";
// const footerrightarrow = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/footerrightarrow.svg";
const footerline = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/footerline.svg";
const footerlinkedin = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/footerlinkedin.svg";
const footerinsta = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/footerinsta.svg";
const footerfb = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/footerfb.svg";


const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/images/OurPortfolio.pdf'; // Path to the PDF file in the public directory
    link.download = 'DatametricksPortfolio.pdf'; // Name for the downloaded file
    link.click(); // Simulate a click to trigger the download
  };

    return(

            <div className="main-container">

     <section style={{backgroundColor:'#ECF6FF' }} >
        <div class="py-4">
            <p className="fs-22 fw-500 text-center " style={{color:'#181818'}}>Let’s Work Together, Just Drop us a Line- <a href="mailto:contact@datametricks.com" style={{textDecorationColor:'rgba(0, 83, 154, 1)'}}><span className="fs-22 fw-700 plus " style={{color:'rgba(0, 83, 154, 1)'}}>contact@datametricks.com</span></a></p>
        </div>
    </section>

                <section>
                    <div className="footerbgimg ">
                        <div className="container py-4">
                            <div className="row">
                                <div className="col-lg-3 ">
                                    <div className="pl-30">
                                    <img src={footerlogo} alt="" className="img-fluid footerlogo " />
                                    <p className="fs-20 fw-700 plus pt-2" style={{color:'rgba(255, 255, 255, 1)'}}>Reach out to us at</p>
                                    <p className="fw-600 fs-17 plus" style={{color:'rgba(255, 255, 255, 1)'}}>Head Office (India):</p>
                                    <div className="d-flex gap-2">
                                        <div>
                                           <img src='https://d3vqz9qyn2r1xe.cloudfront.net/imges/location-footer.svg' alt="" />
                                        </div>
                                        <div>
                                        <p className="fw-400 fs-14 plus" style={{color:'rgba(178, 178, 178, 1)'}}>ITHUM TOWER, Block A, Industrial Area, Sector 62, Noida, Uttar Pradesh 201309 </p>
                                        </div>
                                    </div>

                                    <div className="d-flex gap-2">
                                        <div>
                                           <img src='https://d3vqz9qyn2r1xe.cloudfront.net/imges/email-footer.svg' alt="" />
                                        </div>
                                        <div>
                                        <p className="fw-400 fs-14 plus" style={{color:'rgba(178, 178, 178, 1)',paddingTop:'1px'}}>contact@datametricks.com</p>
                                        </div>
                                    </div>

                                    <div className="d-flex gap-2">
                                        <div>
                                           <img src='https://d3vqz9qyn2r1xe.cloudfront.net/imges/call-footer.svg' alt="" />
                                        </div>
                                        <div>
                                        <p className="fw-400 fs-14 plus" style={{color:'rgba(178, 178, 178, 1)',paddingTop:'2px'}}>+91 &nbsp; 73039 46657</p>
                                        </div>
                                    </div>

                                    <p className="fw-600 fs-17 plus" style={{color:'rgba(255, 255, 255, 1)'}}>Regional Office (Europe) :</p>
                                    <div className="d-flex gap-2">
                                        <div>
                                           <img src='https://d3vqz9qyn2r1xe.cloudfront.net/imges/location-footer.svg' alt="" />
                                        </div>
                                        <div>
                                        <p className="fw-400 fs-14 plus" style={{color:'rgba(178, 178, 178, 1)'}}>Vanåsgatan 132, 216 20 Malmö, Sweden</p>
                                        </div>
                                    </div>

                                    <div className="d-flex gap-2">
                                        <div>
                                           <img src='https://d3vqz9qyn2r1xe.cloudfront.net/imges/email-footer.svg' alt="" />
                                        </div>
                                        <div>
                                        <p className="fw-400 fs-14 plus" style={{color:'rgba(178, 178, 178, 1)',paddingTop:'1px'}}>contact@datametricks.com </p>
                                        </div>
                                    </div>

                                    <div className="d-flex gap-2">
                                        <div>
                                           <img src='https://d3vqz9qyn2r1xe.cloudfront.net/imges/call-footer.svg' alt="" />
                                        </div>
                                        <div>
                                        <p className="fw-400 fs-14 plus" style={{color:'rgba(178, 178, 178, 1)',paddingTop:'2px'}}>+46 &nbsp; 73 610 97 57</p>
                                        </div>
                                    </div>

                                    <button type="button" class="btn btn-sm mt-3 px-3 downloadbutton" onClick={handleDownload} style={{backgroundColor:'rgba(255, 255, 255, 0.8)',fontSize:'16px',outline:'none',border:'none',boxShadow:'none',paddingTop:'5px',paddingBottom:'8px'}}>Download Our Portfolio</button>
                                    </div>
                                </div>
                                        <div className="col-lg-3">
                                        <div className="pl-30 mt-30" style={{paddingLeft:'100px'}}>

                                         <a href="/" style={{textDecoration:'none'}}>   <p className="fw-600 fs-20 plus" style={{color:'rgba(255, 255, 255, 1)'}}>DataMetricks</p></a>
                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                <a href="https://blog.datametricks.com/" style={{textDecoration:'none'}}><p className="fs-18 plus footerHover " style={{color:'rgba(255, 255, 255, 0.8)'}}>Blogs</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div> */}

                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                  <a href="https://blog.datametricks.com/career/" style={{textDecoration:'none'}}>  <p className="fs-18 plus footerHover" style={{color:'rgba(255, 255, 255, 0.8)'}}>Careers</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div> */}

                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                <a href="/about-us" style={{textDecoration:'none'}}>  <p className="fs-18 plus footerHover" style={{color:'rgba(255, 255, 255, 0.8)'}}>About Us</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div> */}
                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                <a href="/our-team" style={{textDecoration:'none'}}>   <p className="fs-18 plus footerHover" style={{color:'rgba(255, 255, 255, 0.8)'}}>Our Team</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>  */}
                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                <a href="/contact-us" style={{textDecoration:'none'}}>   <p className="fs-18 plus footerHover" style={{color:'rgba(255, 255, 255, 0.8)'}}>Contact Us</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>  */}
                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                <a href="/our-completed-projects" style={{textDecoration:'none'}}>   <p className="fs-18 plus footerHover" style={{color:'rgba(255, 255, 255, 0.8)'}}>Our Projects</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>  */}
                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                <a href="Privacypolicy" style={{textDecoration:'none'}}>  <p className="fs-18 plus footerHover" style={{color:'rgba(255, 255, 255, 0.8)'}}>Privacy & Policy</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>  */}
                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                <a href="Termscondition" style={{textDecoration:'none'}}>  <p className="fs-18 plus footerHover" style={{color:'rgba(255, 255, 255, 0.8)'}}>Terms and Conditions</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div> */}
                                                </div>
                                        </div>
                                       
                                        <div className="col-lg-4">

                                             <div className="pl-30 pt-10" style={{paddingLeft:'50px'}} >

                                            <p className="fw-600 fs-20 plus" style={{color:'rgba(255, 255, 255, 1)'}}>Our Services</p>
                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                <a href="/services/digital-marketing" style={{textDecoration:'none'}}>   <p className="fs-18 plus footerHover" style={{color:'rgba(255, 255, 255, 0.8)'}}>Digital Marketing</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div> */}

                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                <a href="/services/graphic-desinging" style={{textDecoration:'none'}}>   <p className="fs-18 plus footerHover" style={{color:'rgba(255, 255, 255, 0.8)'}}>Graphic Designing</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div> */}

                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                <a href="/service/website-design-development-solutions" style={{textDecoration:'none'}}>   <p className="fs-18 plus footerHover" style={{color:'rgba(255, 255, 255, 0.8)'}}>Web Development</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div> */}
                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                <a href="/service/social-media-marketing" style={{textDecoration:'none'}}>   <p className="fs-18 plus footerHover" style={{color:'rgba(255, 255, 255, 0.8)'}}>Social Media Marketing</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>  */}
                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                <a href="/services/performance-marketing" style={{textDecoration:'none'}}>  <p className="fs-18 plus footerHover" style={{color:'rgba(255, 255, 255, 0.8)'}}>Performance Marketing</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>  */}
                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                <a href="/service/mobile-app-design-development-solutions" style={{textDecoration:'none'}}>   <p className="fs-18 plus footerHover" style={{color:'rgba(255, 255, 255, 0.8)'}}>Mobile App Development</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>  */}
                                            {/* <div className="d-flex" style={{gap:'8.43px'}}> */}
                                                <div>
                                                <a href="/service/search-engine-optimization-seo" style={{textDecoration:'none'}}>   <p className="fs-18 plus footerHover" style={{color:'rgba(255, 255, 255, 0.8)'}}>SEO  (Search Engine Optimization)</p></a>
                                                </div>
                                                {/* <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div> */}
                                             </div>
                                            
                                        </div>

                                        <div className="col-lg-2">

                                            
                                    <div className="pl-30 " >
                                        <div >
                                            <div >
                                                <p className="fs-18 fw-600 plus  pt-1 mb-0" style={{color:'rgba(255, 255, 255, 1)'}}>Follow us</p>
                                            </div>
                                            {/* <div>
                                                <img src={footerline} alt="" />
                                            </div> */}

                                            <div className="d-flex gap-2 pt-3">
                                            <div>
                                            <a href="https://in.linkedin.com/company/datametricks-consulting-pvt-ltd" style={{textDecoration:'none'}}> <img src={footerlinkedin} alt="" className="pt-1" /></a>
                                                </div>
                                                <div className="pt-1">
                                                <a href="https://in.linkedin.com/company/datametricks-consulting-pvt-ltd" style={{textDecoration:'none'}}> <p className="fs-16 plus" style={{color:'rgba(179, 179, 179, 1)'}} >LinkedIn</p></a>
                                                </div>
                                            </div>

                                            <div className="d-flex gap-2">
                                                <div>
                                                <a href="https://www.instagram.com/datametricks_pvt_ltd/" style={{textDecoration:'none'}}>   <img src={footerinsta} alt="" className="pt-1" /></a>
                                                </div>
                                                <div className="pt-1">
                                                <a href="https://www.instagram.com/datametricks_pvt_ltd/" style={{textDecoration:'none'}}> <p className="fs-16 plus" style={{color:'rgba(179, 179, 179, 1)'}}>Instagram</p></a>
                                                </div>
                                            </div>

                                            <div className="d-flex gap-2">
                                                <div>
                                                <a href="https://www.facebook.com/people/DataMetricks-Consulting-Pvt-Ltd/61554350043470/" style={{textDecoration:'none'}}>  <img src={footerfb} alt="" className="pt-1" /></a>
                                                </div>
                                                <div className="pt-1">
                                                <a href="https://www.facebook.com/people/DataMetricks-Consulting-Pvt-Ltd/61554350043470/" style={{textDecoration:'none'}}>  <p className="fs-16 plus" style={{color:'rgba(179, 179, 179, 1)'}}>Facebook</p></a>
                                                </div>
                                            </div>

                                            <div className="d-flex gap-2">
                                                <div>
                                                <a href="https://x.com/datametricks?t=tTJi9XotG-3d1NMQpxXhTQ&s=09" style={{textDecoration:'none'}}>  <img src={footertwitter} alt="" className="pt-1" /></a>
                                                </div>
                                                <div className="pt-1">
                                                <a href="https://x.com/datametricks?t=tTJi9XotG-3d1NMQpxXhTQ&s=09" style={{textDecoration:'none'}}>  <p className="fs-16 plus" style={{color:'rgba(179, 179, 179, 1)'}}>Twitter</p></a>
                                                </div>
                                            </div>
                                         


                                        </div>
                                    </div>

                                        </div>
                                 

                                    
                              
                            </div>
                            <div className="row mt-4 ">
                            <p style={{borderBottom:'1px solid rgba(52, 52, 52, 1)'}}></p>

                                <div className="col-lg-6 ">
                                    <p className="fs-16 plus textcenter " style={{color:'rgba(179, 179, 179, 1)'}}>© DataMetricks Consulting Pvt. Ltd. 2024</p>
                                    
                                </div>
                                <div className="col-lg-6  ">
                                    {/* <div className="d-flex  jcc gap-5" style={{justifyContent:'end'}}>
                                        <div>
                                        <a href="Termscondition" style={{textDecoration:'none'}}>  <p className="fs-16 " style={{color:'rgba(179, 179, 179, 1)'}}>Terms & Condition</p></a>
                                        </div>
                                        <div>
                                        <a href="Privacypolicy" style={{textDecoration:'none'}}>  <p className="fs-16 " style={{color:'rgba(179, 179, 179, 1)'}}>Privacy Policy</p></a>
                                        </div>
                                    </div> */}
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section >

    <section className="d-block d-sm-block d-md-none d-lg-none">
    <div class="footer-buttons">
        <a href="tel:+917303946657" class="button call-button" style={{textDecoration:'none',}}>Call Us</a>
        <a href="https://wa.me/917303946657" class="button whatsapp-button" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}>WhatsApp</a>
    </div>

    </section>
               
            </div>

    );
}
export default Footer;



