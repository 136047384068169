import React from "react";
import Slider from "react-slick";
import "./CustomSlider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CustomSlider = ({slides}) => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomArrow type="next" />,
    prevArrow: <CustomArrow type="prev" />,
    responsive: [
        {
          breakpoint: 768, 
          settings: {
            dots: true, 
        
          },
        },
      ],
  };


  return (
    <div className="plr-10">
    <div className=" custom-slider-container-new">
      <Slider {...settings}>
        {slides.map((slide) => (
          <div key={slide.id} className="slide-new">
            <div className="slide-content-new">
                <a href={slide.url1} style={{textDecoration:'none',border:'none',boxShadow:'none'}}>
              <div className="slide-image-new">
                <img src={slide.image} alt={slide.title} />
              </div>
              </a>
              <div className="slide-text-new">
                <h2 className="slide-title-new">{slide.title}</h2>
                <p className="slide-description-new ">{slide.description}</p>
                <div className="d-flex gap-4">
                  <div>
                  <p className="slide-description-new-review mb-0 ">{slide.review}</p>
                  <p className="slide-description-new-review1">{slide.review1}</p>
                  </div>
                  <div>
                    <img src={slide.image1} alt="" />
                  </div>
                  <div>
                  <p className="slide-description-new-review mb-0 ">{slide.review2}</p>
                  <p className="slide-description-new-review1">{slide.review3}</p>
                  </div>
                </div>
           


              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
    </div>
  );
};

const CustomArrow = ({ type, onClick }) => {
  return (
    <div
      className={`custom-arrow ${type === "next" ? "arrow-next" : "arrow-prev"}`}
      onClick={onClick}
    >
      {type === "next" ? "→" : "←"}
    </div>
  );
};

export default CustomSlider;
