import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2"; // Import the library
import "react-phone-input-2/lib/style.css"; 

const NewForm = ()=>{


    // ================ Mailer code start from here =====================


    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        phone: "",
      });
      const [responseMessage, setResponseMessage] = useState(""); 
      const [loading, setLoading] = useState(false); // State for loader
      const navigate = useNavigate(); // Initialize useNavigate
      const [errors, setErrors] = useState({}); 
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
      const handlePhoneChange = (phone) => {
        setFormData({
          ...formData,
          phone, // Update phone field with the selected country code and number
        });
      };
     
      const validateForm = () => {
        let formErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10}$/; 
    
        if (!formData.email || !emailRegex.test(formData.email)) {
          formErrors.email = "Please enter a valid email address.";
        }
    
        // if (!formData.phone || !phoneRegex.test(formData.phone)) {
        //   formErrors.phone = "Please enter a valid 10-digit phone number.";
        // }
        if (!formData.phone || formData.phone.length < 10) {
            formErrors.phone = "Please enter a valid phone number.";
          }
      
    
        if (!formData.message || formData.message.trim().length < 10) {
            formErrors.message = "Please enter a message with at least 10 characters.";
          }
    
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
          return;
        }
        setLoading(true); // Show loader
    
        try {
          const response = await fetch(
            "https://mailer.datametricks.com/send-email.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            }
          );
    
          const result = await response.json(); // Parse JSON from the response
    
          setResponseMessage(result.message);
    
          if (result.status === "success") {
            setTimeout(() => {
              setLoading(false); // Hide loader
              navigate("/Successemailpage"); // Redirect to Thank You page
            }, 1000); // Delay redirect by 2 seconds
          } else {
            setLoading(false); // Hide loader if not successful
          }
        } catch (error) {
          setResponseMessage("Error submitting form.");
          setLoading(false); // Hide loader on error
        }
      };
    
      // ================ Mailer code end here =====================

    return(

      <div>
        <section>
        <div className=" py-4" style={{backgroundColor:'rgba(242, 242, 242, 1)',position:'relative'}}>
        <div style={{position:'absolute', right:'7%',bottom:'87%'}}>
            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/response-img.svg" alt="" className="img-fluid" />
        </div>
        <div className="container">
            <form onSubmit={handleSubmit}>
           

            <div className="row justify-content-center align-items-center">
                <div className="col-lg-5 ">
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/men-formimg.svg" alt="" className="img-fluid "  />
                </div>
                <div className="col-lg-7 ">
                     <p className="fw-800  fs-30 plus " >What can we help you with?</p>

                    <div className="row  pt-1">
                        <div className="col-md-6 ">
                              <input type="text"  id="fname"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                               placeholder="Full Name *" required className="fs-13 plus px-3 w-100" style={{color:'#000000',height:'51px',borderRadius:'7px',border:'none',outline:'none'}} />

                        </div>
                        <div className="col-md-6">
                              <input type="email"
                                id="email" name="email"  value={formData.email}
                                onChange={handleChange}
                              placeholder="Email *" required className="fs-13 plus px-3 w-100" style={{color:'#000000',height:'51px',borderRadius:'7px',border:'none',outline:'none'}} />
                {errors.email && <p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "5px" }}>{errors.email}</p>}

                        </div>
                    </div>
                    <div className="row  mt-4 ">
                        {/* <div className="col-md-6 ">
        
                               <input type="tel" 
                               id="phone" 
                               name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                               placeholder="Phone Number" required className="fs-13 plus px-3 w-100" style={{color:'#000000',height:'51px',borderRadius:'7px',border:'none',outline:'none'}} /> 
                    {errors.phone && <p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "10px" }}>{errors.phone}</p>}


                        </div> */}

                 {/* ============================== */}
                        <div className="col-md-6">
                      <PhoneInput
                        country={"us"} // Default country
                        value={formData.phone}
                        onChange={handlePhoneChange}
                        inputStyle={{
                          width: "100%",
                          height: "51px",
                          borderRadius: "7px",
                          border: "none",
                          outline: "none",
                          boxShadow:'none',
                          color: "#000",
                        }}
                      />
                      {errors.phone && (
                        <p
                          className="error-text"
                          style={{
                            fontSize: "12px",
                            color: "red",
                            marginTop: "10px",
                          }}
                        >
                          {errors.phone}
                        </p>
                      )}
                    </div>
    

                        {/* ====================================== */}
                        <div className="col-md-6">
                              <input type="text" 
                                name="message"  value={formData.message}
                                onChange={handleChange}
                                form="usrform"
                              placeholder="Company" required className="fs-13 plus px-3 w-100" style={{color:'#000000',height:'51px',borderRadius:'7px',border:'none',outline:'none'}} />
                    {errors.message && (<p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "5px" }} > {errors.message} </p>)}
                        </div>
                    </div>

                    <div className="row justify-content-center mt-4">
                        <div className="col-lg-12 ">
                              <textarea
                             
                              placeholder="Your Message / Requirements "  className="fs-13 plus px-3 py-3 w-100" style={{color:'#000000',height:'115px',borderRadius:'7px',border:'none',outline:'none', maxHeight: '115px',}}></textarea>
                              {/* <p className=" text-white pt-3 fw-700 fs-13 plus">
                                 <span type="file" className=" text-white fw-700 fs-13 plus">📎 Select files from your </span>
                                  <span className="  fw-700 fs-13 plus" style={{color:'#ABD2FF'}}>Computer</span>, <span className=" fw-700 fs-13 plus" style={{color:'#ABD2FF'}}>Google Docs</span> or <span className=" fw-700 fs-13 plus" style={{color:'#ABD2FF'}}>Dropbox URLs</span>
                            </p> */}
                        </div>
              
                    </div>
                  

                  <div className="d-flex gap-4 mt-4">

                
                    <div >

                        <button type="submit" className="btn btn-sm  mont" disabled={loading} style={{fontSize:'16px',fontWeight:'700',backgroundColor:'rgba(0, 83, 154, 1)',color:'white',borderRadius:'5px',border:'none',outline:'none',padding:'12px 60px 12px 60px'}}>
                        {loading ? (
                                    <>
            
                         <div className="spinner"></div>
                
                              </>
                          ) : (
                                "Send Your Query"
                              )}

                         </button>
                    </div>


            <div className="d-flex justify-content-center gap-4 ">

<div className="d-flex  gap-2">
    <div>
          <img src= "https://d3vqz9qyn2r1xe.cloudfront.net/imges/green-icon-form.svg" alt="" className="pt-1" />
    </div>
    <div>
           <p className="fw-600 plus fs-19  pt-1">100% Confidential</p>
    </div>
</div>

<div className="d-flex  gap-2">
    <div>
          <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/green-icon-form.svg" alt="" className="pt-1" />
    </div>
    <div>
           <p className="fw-600 plus fs-19  pt-1">We sign NDA</p>
    </div>
</div>

</div>
                    </div>


    <div className="d-flex gap-4 mt-4">
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon1.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon2.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon3.svg" alt="" className="img-fluid pt-3" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon4.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon5.svg" alt="" className="img-fluid" />
            </div>
            <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/formicon6.svg" alt="" className="img-fluid pt-4" />
            </div>
    </div>

                </div>
            </div>

         

        
    </form>
        </div>

    </div>
        </section>
      </div>
       
    )
}
export default NewForm;