import Footer from "../Components/footer";
import Header from "../Components/Header";
// import Testimonial1 from "../Components/Testimonial1";
import Bigcontact from '../Components/Bigcontact';
import Modal from 'react-bootstrap/Modal';
import React, { useState} from 'react';
import CompanyLogoSlider from "../Components/CompanyLogoSlider";
import { useNavigate } from "react-router-dom";
import GoogleReviewsWidget from "../Components/GoogleReviewsWidget";
import Form from "../Components/Form";
import { FaWhatsapp } from "react-icons/fa";

const LandingPage = () =>  {

    // ================ Mailer code start from here =====================
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        phone: "",
      });
      const [responseMessage, setResponseMessage] = useState(""); 
      const [loading, setLoading] = useState(false); // State for loader
      const navigate = useNavigate(); // Initialize useNavigate
      const [errors, setErrors] = useState({}); 
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
     
      const validateForm = () => {
        let formErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10}$/; // Assumes phone number should be 10 digits
    
        if (!formData.email || !emailRegex.test(formData.email)) {
          formErrors.email = "Please enter a valid email address.";
        }
    
        if (!formData.phone || !phoneRegex.test(formData.phone)) {
          formErrors.phone = "Please enter a valid 10-digit phone number.";
        }
    
        if (!formData.message || formData.message.trim().length < 10) {
            formErrors.message = "Please enter a message with at least 10 characters.";
          }
    
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
          return;
        }
        setLoading(true); // Show loader
    
        try {
          const response = await fetch(
            "https://mailer.datametricks.com/send-email.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            }
          );
    
          const result = await response.json(); // Parse JSON from the response
    
          setResponseMessage(result.message);
    
          if (result.status === "success") {
            setTimeout(() => {
              setLoading(false); // Hide loader
              navigate("/Successemailpage"); // Redirect to Thank You page
            }, 1000); // Delay redirect by 2 seconds
          } else {
            setLoading(false); // Hide loader if not successful
          }
        } catch (error) {
          setResponseMessage("Error submitting form.");
          setLoading(false); // Hide loader on error
        }
      };
    
      // ================ Mailer code end here =====================

    

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const sec5dot1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot1.png";
const sec5dot2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot2.png";
const sec5dot3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot3.png";
const sec5dot4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot4.png";
const stagesbg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/stagesbg.png";
// const custompageimg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/custompageimg.svg";
const custompageimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Tech-desktopview.svg";
const custompageimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Tech-mobileview.svg";
const newhomeimg12 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newhome-img12.svg";
const newhomeimg13 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/proimg1.svg";
const newhomeimg14 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newhome-img14.svg";
const newhomeimg15 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/proimg2.svg";
const ratingstar = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/ratingstar.svg";


return(

    <div>
        <section>
                <Header/>
        </section>


<section style={{backgroundColor:'rgba(0, 83, 154, 1)',marginTop:'70px'}}>

    <div className="container pt-lg-5 pt-20 pb-5 ">
      <div className="row justify-content-center  align-items-end">

            <div className="col-lg-7 col-12 col-md-6 col-sm-12">
                <p className="fw-800 fs-45 plus text-white mb-0 font-23 d-lg-block d-md-block d-sm-none d-none fontsize">Revamp Your Digital Presence.Get </p>
                <p className="fw-800 fs-45 plus text-white mb-0 font-23 d-lg-block d-md-block d-sm-none d-none fontsize">Expert Web Developers Today!</p>
                <p className="fw-800 fs-38 plus text-white mb-0 font-20 d-lg-none d-md-none d-sm-block d-block ">Revamp Your Digital Presence.Get Expert Web Developers Today! </p>

                <p className=" fs-22 plus font-18  pt-10" style={{color:'rgba(171, 210, 255, 1)'}}>Drive innovation with our advanced IT solutions.</p>

                <div className="d-flex gap-lg-4 mt-4 gap15">
                    <div>
                         <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon1.svg" alt="done-icon"  />
                    </div>
                    <div>
                        <p className="plus fw-500 fs-18 mb-0 font-18" style={{color:'rgba(255, 255, 255, 1)'}}>Hire the Top 1% of Web Development Talent</p>
                    </div>
                   
                </div>

                <div className="d-flex gap-lg-4 gap15 pt-2">
                    <div>
                         <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon1.svg" alt="done-icon"  />
                    </div>
                    <div>
                        <p className="plus fw-500 fs-18 mb-0 font-18" style={{color:'rgba(255, 255, 255, 1)'}}>12+ Years of Experience, 50+ Skilled Professionals</p>
                    </div>
                   
                </div>

                <div className="d-flex gap-lg-4 gap15 pt-2">
                    <div>
                         <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon1.svg" alt="done-icon"  />
                    </div>
                    <div>
                        <p className="plus fw-500 fs-18 mb-0 font-18" style={{color:'rgba(255, 255, 255, 1)'}}>Save Up to 50% on Development Costs</p>
                    </div>
                   
                </div>

                <div className="d-flex gap-lg-4 gap15 pt-2">
                    <div>
                         <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon1.svg" alt="done-icon"  />
                    </div>
                    <div>
                        <p className="plus fw-500 fs-18 mb-0 font-18" style={{color:'rgba(255, 255, 255, 1)'}}>On Time Delivery Guaranteed </p>
                    </div>
                   
                </div>

                <div className="d-flex gap-lg-4 gap15 pt-2">
                    <div>
                         <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon1.svg" alt="done-icon"  />
                    </div>
                    <div>
                        <p className="plus fw-500 fs-18 font-18 " style={{color:'rgba(255, 255, 255, 1)'}}>Competitive Rates for Excellent Service</p>
                    </div>
                   
                </div>

                 <div className="mt-3 d-flex gap-0 ">
                    <div>
                    <p className="fs-21 fw-600 plus mb-0 font-18 d-lg-block d-md-block d-sm-none d-none" style={{color:'rgba(171, 210, 255, 1)'}}>I’d like to learn more about your</p>
                    <p className="fs-21 fw-600 plus mb-0 font-18 d-lg-block d-md-block d-sm-none d-none" style={{color:'rgba(171, 210, 255, 1)'}}>Web development services</p>
                    <p className="fs-21 fw-600 plus font-18 d-lg-none d-md-none d-sm-block d-block" style={{color:'rgba(171, 210, 255, 1)'}}>I’d like to learn more about your Web development services</p>

                  <a href="https://outlook.office365.com/owa/calendar/MihirProjectLeadatDataMetricks@datametricks.com/bookings/">
                    <button type="button" className="btn btn-sm py-3 px-4 mt-lg-3 font-14" style={{fontSize:'17px',fontWeight:'600',backgroundColor:'#E77F00',color:'white',borderRadius:'5px',border:'none',outline:'none'}}>
                         Book a Free Consultation Call
                    </button>
                </a>
                    <Modal show={show} onHide={handleClose} className='modal1'   >
                         <Modal.Header closeButton className='modelheader1' ></Modal.Header>
                              <Modal.Body className='modelbody1'>  
                                    <Bigcontact/>
                        </Modal.Body>
       
                     </Modal>
                    </div>
                    <div>
                    {/* <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-arrow1.svg" alt="done-icon" className="pt-4 " /> */}

                    </div>
                </div> 
            


                {/* <p className="fw-500 fs-12 plus mt-2 ml-0" style={{color:'#FFFFFF',borderBottom:'0.5px solid #FFFFFF ', display: 'inline-block',marginLeft:'23px'}}>Or, Use this form to tell us about your needs</p> */}

            </div>

            <div className="col-lg-4 col-12 col-md-6  ">
                <Form/>
                {/* <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpimg1.png" alt="bg-image"  style={{height:'600px',width:'100%',marginBottom: '-48px'}} /> */}
            </div>
          
        </div>
    </div>

</section>

<section style={{backgroundColor:'#F6FBFF'}}>
    <div className="container pt-4 pb-5 ">
        <p className="fw-800 fs-33 plus text-center mb-0 font-23" style={{color:'#1B3567'}}>Noida's Highest Rated Web Development Company</p>
        <p className="fw-500 fs-20 plus text-center pt-2 font-18" style={{color:'#1B3567'}}> 100+ Glowing 5-Star Reviews Across Top B2B Review Platforms</p>
        <div className="row justify-content-center mt-4 g-2 p-10" >

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img1.svg" alt="box-img" style={{height:'40px',width:'181px',marginTop:'25px'}} className="img-fluid heightwidth"  />
                    <img src={ratingstar} alt="" className="mt-lg-5 mt-10 " />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                                  

                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-lg-2" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img2.svg" alt="box-img" style={{height:'113px',width:'90px'}} className="img-fluid heightwidth1" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.9</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img3.svg" alt="box-img" style={{height:'113px',width:'120px'}} className="img-fluid heightwidth2" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img4.svg" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid heightwidth2" />
                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6 text-center">
                <div className="p-2" style={{border:'1px solid #C7D6E3',borderRadius:'9px',backgroundColor:'#FFFFFF'}}>
                    {/* <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-img5.svg" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid" /> */}
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/googleicon.png" alt="box-img" style={{height:'113px',width:'119px'}} className="img-fluid heightwidth3" />

                    <img src={ratingstar} alt=""  />
                    <p className="fw-700 fs-32 mont font-23" >4.8</p>
                </div>
            </div>

          
        </div>
    </div>

</section>

<section>
    <div className="container mt-4">
        <p className="fw-800 plus fs-33 text-center mb-0 d-none d-sm-none d-md-block d-lg-block" style={{color:'#1B3567'}}>Transform Your Business with Next-Generation Software</p>
        <p className="fw-500 fs-20 plus text-center pt-2 d-none d-sm-none d-md-block d-lg-block" style={{color:'#1B3567'}}>Include our Web Development Services</p>
        <p className="fw-800 plus fs-23 text-center  d-block d-sm-block d-md-none d-lg-none " style={{color:'#1B3567'}}>Transform Your Business with Next-Generation Software</p>
        <p className="fw-700 plus fs-16 text-center mb-0 d-block d-sm-block d-md-none d-lg-none " style={{color:'#1B3567'}}>  Include our Web Development Services</p>

        <div className="row justify-content-center mt-lg-4 plr-20 mt-10 ">

            <div className="col-lg-3 col-12 col-md-3 ">
                <div className="p-3" style={{borderTopRightRadius:'6px',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
                    <div className="d-flex  justify-content-between">
                        <div>
                          <p className="fw-600 pop fs-18 pt-lg-2 " style={{color:'#1B3567'}}>Custom Software Development Solutions</p>
                        </div>
                        {/* <div>
                                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Add-icon.svg" alt="" className="pt-4" />
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-12 col-md-3 pt-10">
<div className="p-3" style={{borderTopRightRadius:'6px',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
    <div className="d-flex  justify-content-between">
        <div>
          <p className="fw-600 pop fs-18 pt-lg-2" style={{color:'#1B3567'}}>Custom Website Development Solutions</p>
        </div>
        {/* <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Add-icon.svg" alt="" className="pt-4" />
        </div> */}
    </div>
</div>
</div>

<div className="col-lg-3 col-12 col-md-3 pt-10">

<div className="p-3" style={{borderTopRightRadius:'6px',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
    <div className="d-flex  justify-content-between">
        <div>
          <p className="fw-600 pop fs-18 pt-lg-2" style={{color:'#1B3567'}}>e-Commerce Website Development Solutions</p>
        </div>
        {/* <div>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Add-icon.svg" alt="" className="pt-4" />
        </div> */}
    </div>
</div>
</div>
        </div>

        <div className="row justify-content-center mt-lg-4 plr-20 pt-10">

<div className="col-lg-3 col-12 col-md-3 ">
    <div className="p-3" style={{borderTopRightRadius:'6px',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
        <div className="d-flex  justify-content-between">
            <div>
              <p className="fw-600 pop fs-18 pt-lg-2" style={{color:'#1B3567'}}>CMS Website Development Solutions</p>
            </div>
            {/* <div>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Add-icon.svg" alt="" className="pt-4" />
            </div> */}
        </div>
    </div>
</div>

<div className="col-lg-3 col-12 col-md-3 pt-10">
<div className="p-3" style={{borderTopRightRadius:'6px',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
<div className="d-flex  justify-content-between">
<div>
<p className="fw-600 pop fs-18 pt-lg-2" style={{color:'#1B3567'}}>Mobile Apps Development Solutions</p>
</div>
{/* <div>
    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Add-icon.svg" alt="" className="pt-4" />
</div> */}
</div>
</div>
</div>

<div className="col-lg-3 col-12 col-md-3 pt-10">

<div className="p-3" style={{borderTopRightRadius:'6px',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
<div className="d-flex  justify-content-between">
<div>
<p className="fw-600 pop fs-18 pt-lg-2" style={{color:'#1B3567'}}>IT Staff Augmentation<br/>Solutions</p>
</div>
{/* <div>
    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Add-icon.svg" alt="" className="pt-4" />
</div> */}
</div>
</div>
</div>
</div>

<div className="row justify-content-center mt-lg-4 plr-20 pt-10">

<div className="col-lg-3 col-12 col-md-3 ">
    <div className="p-3" style={{borderTopRightRadius:'6px',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
        <div className="d-flex  justify-content-between">
            <div>
              <p className="fw-600 pop fs-18 pt-lg-2" style={{color:'#1B3567'}}>Cloud Application Development Solutions</p>
            </div>
            {/* <div>
                    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Add-icon.svg" alt="" className="pt-4" />
            </div> */}
        </div>
    </div>
</div>

<div className="col-lg-3 col-12 col-md-3 pt-10">
<div className="p-3" style={{borderTopRightRadius:'6px',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
<div className="d-flex  justify-content-between">
<div>
<p className="fw-600 pop fs-18 pt-lg-2" style={{color:'#1B3567'}}>Enterprise Website Development Solutions</p>
</div>
{/* <div>
    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Add-icon.svg" alt="" className="pt-4" />
</div> */}
</div>
</div>
</div>

<div className="col-lg-3 col-12 col-md-3 pt-10">

<div className="p-3" style={{borderTopRightRadius:'6px',borderTopLeftRadius:'6px',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px', borderStyle: 'solid',borderWidth: '1px 1px 4px 1px',borderColor: '#DEE2E6',}}>
<div className="d-flex  justify-content-between">
<div>
<p className="fw-600 pop fs-18 pt-lg-2" style={{color:'#1B3567'}}>Software Development Outsourcing Solutions</p>
</div>
{/* <div>
    <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Add-icon.svg" alt="" className="pt-4" />
</div> */}
</div>
</div>
</div>
</div>

    </div>
</section>


<section className="text-center">

    <a href="https://wa.me/917303946657" alt="" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}} >
    <div className="mt-lg-5 mt-10 whatsapp-container1">
            <button type="button" className="btn btn-sm py-2 px-4 mt-2 mont whatsapp-button1" style={{fontSize:'16px',fontWeight:'700',backgroundColor:'#00539a',color:'white',borderRadius:'5px'}}>
                <div className="d-flex gap-3">
                    <div >
                     <FaWhatsapp size={24} className="" />

                    </div>
                    <div>
                       WhatsApp

                    </div>
                </div>
            </button>



    </div></a>

    {/* <p className="fw-500 fs-14 mont mt-2 " style={{color:'#0C2848',borderBottom:'0.5px solid #0C2848',display:'inline-block'}}>Or, Use this form to tell us about your needs</p> */}

</section>





        <section className="mt-lg-4 mt-10 ">
               <div className="px-lg-5 plr-10">
                    <p className="fs-33 fw-700 plus text-center mb-0 d-sm-none d-none d-md-block d-lg-block" style={{color:'rgba(27, 53, 103, 1)'}}>We deliver IT services, including managed support, cybersecurity solutions</p>
                    <p className="fs-33 fw-700 plus text-center d-sm-none d-none d-md-block d-lg-block" style={{color:'rgba(27, 53, 103, 1)'}}>and digital transformation strategies to optimize performance and drive business growth.</p>
                    <p className="fs-20 fw-700 plus text-center mb-0 d-sm-block d-block d-md-none d-lg-none" style={{color:'rgba(27, 53, 103, 1)'}}>We deliver IT services, including managed support, cybersecurity solutions and digital transformation strategies to optimize performance and drive business growth.</p>

               </div>
               <div className="container mt-lg-4 mt-30 ">
                <div className="row justify-content-center">
                    <div className="col-lg-11">

                  
                    {/* <img src={custompageimg} alt="" className="img-fluid" /> */}
                    <img src={custompageimg1} alt="" className="img-fluid d-sm-none d-none d-lg-block d-md-block" />
                    <img src={custompageimg2} alt="" className="img-fluid d-sm-block d-block d-lg-none d-md-none" />

                    </div>
                    </div>
               </div>
              
            </section>




            <section>
                <div className="container mt-lg-4 mt-20">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-12 col-md-6">
                        <div className="text-center">
                                  <p className="fw-800 fs-33 plus font-23 " style={{color:'#1B3567'}}>What  We Believe in</p>
                                <p className="fw-500 fs-18 plus px-lg-5 font-17" style={{color:'#000000',lineHeight:'30px'}}>We Committed To Delivering High-Quality Projects With a Strong Focus On On-Time Delivery. Our Team Prioritizes Efficient Workflows, Clear Communication, And Tailored Solutions To Ensure Every Project Is Completed Within The Agreed Timeline, Meeting And Exceeding Client Expectations</p>
                        </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-4">
                    <div className="row g-2">
                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 ">
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon2.svg" alt="" />
                            <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'#00539A'}}>Security</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'#7C7C7C'}}>Our aim is to bring a new approach to secure your software based on integrity, trust and transparency</p>

                        </div>
                        </div>

                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 ">
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon3.svg" alt="" />
                            <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'#00539A'}}>Support</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'#7C7C7C'}}>The best gift is time, and our 24/7 dedicated support team is always available to address your queries and concerns.</p>

                        </div>
                        </div>

                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 ">
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon4.svg" alt="" />
                            <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'#00539A'}}>Integrity</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'#7C7C7C'}}>Our expert team offers the best advice tailored to your product. We ensure guidance that drives success.</p>

                        </div>
                        </div>

                        <div className="col-lg-3 col-12 col-md-6 textcenter">
                        <div className="box-shadow1 ">
                            <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-icon5.svg" alt="" />
                            <p className="fw-800 fs-17 plus mb-0 mt-4" style={{color:'#00539A'}}>Transparency</p>
                            <p className="fw-500 plus fs-14 mt-3" style={{color:'#7C7C7C'}}>Transparency means embracing new ideas, technology, and approaches. We firmly believe in fostering learning and openness.</p>

                        </div>
                        </div>
                    </div>
                </div>
            </section>


<section className="mt-5">
    <div className="p-3" style={{backgroundColor:'black'}}>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-3 d-flex align-items-center d-sm-none d-none d-md-block d-lg-block">
                    <p className="fs-35 fw-700 plus pt-1" style={{color:'rgba(255, 255, 255, 1)'}}>Our Clients</p>
                </div>
                <div className="col-lg-8">
                    <CompanyLogoSlider/>
                </div>
            </div>
        </div>

    </div>

</section>



<section>
<section>
       <div className='container textcenter text-center  mt-lg-5 mt-30'>
            <p className='fw-600 fs-35 plus mb-0 font-23'>Our Success Stories</p>
            <p className='fw-600 fs-35 plus font-23 '>Transformative Projects and <span style={{color:'rgba(0, 83, 154, 1)'}}>Case Studies </span></p>
       </div>
    </section>

    
 <section>
      <div className='p-4  p-20 ' style={{backgroundColor:'white'}}>
       <div className='container  '>
           <div className='row justify-content-center'>
            <div className='col-lg-4'>
          <a href='/our-completed-projects/lookplex'><div className='hovercontainer ' style={{border:'1px solid #ff0066',borderRadius:'12px'}}>
                <img src={newhomeimg12} alt='' className='img-fluid image123  ' />
                <div class="overlay123">
                     <div class="text123">Salon at Home Services Website</div>
                     <div class="text1234">For a salon at home services provider, we designed a sleek and modern website with an easy
                          booking feature. The site allows customers to book appointments effortlessly and explore various
                            beauty services offered.</div>
                 </div>
               </div></a>
            </div>
            <div className='col-lg-4'>
            <a href='/our-completed-projects/smartcare'> <div className='hovercontainer  ' style={{border:'1px solid blue',borderRadius:'9px'}}>
                <img src={newhomeimg13} alt='' className='img-fluid pt-20 image123  ' />
                <div class="overlay123">
                     <div class="text123">Smartcare</div>
                     <div class="text1234">We created a healthcare platform, SmartCare, that enables patients to easily book appointments, find doctors, store medical records, and save vital health information, streamlining their healthcare experience.</div>
                 </div>
               </div></a>

            </div>
           </div>
           <div className='row justify-content-center mt-4'>
            <div className='col-lg-4'>
          <a href='/our-completed-projects/monovouge'>  <div className='hovercontainer  ' style={{border:'1px solid  #009999',borderRadius:'13px'}}>
                <img src={newhomeimg14} alt='' className='img-fluid mt0 image123  ' />
                <div class="overlay123">
                     <div class="text123 "> E-commerce Platform</div>
                     <div class="text1234">We developed a dynamic and visually appealing e-commerce platform that enhances the
shopping experience. The site includes secure payment gateways, intuitive navigation, and a
responsive design for seamless browsing on all devices.</div>
                 </div>
               </div></a>

            </div>
            <div className='col-lg-4 hidepara'>
            <a href='/our-completed-projects/healthomax-website'> <div className='hovercontainer' style={{border:'1px solid  black',borderRadius:'9px'}}>
                <img src={newhomeimg15} alt='' className='img-fluid mt0 image123  ' />
                <div class="overlay123">
                     <div class="text123"> Healthomax</div>
                     <div class="text1234">We developed the HealthOmax mobile application for both Android and iOS platforms. The app offers the same features as the website, allowing medical professionals to connect and collaborate, and patients to interact with doctors on the go.</div>
                 </div>
               </div></a>

            </div>
           </div> 
       </div>
       </div>
    </section>  
</section>


<section>

<section className='d-none d-sm-none d-lg-block d-md-block'>

<div className="mt-4 ">
        <p className="fs-35 fw-700 plus text-center mb-0 font-20">Our Streamlined Web Development</p>
        <p className="fs-35 fw-700 plus text-center font-20"> Process from Concept to Launch</p>

 </div>
 <div className="container mt-lg-5 ">
<div className="row ms-lg-5">
  <div className="col-lg-4 ">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt=""  />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">
          1. Discovery and Planning
        </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
         Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot2} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">2. Design</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Bringing your vision to life with captivating visuals and
          intuitive user interfaces.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot1} alt="" />
      </div>
    
      <div>
        <p className="sec5title mb-0 mt-1">3. Development</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Transforming designs into functional <br /> websites with
          clean code and seamless functionality.
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<div className="container">

<div className="col-lg-12 ms-lg-5">
<img className="img-fluid" src={stagesbg} alt="" />
</div>
</div>
<div className="container pt-4 ">
<div className="row ms-lg-5">
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">4. Testing </p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Thoroughly checking every aspect of the website to ensure
          it meets quality standards and performs flawlessly.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot4} alt="" />
      </div>
     
      <div>
        <p className="sec5title mb-0 mt-1">5. Launch</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Deploying the website to the live <br /> environment and
          making it accessible to your audience.
        </p>
      </div>
    </div>
  </div>
  <div className="col-lg-4">
    <div className="d-flex  gap-2">
      <div>
        <img src={sec5dot3} alt="" />
      </div>
      <div>
        <p className="sec5title mb-0 mt-1">6. Beyond</p>
        <p
          style={{ paddingLeft: "17px !important" }}
          className="para1"
        >
          Providing ongoing support, maintenance, and updates to
          ensure your website remains optimized and up to date.
        </p>
      </div>
    </div>
  </div>
</div>



</div>
    
</section>

{/* mobile view */}
<section className='d-block d-sm-block d-lg-none d-md-none'>
  <div className='container mt-4 '>
  <p className="fs-35 fw-700 plus text-center mb-0 font-20">Our Streamlined Web Development</p>
  <p className="fs-35 fw-700 plus text-center font-20"> Process from Concept to Launch</p>
    <div className='row justify-content-center'>
      {/* <div className='col-3'>
         <img style={{height:'532px'}} src="/assets/" alt="" className="img-fluid" />
      </div> */}
      <div className='col-10 mt-2'>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot1} alt=""  />
          </div>
          <div>
            <p className="sec5title fs-15 mb-0 mt-1">
              1. Discovery and Planning
            </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12 "
            >
            Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot2} alt="" />
          </div>
        
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">2. Design</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Bringing your vision to life with captivating visuals and
              intuitive user interfaces.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot1} alt="" />
          </div>
        

          <div>
            <p className="sec5title mb-0 mt-1 fs-15">3. Development </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Transforming designs into functional <br /> websites with
              clean code and seamless functionality.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot3} alt="" />
          </div>
       
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">4. Testing </p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Thoroughly checking every aspect of the website to ensure
              it meets quality standards and performs flawlessly.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot4} alt="" />
          </div>
       
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">5. Launch</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Deploying the website to the live <br /> environment and
              making it accessible to your audience.
            </p>
          </div>
        </div>
        <div className="d-flex  gap-2">
          <div>
            <img src={sec5dot3} alt="" />
          </div>
          <div>
            <p className="sec5title mb-0 mt-1 fs-15">6. Beyond</p>
            <p
              style={{ paddingLeft: "17px !important" }}
              className="para1 fs-12"
            >
              Providing ongoing support, maintenance, and updates to
              ensure your website remains optimized and up to date.
            </p>
          </div>
        </div>
      </div>
    </div>
    

  </div>
</section>

</section>

<section style={{backgroundColor:'#F6FBFF',overflow:'hidden'}}>
    <div className="container mt-lg-5 py-4">
        <div className="row align-items-center g-5">
            <div className="col-lg-5 col-12 ">
                <p className="fw-800 fs-38 plus mb-0 font-23 mx-3" style={{color:'#1B3567'}}>Why Choose Datametricks?</p>
                <p className="fw-600 fs-23 plus pt-2 font-17 mx-3" style={{color:'#1B3567'}}>Vision + Hardwork + Consistency</p>
                <p className="fs-17 plus fw-400 font-18 mx-3 mb-0" style={{color:'#000000',lineHeight:'28px'}}>We Consider Every Aspect That Matters To Clients' Data Security And Intellectual Property Protections. We Believe Transparency Is Needed To Create Trust And Is An Integral Part Of DataMetricks Culture. It Gives Us Confidence To Provide Visibility Regarding The Development Process To Clients. As Your Technology Partner, We Deliver The Right Combination Of Technologies For Your Needs And Excellent Results.</p>
            </div>
            <div className="col-lg-7 col-12">
                <div className="row ">
                    <div className="col-md-3 col-6 text-center">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon1.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>Dedicated Resource Allocation</p>
                    </div>

                    <div className="col-md-3 col-6 text-center">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon2.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>Different Business Models</p>
                    </div>

                    <div className="col-md-3 col-6 text-center">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon3.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>Technology Experts on Board</p>
                    </div>

                    <div className="col-md-3 col-6 text-center">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon4.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>Agile Development’s Quick Deployment</p>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-3 col-6 text-center">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon5.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>Value of your Investment</p>
                    </div>

                    <div className="col-md-3 col-6 text-center">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon6.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>Competitive Rates for Excellent Services</p>
                    </div>

                    <div className="col-md-3 col-6 text-center">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon7.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>NDA Ownership Transfer</p>
                    </div>

                    <div className="col-md-3 col-6 text-center">
                        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lpicon8.svg" alt="" />
                        <p className="fs-14 fw-600 plus pt-3" style={{color:'#00539A',lineHeight:'19px'}}>Complete End  to End  Solutions</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>


<section className="d-sm-none d-none d-md-block d-lg-block">
    <div className="container mt-4">
        <div className="row justify-content-center">
            <div className="col-lg-11">
               <div style={{position:'relative'}}>
                <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/LP-BG-IMG-2.svg" className="img-fluid" alt="" />
                <div style={{position:'absolute',top:'39%',left:'5%'}} >
                     <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/limitedtimeoffer.svg" className="img-fluid" alt=""/>
                     <p className="fw-600 fs-33 pop mb-0 font-23" style={{color:'#1B3567'}}>Take the First Step</p>
                     <p className="fw-500 fs-22 mont pt-2 font-18" style={{color:'#1B3567',lineHeight:'32px'}}>Begin your no-commitment, <span className="fw-800 fs-22 mont font-18" style={{color:'#1B3567',borderBottom:'1px solid #1B3567'}}>Get a Free Quote</span></p>
                     <button type="button" className="btn btn-sm py-2 px-4 mt-2 mont d-md-block d-lg-block d-sm-none d-none" onClick={handleShow} style={{fontSize:'16px',fontWeight:'700',backgroundColor:'#E77F00',color:'white',borderRadius:'5px',border:'none',outline:'none'}}>
                     Contact For Free-Consultation
            </button>

           

                </div>
               </div>
            </div>
        </div>
    </div>

</section>

{/* Mobile View  */}
<section className="d-sm-block d-block d-md-none d-lg-none p-3">
    <div className="likereview p-3">
        <div>
        <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/limitedtimeoffer.svg" className="img-fluid" alt=""/>
                     <p className="fw-600 fs-34 plus mb-0 font-23" style={{color:'#1B3567'}}>Take the First Step</p>
                     <p className="fw-500 fs-22 plus  font-18" style={{color:'#1B3567',lineHeight:'32px'}}>Begin your no-commitment, <br/><span className="fw-800 fs-22 mont font-18" style={{color:'#1B3567',borderBottom:'1px solid #1B3567'}}>Get a Free Quote</span></p>
                     <button type="button" className="btn btn-sm py-2 px-4  plus" onClick={handleShow} style={{fontSize:'15px',fontWeight:'700',backgroundColor:'#E77F00',color:'white',borderRadius:'5px',border:'none',outline:'none'}}>
                     Contact For Free-Consultation
            </button>

        </div>
       
    </div>

</section>



<section className="mt-lg-5">
<div>
            {/* <Testimonial1/> */}
            <GoogleReviewsWidget/>
          </div>
</section>

<section className="mt-5 d-none d-sm-none d-md-block d-lg-block">
    <div className="LandingPageBG p-5">
        <div className="container">
            <form onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="col-lg-5 ">
                      <p className="fw-600 text-center fs-24 plus mb-0" style={{color:'#FFFFFF'}}>What can we help you with?</p>
                      <p className="fw-500 text-center fs-17 plus pt-2" style={{color:'#ABD2FF',lineHeight:'22px'}}>Get Custom Solutions, Recommendations, or, Estimates.
                      Confidentiality & Same Day Response Guaranteed!</p>

                </div>
            </div>

            <div className="row justify-content-center mt-3">
                <div className="col-lg-8 ">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 text-end">
                              <input type="text"  id="fname"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                               placeholder="Full Name *" required className="fs-13 plus px-3" style={{color:'#000000',width:'344px',height:'51px',borderRadius:'7px',border:'none',outline:'none'}} />

                        </div>
                        <div className="col-lg-6">
                              <input type="email"
                                id="email" name="email"  value={formData.email}
                                onChange={handleChange}
                              placeholder="Work Email *" required className="fs-13 plus px-3" style={{color:'#000000',width:'344px',height:'51px',borderRadius:'7px',border:'none',outline:'none'}} />
                {errors.email && <p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "5px" }}>{errors.email}</p>}

                        </div>
                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className="col-lg-6 text-end">
                              <input type="tel" 
                               id="phone" 
                               name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                               placeholder="Phone Number" required className="fs-13 plus px-3" style={{color:'#000000',width:'344px',height:'51px',borderRadius:'7px',border:'none',outline:'none'}} />
                    {errors.phone && <p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "10px" }}>{errors.phone}</p>}


                        </div>
                        <div className="col-lg-6">
                              <input type="text" 
                                name="message"  value={formData.message}
                                onChange={handleChange}
                                form="usrform"
                              placeholder="Company" required className="fs-13 plus px-3" style={{color:'#000000',width:'344px',height:'51px',borderRadius:'7px',border:'none',outline:'none'}} />
                    {errors.message && (<p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "5px" }} > {errors.message} </p>)}
                        </div>
                    </div>

                    <div className="row justify-content-center mt-4">
                        <div className="col-lg-12 text-center">
                              <textarea
                             
                              placeholder="Your Message / Requirements "  className="fs-13 plus px-3 py-3" style={{color:'#000000',width:'710px',height:'115px',borderRadius:'7px',border:'none',outline:'none', maxHeight: '115px',}}></textarea>
                              {/* <p className=" text-white pt-3 fw-700 fs-13 plus">
                                 <span type="file" className=" text-white fw-700 fs-13 plus">📎 Select files from your </span>
                                  <span className="  fw-700 fs-13 plus" style={{color:'#ABD2FF'}}>Computer</span>, <span className=" fw-700 fs-13 plus" style={{color:'#ABD2FF'}}>Google Docs</span> or <span className=" fw-700 fs-13 plus" style={{color:'#ABD2FF'}}>Dropbox URLs</span>
                            </p> */}
                        </div>
              
                    </div>
                  

                </div>
            </div>

            <div className="text-center">

            <button type="submit" className="btn btn-sm  mt-4 mont" disabled={loading} style={{fontSize:'16px',fontWeight:'700',backgroundColor:'#E77F00',color:'white',borderRadius:'5px',border:'none',outline:'none',padding:'12px 60px 12px 60px'}}>
            {loading ? (
                            <>
                        
                              <div className="spinner"></div> {/* Spinner */}
                              {/* <span className="">Sending...</span> */}
                            
                            </>
                          ) : (
                            "Send your query"
                          )}

            </button>
            </div>

            <div className="d-flex justify-content-center gap-4 mt-2">

                    <div className="d-flex  gap-2">
                        <div>
                              <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-form-icon.svg" alt="" className="pt-1" />
                        </div>
                        <div>
                               <p className="fw-400 plus fs-10 text-white pt-2">100% confidential</p>
                        </div>
                    </div>

                    <div className="d-flex  gap-2">
                        <div>
                              <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/Lp-form-icon.svg" alt="" className="pt-1" />
                        </div>
                        <div>
                               <p className="fw-400 plus fs-10 text-white pt-2">We sign NDA</p>
                        </div>
                    </div>

        </div>
        
    </form>
        </div>

    </div>

</section>

{/* Mobile View  */}
<section className="mt-4 d-block d-sm-block d-md-none d-lg-none">
    <div className="LandingPageBG pt-3 pb-5 px-2">
        <div className="container">
         
                      <p className="fw-600 text-center fs-23 plus mb-0 pt-1" style={{color:'#FFFFFF'}}>What can we help you with?</p>
                      <p className="fw-500 text-center fs-17 plus pt-3" style={{color:'#ABD2FF',lineHeight:'22px'}}>Get Custom Solutions, Recommendations, or, Estimates.
                      Confidentiality & Same Day Response Guaranteed!</p>
    <div className="pt-1">
       <Form/>
    </div>

      </div>
      </div>
</section>

    <section className="">
                <Footer/>
        </section>

    </div>  
);
};
export default LandingPage;