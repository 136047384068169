
// ==========This code is normal dropdown functionality====================

// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';

// const img = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/comlogo.svg';

// const Header = () => {
//   const location = useLocation();
//   const [activeLink, setActiveLink] = useState(location.pathname);
//   const [expanded, setExpanded] = useState(false); 

//   useEffect(() => {
//     setActiveLink(location.pathname);
//   }, [location.pathname]);

//   return (
//     <div className="header-container">
//       <Navbar fixed="top" expand="lg" className="custom-navbar" expanded={expanded}>
//         <a href="/">
//           <img
//             className="companylogo"
//             src={img}
//             alt="Company Logo"
//           />
//         </a>
//         <Navbar.Toggle 
//           aria-controls="basic-navbar-nav" 
//           className="navbarstyle"
//           onClick={() => setExpanded(expanded ? false : "expanded")}
//         >
//            {/* Change icon based on expanded state */}
//            <span style={{ fontSize: '25px' }}> {/* Increased icon size and padding */}
//             {expanded ? '✕' : '☰'} {/* Unicode characters for 'X' and hamburger icon */}
//           </span>
//           {/* {expanded ? (
//             <span>&#x2715;</span>  
//           ) : (
//             <span style={{fontSize:'30px'}}>&#9776;</span>  
//           )} */}
//         </Navbar.Toggle>
//         <Navbar.Collapse style={{ marginLeft: '40px' }} id="basic-navbar-nav">
//           <Nav className="me-auto gap-4 gapstyle">
//             <NavDropdown title="Services" id="basic-nav-dropdown" className={`${activeLink.startsWith('/Services') ? 'active' : ''}`} style={{ fontSize: '17px', fontWeight: '400', fontFamily: 'Plus Jakarta Sans, sans-serif' }}>
//               <NavDropdown.Item href="/service/website-design-development-solutions" className={activeLink === '/Webdev' ? 'active' : ''}>Website Development</NavDropdown.Item>
//               <NavDropdown.Item href="/service/mobile-app-design-development-solutions" className={activeLink === '/Mobiledev' ? 'active' : ''}>Mobile Development</NavDropdown.Item>
//               <NavDropdown.Item href="/service/social-media-marketing" className={activeLink === '/Socialmedia' ? 'active' : ''}>Social Media</NavDropdown.Item>
//               <NavDropdown.Item href="/services/digital-marketing" className={activeLink === '/Digitalmarketing' ? 'active' : ''}>Digital Marketing</NavDropdown.Item>
//               <NavDropdown.Item href="/services/performance-marketing" className={activeLink === '/Performancemarketing' ? 'active' : ''}>Performance Marketing</NavDropdown.Item>
//               <NavDropdown.Item href="/services/graphic-desinging" className={activeLink === '/Graphicdesigning' ? 'active' : ''}>Graphic Designing</NavDropdown.Item>
//               <NavDropdown.Item href="/service/search-engine-optimization-seo" className={activeLink === '/Seo' ? 'active' : ''}>SEO</NavDropdown.Item>
//             </NavDropdown>
//             <Nav.Link href="/our-completed-projects" className={`navlinkstyle  ${activeLink === '/our-completed-projects' ? 'active' : ''}`}>Our Projects</Nav.Link>
//             <Nav.Link href="https://blog.datametricks.com/" className={`navlinkstyle  ${activeLink === 'https://blog.datametricks.com/' ? 'active' : ''}`}>Blogs</Nav.Link>
//             <Nav.Link href="/about-us" className={`navlinkstyle  ${activeLink === '/about-us' ? 'active' : ''}`}>About Us</Nav.Link>
//             <Nav.Link href="/contact-us" className={`navlinkstyle  ${activeLink === '/contact-us' ? 'active' : ''}`}>Contact Us</Nav.Link>
//             <Nav.Link href='/ai-ml'>
//               <div style={{ position: 'relative' }}>
//                 <span className='plus fw-600 fs-18' style={{ color: ' rgba(0, 14, 217, 1), rgba(0, 15, 104, 1)' }}>AI & ML</span>
//                 <div style={{ padding: '3px 15px',   background: 'linear-gradient(to left, rgba(96, 20, 165, 1), rgba(90, 20, 170, 1), rgba(129, 18, 145, 1), rgba(132, 18, 143, 1), rgba(248, 0, 66, 1))', color: 'white', fontSize: '13px', fontWeight: '500', borderRadius: '5px', position: 'absolute', left: '70px', bottom: '15px', animation: 'blink 1s infinite' }}>
//                   New
//                 </div>
//               </div>
//             </Nav.Link>
//           </Nav>
//           <Nav className="ms-auto">
//             <Nav.Link href="/contact-us" className="align-items-end">
//               <button type="button" className="btn btn-sm text-white plus fs-18" style={{ boxShadow: 'none', border: 'none', paddingLeft: '25px', paddingRight: '25px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '7px', backgroundColor: 'rgba(5, 5, 5, 1)', fontWeight: '600' }}>
//                 Let’s Talk
//               </button>
//             </Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//       </Navbar>
//     </div>
//   );
// };

// export default Header;

// ==========This code is normal dropdown functionality END====================



import React, { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


const Header = () => {


// const img = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/comlogo.svg';
const newheaderimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newHeaderimg1.svg";
const newheaderimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg2.svg";
const newheaderimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg3.svg";
const newheaderimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg4.svg";
const newheaderimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg5.svg";
const newheaderimg6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg6.svg";
const newheaderimg7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg7.svg";
const newheaderimg8 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg8.svg";
const newheaderimg9 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg9.svg";
const newheaderimg10 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg10.svg";
const upheadarrow = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/headuparrow.svg";
const downheadarrow = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/headdownarrow.svg";

  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [expanded, setExpanded] = useState(false);
  const [showDropdownContent, setShowDropdownContent] = useState(false);
  const [activeButton, setActiveButton] = useState('webDevelopment');


  useEffect(() => {
    setActiveLink(location.pathname);
   
  }, [location.pathname]);

  const handleDropdownToggle = (isOpen) => {
    setShowDropdownContent(isOpen);
    if(isOpen===true){
      setIsFocused(true);
      setIsOpen(true);
    }
    else{
      setIsFocused(false);
      // setIsOpen(false);
    }
    
  };

  const handleButtonClick = (button) => {
    setActiveButton(button);
    

  };
 
    const [isFocused, setIsFocused] = useState(false); // State to track focus
    const [isOpenDrop, setIsOpen] = useState(false); // State to track focus
  
    const isServicesActive = [
      '/service/website-design-development-solutions',
      '/service/mobile-app-design-development-solutions',
      '/service/social-media-marketing',
      '/services/digital-marketing',
      '/services/performance-marketing',
      '/services/graphic-desinging',
      '/service/search-engine-optimization-seo',
      '/software-development-services',
      '/service/cms-website-design-development-solutions',
      '/service/ecommerce-website-design-development-solutions',
      '/service/custom-website-design-development-solutions'



    ].includes(location.pathname);



    const serviceCards = [
      {
        title: 'Custom Website Development',
        description:
          'Build a website that stands out with innovative designs, seamless functionality, and a user-focused approach ensuring a memorable online presence for your brand.',
        link: '/service/custom-website-design-development-solutions',
        image: 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/header-icon1.svg',
      },
      {
        title: 'Website Development',
        description:
          'Boost your brand with websites. Our services deliver exceptional designs tailored to your business. Drive growth with a platform to impress.',
        link: '/service/website-design-development-solutions',
        image: 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/header-icon2.svg',
      },
      {
        title: 'Mobile App Development',
        description:
          'Create innovative and user-friendly mobile apps tailored to your business needs. Empower your brand with seamless functionality and a superior user experience.',
        link: '/service/mobile-app-design-development-solutions',
        image: 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/header-icon3.svg',
      },
      {
        title: 'E-Commerce Website Development',
        description:
          'Launch a powerful e-commerce website to boost sales and customer engagement. Experience seamless design, secure transactions, and an exceptional experience.',
        link: '/service/ecommerce-website-design-development-solutions',
        image: 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/header-icon6.svg',
      },
      {
        title: 'CMS Website Development',
        description:
          'Simplify content management with our CMS website development services and scale your website with user-friendly tools tailored to your needs.',
        link: '/service/cms-website-design-development-solutions',
        image: 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/header-icon4.svg',
      },
      {
        title: 'Software Development',
        description:
          'Create custom software solutions that drive efficiency and innovation. Our development services are designed to meet your needs and deliver scalable results.',
        link: '/software-development-services',
        image: 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/header-icon5.svg',
      },
    ];

    const serviceCards1 = [
      {
        href: '/services/digital-marketing',
        imgSrc: 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/header-digitalicon1.svg',
        title: 'Digital Marketing',
        description:
          'Amplify your online presence with targeted digital marketing strategies. Drive traffic, increase conversions, and grow your brand across multiple platforms.',
      },
      {
        href: '/service/search-engine-optimization-seo',
        imgSrc: 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/header-digitalicon2.svg',
        title: 'SEO (Search Engine Optimization)',
        description:
          'Improve your website’s visibility with expert SEO strategies that drive organic traffic. Boost rankings, and attract more qualified leads.',
      },
      {
        href: '/service/social-media-marketing',
        imgSrc: 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/header-digitalicon3.svg',
        title: 'Social Media Marketing',
        description:
          'Expand your brand\'s reach with effective social media marketing strategies. Engage your audience, build brand loyalty, and drive conversions across platforms.',
      },
      {
        href: '/services/performance-marketing',
        imgSrc: 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/header-digitalicon4.svg',
        title: 'Performance Marketing',
        description:
          'Maximize your ROI with performance marketing strategies focused on measurable results. Drive targeted traffic, conversions, and growth through data-driven campaigns.',
      },
    ];

    const [activeBox, setActiveBox] = useState(null);

    const handleBoxClick = (id) => {
      setActiveBox(id);
    };

  return (
    <div>
      
    <div className="header-container " >
      <Navbar fixed="top" expand="lg" className="custom-navbar pb-6" expanded={expanded}>
        <a href="/">
          <img className="companylogo" src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/companylogo1.svg" alt="Company Logo" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null}/>
        </a>
        <Navbar.Toggle 
          aria-controls="basic-navbar-nav"
          className="navbarstyle"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        >
          <span style={{ fontSize: '25px',color:'white' }}>
            {expanded ? '✕' : '☰'}
          </span>
        </Navbar.Toggle>
        <Navbar.Collapse style={{ marginLeft: '40px' }} id="basic-navbar-nav">
          <Nav className="me-auto gap-4 gapstyle">

            {/* Solutions menu title */}
            <div className='d-sm-none d-none d-md-block d-lg-block'>
            <Nav.Link 
              className={`navlink1 text-white ${activeLink === '' ? 'active' : ''}`}
             
              onMouseEnter={() => handleDropdownToggle(true)}
            
      
            >
    Services <span>{isFocused ? <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/header-upicon1.svg" alt='' style={{height:'13px',width:'13px'}} /> : <img src="https://d3vqz9qyn2r1xe.cloudfront.net/imges/header-downicon1.svg" alt='' style={{height:'13px',width:'13px'}}/> }</span>
      </Nav.Link>
            </div>

          
            {/* <div className=''>
           <NavDropdown title="Solutions" id="basic-nav-dropdown" className={`${activeLink.startsWith('/Solutions') ? 'active' : ''}`} style={{ fontSize: '18px', fontFamily: 'Plus Jakarta Sans, sans-serif',color:'white' }}>
               <NavDropdown.Item href="/service/website-design-development-solutions" className={activeLink === '/Webdev' ? 'active' : ''}>Website Development</NavDropdown.Item>
              <NavDropdown.Item href="/service/mobile-app-design-development-solutions" className={activeLink === '/Mobiledev' ? 'active' : ''}>Mobile Development</NavDropdown.Item>
               <NavDropdown.Item href="/service/social-media-marketing" className={activeLink === '/Socialmedia' ? 'active' : ''}>Social Media</NavDropdown.Item>
               <NavDropdown.Item href="/services/digital-marketing" className={activeLink === '/Digitalmarketing' ? 'active' : ''}>Digital Marketing</NavDropdown.Item>
              <NavDropdown.Item href="/services/performance-marketing" className={activeLink === '/Performancemarketing' ? 'active' : ''}>Performance Marketing</NavDropdown.Item>
              <NavDropdown.Item href="/services/graphic-desinging" className={activeLink === '/Graphicdesigning' ? 'active' : ''}>Graphic Designing</NavDropdown.Item>
               <NavDropdown.Item href="/service/search-engine-optimization-seo" className={activeLink === '/Seo' ? 'active' : ''}>SEO</NavDropdown.Item>
           </NavDropdown></div>   */}


           {/* <NavDropdown title="Services" id="basic-nav-dropdown"  menuVariant="dark" style={{ fontSize: '18px', fontFamily: 'Plus Jakarta Sans, sans-serif'}}>
               <NavDropdown.Item href="/service/website-design-development-solutions">Website Development</NavDropdown.Item>
              <NavDropdown.Item href="/service/mobile-app-design-development-solutions">Mobile Development</NavDropdown.Item>
               <NavDropdown.Item href="/service/social-media-marketing" >Social Media</NavDropdown.Item>
               <NavDropdown.Item href="/services/digital-marketing" >Digital Marketing</NavDropdown.Item>
              <NavDropdown.Item href="/services/performance-marketing" >Performance Marketing</NavDropdown.Item>
              <NavDropdown.Item href="/services/graphic-desinging">Graphic Designing</NavDropdown.Item>
               <NavDropdown.Item href="/service/search-engine-optimization-seo">SEO</NavDropdown.Item>
           </NavDropdown> */}

      <li class="nav-item dropdown d-lg-none d-md-none d-sm-block d-block">
          <a 
          // class="nav-link dropdown-toggle custom-dropdown"
          className={`nav-link dropdown-toggle custom-dropdown ${isServicesActive ? 'active' : ''}`} 
          onMouseEnter={() => (isFocused) ? handleDropdownToggle(false) : null} 
           href="" 
           id="navbarDropdown"
            role="button"
             data-bs-toggle="dropdown" 
             aria-expanded="false"
              style={{
                color: isServicesActive ? 'white' : 'rgba(255, 255, 255, 0.6)',
                fontSize: '18px', fontFamily: 'Plus Jakarta Sans, sans-serif'}}>
            Services
    

          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <NavDropdown.Item href="/service/website-design-development-solutions" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={`texthover  ${activeLink === '/service/website-design-development-solutions' ? 'active' : ''}`}>Website Development</NavDropdown.Item>
              <NavDropdown.Item href="/service/mobile-app-design-development-solutions" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={`texthover  ${activeLink === '/service/mobile-app-design-development-solutions' ? 'active' : ''}`}>Mobile Development</NavDropdown.Item>
              <NavDropdown.Item href="/software-development-services" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={` texthover ${activeLink === '/software-development-services' ? 'active' : ''}`}>Software Development</NavDropdown.Item>
               <NavDropdown.Item href="/service/social-media-marketing" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={`texthover  ${activeLink === '/service/social-media-marketing' ? 'active' : ''}`} >Social Media</NavDropdown.Item>
               <NavDropdown.Item href="/services/digital-marketing" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={` texthover ${activeLink === '/services/digital-marketing' ? 'active' : ''}`}>Digital Marketing</NavDropdown.Item>
               <NavDropdown.Item href="/service/cms-website-design-development-solutions" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={` texthover ${activeLink === '/service/cms-website-design-development-solutions' ? 'active' : ''}`}>Cms Website</NavDropdown.Item>
               <NavDropdown.Item href="/service/custom-website-design-development-solutions" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={` texthover ${activeLink === '/service/custom-website-design-development-solutions' ? 'active' : ''}`}>Custom Website</NavDropdown.Item>
               <NavDropdown.Item href="/service/ecommerce-website-design-development-solutions" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={` texthover ${activeLink === '/service/ecommerce-website-design-development-solutions' ? 'active' : ''}`}>E-Commerce Website</NavDropdown.Item>
              <NavDropdown.Item href="/services/performance-marketing" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={`texthover  ${activeLink === '/services/performance-marketing' ? 'active' : ''}`}>Performance Marketing</NavDropdown.Item>
              {/* <NavDropdown.Item href="/services/graphic-desinging" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={` texthover ${activeLink === '/services/graphic-desinging' ? 'active' : ''}`}>Graphic Designing</NavDropdown.Item> */}
               <NavDropdown.Item href="/service/search-engine-optimization-seo" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={`texthover  ${activeLink === '/service/search-engine-optimization-seo' ? 'active' : ''}`}>SEO</NavDropdown.Item>
          </ul>
      </li>
      

              <Nav.Link href="/our-completed-projects" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={`navlinkstyle navlinkstyle1 ${activeLink === '/our-completed-projects' ? 'active' : ''}`}>Our Projects</Nav.Link>
            <Nav.Link href="https://blog.datametricks.com/" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null}
              className={`navlinkstyle navlinkstyle1 ${activeLink === 'https://blog.datametricks.com/' ? 'active' : ''}`}>Blogs</Nav.Link>
            <Nav.Link href="/about-us" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={`navlinkstyle navlinkstyle1${activeLink === '/about-us' ? 'active' : ''}`}>About Us</Nav.Link>
            <Nav.Link href="/contact-us" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={`navlinkstyle navlinkstyle1 ${activeLink === '/contact-us' ? 'active' : ''}`}>Contact Us</Nav.Link>
            <Nav.Link href='/ai-ml' onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} >
              <div style={{ position: 'relative' }}>
                <span className='plus fw-600 fs-17 gradient-text-header'>AI & ML</span>
                <div style={{ padding: '3px 15px', background: 'linear-gradient(to right, #04D9BE 20%, #BF64FF 100% ,#058AF7 100%,#058AF7 0%)', color: 'rgba(41, 0, 125, 1)', fontSize: '13px', fontWeight: '500', borderRadius: '5px', position: 'absolute', left: '70px', bottom: '15px', }}>
                  New
                </div>
              </div>
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto">
            {/* <Nav.Link href="/contact-us" className="align-items-end " onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null}>
              <button type="button" className="btn btn-sm text-black plus fs-18 hovereffect" style={{ boxShadow: 'none', border: 'none', paddingLeft: '25px', paddingRight: '25px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '7px', backgroundColor: 'white', fontWeight: '600', }}>
                Let’s Talk
              </button>
            </Nav.Link> */}

             <Nav.Link href="/contact-us" className="align-items-end " style={{paddingRight:'10px'}}>
             <a href="mailto:contact@datametricks.com" style={{textDecoration:'none'}}>
           <div className='d-flex gap-2'>
            <div>
              <img src='https://d3vqz9qyn2r1xe.cloudfront.net/imges/mail-icon.svg' alt='' />
            </div>
            <div>
            <p className='text-white mb-0'>contact@datametricks.com</p>
            </div>
           </div></a>

       < a href='tel:+917303946657' style={{textDecoration:'none'}}><div className='d-flex gap-2 pt-10 '>
            <div>
              <img src='https://d3vqz9qyn2r1xe.cloudfront.net/imges/call-icon.svg' alt='' />
            </div>
            <div>
            <p className='text-white '>+917303946657</p>
            </div>
           </div></a>
            </Nav.Link>
            
          </Nav>
        </Navbar.Collapse>

      
      </Navbar>



    
    </div>
    

      {/* Full-width blue background container */}
      {isOpenDrop&& (
     
              <div 

           
                // className="full-width-container d-none d-sm-none d-lg-block d-md-block"                      position-fixed                                                              position-fixed
                className={isFocused ? 'slide-down full-width-container d-none d-sm-none d-lg-block d-md-block   position-fixed'  : 'slide-up full-width-container d-none d-sm-none d-lg-block d-md-block  position-fixed'}
                // onMouseEnter={() => handleDropdownToggle(true)} 
                onMouseLeave={() => handleDropdownToggle(false)} 
              >
    
                <div className='container '>
               <div className='d-flex gap-3'>
            

               <div>
                    <button
                      type="button"
                      className="fw-400 fs-18 plus btn-sm px-4 pb-2 py-1"
                      style={{

                        color: activeButton === 'webDevelopment' ? 'rgba(255, 255, 255, 1)' : 'white', // Text color changes to white on click
                        borderRadius: '34px',
                         border: activeButton === 'webDevelopment' ? ' white' : '1px solid white',
                        backgroundColor: activeButton === 'webDevelopment' ? 'rgba(0, 110, 205, 1)' : 'transparent',
                        fontSize: activeButton === 'webDevelopment' ? '19px' : '18px', 

                       
                      }}
                      onClick={() => handleButtonClick('webDevelopment')}

                    
                    >
                      Web Development
                    </button>
                   
                  </div>


                  <div>
                    <button
                      type="button"
                      className="fw-400 fs-18 plus btn-sm px-4 pb-2 py-1"
                      style={{

                        color: activeButton === 'digitalMarketing' ? 'rgba(255, 255, 255, 1)' : 'white', // Text color changes to white on click
                        borderRadius: '34px',
                        border: activeButton === 'digitalMarketing' ? ' white' : '1px solid white',
                        backgroundColor: activeButton === 'digitalMarketing' ? 'rgba(0, 110, 205, 1)' : 'transparent',
                        fontSize: activeButton === 'digitalMarketing' ? '19px' : '18px', 

                      }}
                      onClick={() => handleButtonClick('digitalMarketing')}

                     
                    >
                      Digital Marketing
                    </button>
                  </div>
                 
                
             </div>
           

 
                  {activeButton === 'webDevelopment' && (
                    <div>
                        <div>   

                        <div className="row mt-3 ">
        {serviceCards.map((service, index) => (
          <div className="col-lg-4 mb-3" key={index}>
            <a href={service.link} style={{ textDecoration: 'none' }}>
              <div
                className="mt-2 py-2 px-3 h-100 headerhover"
                style={{
                  backgroundColor: 'rgba(18, 18, 18, 1)',
                  border: '1px solid rgba(255, 255, 255, 0.07)',
                }}
              >
                <div className="d-flex gap-2">
                  <div>
                    <img src={service.image} alt={service.title} />
                  </div>
                  <div>
                    <p
                      className="fw-500 plus fs-16"
                      style={{ color: 'rgba(255, 255, 255, 1)' }}
                    >
                      {service.title}
                    </p>
                  </div>
                </div>
                <p
                  className="fs-14 plus pt-1"
                  style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                >
                  {service.description}
                </p>
              </div>
            </a>
          </div>
        ))}
                       </div>
                  
                   </div>
                    </div>
                  )}


                
                  {activeButton === 'digitalMarketing' && (
                    <div>
                         <div>   

                         <div className="row mt-3">
    {serviceCards1.map((service, index) => (
      <div key={index} className="col-lg-4 mb-3">
        <a href={service.href} style={{ textDecoration: 'none' }}>
          <div
            className="mt-2 py-2 px-3 h-100 headerhover"
            style={{
              backgroundColor: 'rgba(18, 18, 18, 1)',
              border: '1px solid rgba(255, 255, 255, 0.07)',
            }}
          >
            <div className="d-flex gap-2">
              <div>
                <img src={service.imgSrc} alt={service.title} />
              </div>
              <div>
                <p
                  className="fw-500 plus fs-16"
                  style={{ color: 'rgba(255, 255, 255, 1)' }}
                >
                  {service.title}
                </p>
              </div>
            </div>
            <p
              className="fs-14 plus pt-1"
              style={{ color: 'rgba(255, 255, 255, 0.5)' }}
            >
              {service.description}
            </p>
          </div>
        </a>
      </div>
    ))}
  </div>
             
              </div>
                    </div>
                  )}

               


            </div>
              </div>
            )}




    </div>
  );
};

export default Header;



