import React from "react";

const CompanyLogoSlider = () => {

  const logos = [
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/com-logo-1.svg", 
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/com-logo-2.svg", 
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/com-logo-3.svg", 
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/com-logo-4.svg", 
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/com-logo-5.svg", 
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/com-logo-6.svg", 
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/com-logo-7.svg", 
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/com-logo-8.svg", 
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/com-logo-9.svg", 
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/com-logo-10.svg", 
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/com-logo-11.svg", 
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/com-logo-12.svg", 
    "https://d3vqz9qyn2r1xe.cloudfront.net/imges/com-logo-13.svg", 
   
  ];

  return (
    <div className="logo-slider-container123">
      <div className="logo-slider123">
        {logos.map((logo, index) => (
          <img src={logo} alt={`Logo ${index + 1}`} key={index} className="logo123" />
        ))}
{/*         
        {logos.map((logo, index) => (
          <img src={logo} alt={`Logo Duplicate ${index + 1}`} key={`duplicate-${index}`} className="logo123" />
        ))}

           {logos.map((logo, index) => (
          <img src={logo} alt={`Logo Duplicate ${index + 1}`} key={`duplicate-${index}`} className="logo123" />
        ))}

           {logos.map((logo, index) => (
          <img src={logo} alt={`Logo Duplicate ${index + 1}`} key={`duplicate-${index}`} className="logo123" />
        ))} */}


      </div>
    </div>
  );
};

export default CompanyLogoSlider;
